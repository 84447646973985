import React, {useState} from 'react';
import PropTypes from 'prop-types';

const PassengerDetail = ({ passenger }) => {
    const { id, firstName, lastName} = passenger;
    const [card, setCard] = useState(false);
    const [SIMCard, setSIMCard] = useState(false);
    const [amount, setAmount] = useState(null);

    const CardChangeHandler = (event) => {
        const target = event.target;
        const value = target.name === 'Card' ? target.checked : target.value;
        setCard(value);
    };

    const SIMCardChangeHandler = (event) => {
        const target = event.target;
        const value = target.name === 'SIMCard' ? target.checked : target.value;
        setSIMCard(value);
    };

    const onChange = e => {
        setAmount(e.target.value);
    };

    return (
        <div className='grid-4-in-family' style={styles.containerStyle}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{firstName}</div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{lastName}</div>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>
                <input
                    id={id}
                    className={id}
                    name='Card'
                    type='checkbox'
                    checked={card}
                    onChange={CardChangeHandler}
                    style={{margin: 0}}
                />
                <div className='form__group field' style={{width: window.innerWidth > 767 ? 100 : 75, margin: 0, marginLeft: 5}}>
                    <input
                        id={`amount ${id}`}
                        className={`form__field amount-family ${id}`}
                        type='number'
                        name='Amount'
                        value={amount}
                        onChange={onChange}
                        required
                        disabled={!card}
                        placeholder='Amount'
                    />
                    <label htmlFor='amount' className='form__label'>Amount</label>
                </div>
                <label style={{marginLeft: -5, color: 'black', fontSize : window.innerWidth > 767 ? 14 : 10}}>
                    {localStorage.getItem('currency') === '1' ? '€' : '$'}
                </label>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>
                <input
                    id={id}
                    className={id}
                    name='SIMCard'
                    type='checkbox'
                    checked={SIMCard}
                    onChange={SIMCardChangeHandler}
                    style={{margin: 0}}
                />
            </div>
        </div>
    );
};

PassengerDetail.propTypes = {
    passenger: PropTypes.object.isRequired,
};

const styles = {
    containerStyle: {
        backgroundColor: 'white',
        padding: 5,
        color: '#777',
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 1.5,
        borderColor: '#ccc',
        fontSize: window.innerWidth > 767 ? 14 : 12,
        gridGap: window.innerWidth > 767 ? 50 : 15,
    }
};

export default PassengerDetail;
