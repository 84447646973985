import React, {Fragment, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import SpinnerModal from '../layout/SpinnerModal';
import AlertsSettings from '../layout/AlertsSettings';
import history from '../history';
import '../../Styles/NewStyles.css';
import '../../Styles/NewStyles.css';
import '../../App.css';

const EditProfile = () => {
    const authContext = useContext(AuthContext);
    const { token, first_name, last_name, username, error, clearErrors } = authContext; //+ phone

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const [height, setHeight] = useState(window.innerHeight);

    const [load, setLoad] = useState(null);

    const [label, setLabel] = useState('');
    const [labelDone, setLabelDone] = useState('');

    //Profile Img
    const [profile, setProfile] = useState('');
    const [refresh, setRefresh] = useState(false);

    const [showAlert0, setShowAlert0] = useState(false);

    const showProfileImage = () => {
        if (!localStorage.getItem('profile_pic')) {
            return <img
                src='/Images/default user.jpg'
                alt='profile'
                className='img'
                style={styles.profileStyle}
            />
        } else {
            return <img
                src={profile}
                alt='profile'
                className='img'
                style={styles.profileStyle}
            />
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadPhoto  = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);

        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const data = { profile_pic: base64.split('base64,')[1] };
            await axios.put(`https://admin.intravelcard.com/api/auth/updateProfile/${username}`, data, config);
            localStorage.setItem('profile_pic', base64.split('base64,')[1]);
            setRefresh(!refresh);
            setLoad(true);
            setShowAlert0(true);
            setAlert('Done Successfully!', 'green');
        } catch (e) {
            setLoad(true);
            setShowAlert0(true);
            setAlert('Server Error!', 'red');
        }
    };

    //personal
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [personalUpdate, setPersonalUpdate] = useState(false);
    const [personalBtnText, setPersonalBtnText] = useState('Update Information');
    const [form, setForm] = useState({
        firstName: first_name ? first_name : '',
        lastName: last_name ? last_name : '',
        email: username,
        phone: authContext.phone !== '' ? authContext.phone : '',
    });

    const {firstName, lastName, email, phone} = form;

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const onChangeProfile = (e) => {
        e.preventDefault();
        setShowFileUpload(!showFileUpload);
    };

    const onPersonalSubmit = async (e) => {
        e.preventDefault();
        if (!personalUpdate) {
            setPersonalUpdate(true);
            setPersonalBtnText('Save Changes');
        } else {
            setShowAlert0(false);
            setPersonalUpdate(false);
            setPersonalBtnText('Update Information');
            setLoad(false);
            const config = {
                headers: {
                    'Authorization' : 'token ' + token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            };

            if (firstName && lastName && phone) {
                const data1 = {
                    first_name: firstName,
                    last_name: lastName,
                };
                const data2 = { phone: phone };
                try {
                    await axios.put(`https://admin.intravelcard.com/api/auth/updateName/${username}`, data1, config);
                    await axios.put(`https://admin.intravelcard.com/api/auth/updateProfile/${username}`, data2, config);
                    localStorage.setItem('first_name', firstName);
                    localStorage.setItem('last_name', lastName);
                    localStorage.setItem('phone', phone);
                    setLoad(true);
                    setAlert('Done Successfully!', 'green');
                    setLabelDone('Done Successfully!');
                    setTimeout(() => {
                        setLabelDone('');
                    }, 3000);
                } catch (e) {
                    setLoad(true);
                    setAlert('Server Error!', 'red');
                    setLabel('Server Error!');
                    setTimeout(() => {
                        setLabel('');
                    }, 5000);
                }
            } else {
                setLoad(true);
                setLabel('Please fill in all fields!');
                setTimeout(() => {
                    setLabel('');
                }, 5000);
                setPersonalUpdate(true);
                setPersonalBtnText('Save Changes');
            }
        }
    };

    useEffect(() => {
        if (error === 'Done Successfully!') { //photo
            setAlert('Done Successfully!', 'green');
            clearErrors();
            history.push('/settings');
        }
        setProfile(`data:image/jpeg;base64,${localStorage.getItem('profile_pic')}`);
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener("resize", handleResize);
    },[error, refresh]);

    const showChangeProfile = () => {
        return (
            <div className='all-center'>
                {showProfileImage()}
                <button
                    className='btn'
                    style={{backgroundColor: 'rgba(0,0,0,0)', padding: 0, fontSize: 12, color: 'white'}}
                    onClick={onChangeProfile}
                >Change Profile Photo</button>
                {showFileUpload
                    ? (
                        <input
                            style={{marginTop: 10, marginBottom: 0, width: 160, fontSize: 12, color: 'white'}}
                            type='file'
                            name='identificationPhoto'
                            onChange={(e) => {
                                uploadPhoto(e);
                            }}
                        />
                    )
                    : null
                }
            </div>
        )
    };

    const showPersonal = () => {
        return (
            <div className='text-left' style={{marginTop: 15, fontSize: 12, color: 'white'}}>
                <div style={{padding: 25}}>
                    <div style={styles.rowStyle}>
                        <label htmlFor='firstName' style={{width: 75, fontWeight: 'bold'}}>First Name</label>
                        {personalUpdate === true
                            ? (
                                <input
                                    className='input-in-edit'
                                    style={styles.inputStyle}
                                    type='text'
                                    name='firstName'
                                    value={firstName}
                                    onChange={onChange}
                                    maxLength='50'
                                />
                            )
                            : <div style={styles.labelStyle}>
                                {firstName ? firstName : '-'}
                            </div>
                        }
                    </div>

                    <div style={styles.rowStyle}>
                        <label htmlFor='lastName' style={{width: 75, fontWeight: 'bold'}}>Last name</label>
                        {personalUpdate === true
                            ? (
                                <input
                                    className='input-in-edit'
                                    style={styles.inputStyle}
                                    type='text'
                                    name='lastName'
                                    value={lastName}
                                    onChange={onChange}
                                    maxLength='50'
                                />
                            )
                            : <div style={styles.labelStyle}>
                                {lastName ? lastName : '-'}
                            </div>
                        }
                    </div>

                    <div style={styles.rowStyle}>
                        <label htmlFor='email' style={{width: 75, fontWeight: 'bold'}}>Email</label>
                        <div style={styles.labelStyle}>{email}</div>
                    </div>

                    <div style={styles.rowStyle}>
                        <label htmlFor='phone' style={{width: 75, fontWeight: 'bold'}}>Phone</label>
                        {personalUpdate === true
                            ? (
                                <input
                                    className='input-in-edit'
                                    style={styles.inputStyle}
                                    type='text'
                                    name='phone'
                                    value={phone}
                                    onChange={onChange}
                                    maxLength='20'
                                />
                            )
                            : <div style={styles.labelStyle}>
                                {phone ? phone : '-'}
                            </div>
                        }
                    </div>

                    {label
                        ? (
                            <label
                                className='all-center'
                                style={{
                                    position: 'fixed',
                                    marginLeft: -35,
                                    bottom: 90,
                                    color: 'red',
                                    fontSize: 12,
                                }}
                            >{label}</label>
                        )
                        : null
                    }
                    {labelDone
                        ? (
                            <label
                                className='all-center'
                                style={{
                                    position: 'fixed',
                                    marginLeft: -35,
                                    bottom: 90,
                                    color: 'green',
                                    fontSize: 12,
                                }}
                            >{labelDone}</label>
                        )
                        : null
                    }
                </div>
                <div className='all-center'
                     style={{
                         position: 'fixed',
                         bottom: 40,
                         marginLeft: -10,
                         marginTop: 0,
                         paddingLeft: 8,
                         paddingRight:8,
                     }}>
                    <button
                        className='btn btn-in-edit-mobile'
                        onClick={onPersonalSubmit}
                    >{personalBtnText}</button>
                </div>
            </div>
        )
    };

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : null
            }
            <div style={{height: 30, backgroundColor: '#003e57'}} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#f1f1f1',
                    padding: 10,
                    paddingLeft: 15,
                    paddingRight: 15,
                }}>
                <a
                    className='all-center'
                    href='javascript:history.go(-1)'
                    style={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        margin: 0,
                        height: 35,
                        width: 30,
                        borderWidth: 0,
                        cursor: 'pointer',
                        zIndex: 2,
                    }}>
                    <i
                        className="fa fa-angle-left"
                        style={{color: '#626465', fontSize: 25, fontWeight: 'bold'}}
                    />
                </a>
                <div className='all-center' style={{marginLeft: -30, fontSize: 13, color: '#626465'}}>
                    Edit Profile
                </div>
            </div>
            <div style={{
                backgroundColor: '#003e57',
                //minHeight: window.innerHeight - 85,
                minHeight: height - 85,
                padding: 10,
                paddingTop: 20,
                paddingBottom: 0,
            }}>
                {showAlert0 === true
                    ? <div style={{marginTop: -20, marginBottom: 30}}>
                        <AlertsSettings/>
                    </div>
                    : null
                }
                {showChangeProfile()}
                {showPersonal()}
            </div>
        </Fragment>
    );
};

const styles = {
    profileStyle: {
        width: 80,
        height: 80,
        marginBottom: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 10,
    },
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 8,
    },
    labelStyle: {
        height: 35,
        width: 220,
        marginTop: 2,
        marginBottom: 2,
        padding: '0.4rem',
    },
    inputStyle: {
        backgroundColor: 'rgba(0,0,0,0)',
        marginTop: 5,
        marginBottom: 5,
        color: 'white',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 0,
        borderBottomWidth: 1.5,
    },
};

export default EditProfile;
