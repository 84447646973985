import React, {Fragment, useContext, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import Card from '../layout/Card';
import SpinnerModal from '../layout/SpinnerModal';
import DialogBox from '../layout/DialogBox';
import BottomMenu from "../layout/BottomMenu";
import history from '../history';
import '../../Styles/NewStyles.css';
import '../../App.css';

const Refund = (props) => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, id, username, currency, iranian_phone_number } = authContext; //+ phone

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220,
        marginLeft: 220,
    });

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [balance, setBalance] = useState(null);
    const [pan, setPan] = useState('');

    const [load, setLoad] = useState(null);

    const [dollarPrice, setDollarPrice] = useState(1);
    const [euroPrice, setEuroPrice] = useState(1);
    const [lastUpdate, setLastUpdate] = useState('');

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const [form , setForm] = useState({
        user_ID : id,
        firstName: '',
        lastName: '',
        email: username,
        date: '',
        place: '',
        phone: authContext.phone ? authContext.phone : '',
        pan: '',
        amount: null,
    });

    const {place, phone, amount} = form;

    const [date1, setDate1] = useState('');

    const [otp, setOtp] = useState('');
    const [bToken, setBToken] = useState('');

    const dateChangeHandler = (date) => {
        setDate1(date);
        if (date !== '') {
            setForm({ ...form, date:
                date.toString().split(' ')[2] + ' ' +
                date.toString().split(' ')[1] + ' ' +
                date.toString().split(' ')[3] })
        }
    };

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const onChange1 = e => {
        setOtp(e.target.value);
    };

    const next = (e) => {
        e.preventDefault();
        setShow(false);
        getOTP();
    };

    const back = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const divideNum = (num) => {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    const getOTP = async (e) => {
        e.preventDefault();
        setLoad(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        try {
            //bank token
            const res1 = await axios.get('https://admin.intravelcard.com/getBankInfo/getOTP/', config1);
            const bankToken = res1.data; //access_token
            setBToken(bankToken);

            const data2 = {
                pan: Number(pan),
                otpType: '1'
            };
            const req2 = { bankToken: `Bearer ${bankToken}` , data: data2 };
            await axios.post('https://admin.intravelcard.com/getBankInfo/getOTP/', req2, config1);
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Error!');
            setLoad(true);
        }
    };

    const refund = async (e) => {
        e.preventDefault();
        setLoad(false);
        //django server
        const config1 = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        //bank service
        const data1 = {
            pan: Number(pan),
            amount: Number(amount),
            otpCode: otp.toString()
        };
        const req1 = { bankToken: `Bearer ${bToken}` , data: data1 };

        try {
            await axios.post('https://admin.intravelcard.com/getBankInfo/bankRefund/', req1, config1);
            await axios.post('https://admin.intravelcard.com/api/req/CreateRefund/', form, config1);
            setShowDialogBox(true);
            setDialogBoxData('done');
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Error!');
            setLoad(true);
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const [show, setShow] = useState(true);

    const showScreen0 = () => {
        return (
            <>
                <div style={{
                    marginTop: 10,
                    fontSize: width > 767 ? 16 : 14,
                    color: 'red',
                    textAlign: 'center'
                }}>
                    No card issued to you!
                </div>
                <div
                    className='grid-2-for-btns-in-refund'
                    style={{
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 0
                    }}
                >
                    <a
                        href='/myCard'
                        className='btn text-center back-btn-in-refund'
                    >
                        Back
                    </a>
                </div>
            </>
        )
    };

    const showScreen1 = () => {
        return (
            <>
                <div style={{
                    marginTop: 10,
                    fontSize: width > 767 ? 16 : 14,
                    color: 'red',
                    textAlign: 'center'
                }}>
                    Mobile number is not registered for you! <br/>
                    You are not allowed to submit a request.
                </div>
                <div
                    className='grid-2-for-btns-in-refund'
                    style={{
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 0
                    }}
                >
                    <a
                        href='/myCard'
                        className='btn text-center back-btn-in-refund'
                    >
                        Back
                    </a>
                </div>
            </>
        )
    };

    const showScreen2 = () => {
        return (
            <>
                <div style={{
                    marginTop: 10,
                    marginBottom: 15,
                    textAlign: 'justify',
                    fontSize: 14,
                    color: '#626465'
                }}>
                    Please specify the approximate or exact amount of Refund and your desired date.
                </div>
                <form onSubmit={next} className='form-in-refund'>
                    <div style={{marginBottom: 15}}>
                        <label
                            htmlFor='date'
                            style={styles.questionStyle}
                        >
                            when do you want to refund your money?
                        </label>
                        <br/>
                        <DatePicker
                            className='refund'
                            dateFormat='MMMM dd,yyyy'
                            selected={date1}
                            onChange={dateChangeHandler}
                            required
                            placeholderText='November 17,2020'
                        />
                    </div>
                    <div style={{marginBottom: 15}}>
                        <label
                            htmlFor='place'
                            style={styles.questionStyle}
                        >
                            where do you want to refund your money?
                        </label>
                        <br/>
                        <input
                            className='refund'
                            type='text'
                            name='place'
                            value={place}
                            onChange={onChange}
                            required
                            placeholder='EXP: Pasargad Hotel, Tehran'
                            maxLength={100}
                        />
                    </div>
                    <div style={{marginBottom: 15}}>
                        <label
                            htmlFor='amount'
                            style={styles.questionStyle}
                        >
                            How much money do you want to be refunded in your default currency?
                        </label>
                        <br/>
                        {width > 767
                            ? (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: 55,
                                        alignItems: 'center',
                                    }}>
                                        <input
                                            className='refund'
                                            type='number'
                                            name='amount'
                                            value={amount}
                                            onChange={onChange}
                                            required
                                            placeholder='EXP: 300000'
                                        />
                                        <label style={{color: 'gray', fontSize: 13}}>IRR</label>
                                        <img
                                            src='/Images/Icons/info.svg'
                                            alt='info'
                                            style={{
                                                marginLeft: 60,
                                                marginRight: 10,
                                                width: 14,
                                            }}
                                        />
                                        <div style={{
                                            fontSize: 12,
                                            color: 'black',
                                        }}>
                                            {currency === '1'
                                                ? (
                                                    <>
                                                        1 EUR
                                                        <i className='fa fa-exchange' style={{marginLeft: 7, marginRight: 7}} />
                                                        {divideNum(euroPrice)} IRR {' '}
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        1 USD
                                                        <i className='fa fa-exchange' style={{marginLeft: 7, marginRight: 7}} />
                                                        {divideNum(dollarPrice)} IRR {' '}
                                                    </>
                                                )
                                            }
                                            <br/>
                                            <label style={{fontSize: 10, color: 'gray'}}>
                                                {lastUpdate.slice(0,4)}/
                                                {lastUpdate.slice(5,7)}/
                                                {lastUpdate.slice(8,10)}
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{marginTop: -3, marginLeft: 5, color: 'gray', fontSize: 10}}>
                                        Provide approximate or exact amount in Iranian Rial
                                    </div>
                                </>
                            )
                            : (
                                <>
                                    <div style={{paddingTop: 10}}>
                                        <input
                                            className='refund'
                                            type='number'
                                            name='amount'
                                            value={amount}
                                            onChange={onChange}
                                            required
                                            placeholder='EXP: 300000'
                                        />
                                        <label style={{color: 'gray', fontSize: 13}}>IRR</label>
                                        <div style={{
                                            marginTop: 3,
                                            marginBottom: 7,
                                            marginLeft: 5,
                                            color: 'gray',
                                            fontSize: 10
                                        }}>
                                            Provide approximate or exact amount in Iranian Rial
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginLeft: 5,
                                            fontSize: 12,
                                            color: 'black',
                                        }}>
                                            <img
                                                src='/Images/Icons/info-orange.svg'
                                                alt='info'
                                                style={{
                                                    marginRight: 8,
                                                    width: 14,
                                                }}
                                            />
                                            <div>
                                                {currency === '1'
                                                    ? (
                                                        <>
                                                            1 EUR
                                                            <i className='fa fa-exchange' style={{marginLeft: 7, marginRight: 7}} />
                                                            {divideNum(euroPrice)} IRR {' '}
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            1 USD
                                                            <i className='fa fa-exchange' style={{marginLeft: 7, marginRight: 7}} />
                                                            {divideNum(dollarPrice)} IRR {' '}
                                                        </>
                                                    )
                                                }
                                                <br/>
                                                <label style={{fontSize: 10, color: 'gray'}}>
                                                    {lastUpdate.slice(0,4)}/
                                                    {lastUpdate.slice(5,7)}/
                                                    {lastUpdate.slice(8,10)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <div style={{marginBottom: 15}}>
                        <label
                            htmlFor='phone'
                            style={styles.questionStyle}
                        >
                            Provide your available phone number, in case we need more details
                        </label>
                        <input
                            className='phone-input-in-refund'
                            type='text'
                            name='phone'
                            value={phone}
                            onChange={onChange}
                            maxLength='20'
                            required
                            placeholder='To call you'
                        />
                    </div>
                    {width > 767
                        ? (
                            <div
                                className='grid-2-for-btns-in-refund'
                                style={{display: 'flex', alignItems: 'center', marginTop: 25}}
                            >
                                <a
                                    href='/myCard'
                                    className='btn text-center'
                                    style={styles.cancelBtnStyle}
                                >
                                    Cancel
                                </a>
                                <input
                                    type='submit'
                                    value='Refund'
                                    className='btn text-center'
                                    style={styles.refundBtnStyle}
                                />
                            </div>
                        )
                        : (
                            <div style={{marginTop: -5}}>
                                <input
                                    type='submit'
                                    value='Refund'
                                    className='btn text-center'
                                    style={styles.refundBtnStyle_mobile}
                                />
                            </div>
                        )
                    }
                </form>
            </>
        )
    };

    const showScreen3 = () => {
        return (
            <div>
                <div
                    className='font-in-refund form2-in-refund1'
                    style={{
                        color: '#626465',
                        textAlign: 'center',
                    }}>
                    Enter the verification code we just sent to {' '}
                    <b>{iranian_phone_number}</b>
                </div>
                <form onSubmit={refund}>
                    <div className='form2-in-refund2'>
                        <div
                            className='all-center'
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 30
                            }}>
                            <i className="fa fa-mobile font-mobile-icon-in-refund" style={{color: '#797979'}} />
                            <input
                                className='otp-input-in-refund'
                                type='text'
                                name='otp'
                                value={otp}
                                onChange={onChange1}
                                required
                            />
                        </div>
                        <div
                            className='all-center font-in-refund2'
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 10,
                            }}>
                            <label style={{color: '#626465'}}>Don't receive the code?</label>
                            <button
                                onClick={getOTP}
                                className='btn font-in-refund2'
                                style={{
                                    backgroundColor: 'white',
                                    marginLeft: 8,
                                    padding: 0,
                                    color: 'blue',
                                }}
                            >
                                Resend
                            </button>
                        </div>
                    </div>
                    {width > 767
                        ? (
                            <div
                                className='grid-2-for-btns-in-refund'
                                style={{display: 'flex', alignItems: 'center', marginTop: 25}}
                            >
                                <button
                                    onClick={back}
                                    className='btn text-center'
                                    style={styles.cancelBtnStyle}
                                >
                                    Back
                                </button>
                                <input
                                    type='submit'
                                    value='Verify'
                                    className='btn text-center'
                                    style={styles.refundBtnStyle}
                                />
                            </div>
                        )
                        : (
                            <div
                                style={{marginTop: 60, marginBottom: 0}}
                            >
                                <input
                                    type='submit'
                                    value='Verify'
                                    className='btn text-center'
                                    style={styles.refundBtnStyle_mobile}
                                />
                                <button
                                    onClick={back}
                                    className='btn text-center'
                                    style={styles.cancelBtnStyle_mobile}
                                >
                                    Back
                                </button>
                            </div>
                        )
                    }
                </form>
            </div>
        )
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (props.location.state && isAuthenticated) {
            setFirstName(props.location.state.firstName);
            setLastName(props.location.state.lastName);
            setBalance(props.location.state.balance);
            setPan(props.location.state.cardNum);
            setDollarPrice(props.location.state.dollarPrice);
            setEuroPrice(props.location.state.euroPrice);
            setLastUpdate(props.location.state.lastUpdate);
            setForm({ ...form,
                pan: props.location.state.cardNum,
                firstName: props.location.state.firstName,
                lastName: props.location.state.lastName });
        } else {
            history.push('/myCard');
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, props.location.state, isAuthenticated, width]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={3}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='myCard'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            <Card
                                width={width}
                                firstName={firstName}
                                lastName={lastName}
                                balance={balance}
                                cardNum={pan}
                            />
                            <div style={styles.smallCadreStyle}>
                                <strong style={{fontSize: 20}}>Refund</strong>
                                {pan === '---- ---- ---- ----'
                                    ? showScreen0()
                                    : (
                                        iranian_phone_number === ''
                                            ? showScreen1()
                                            : (
                                                show === true
                                                    ? showScreen2()
                                                    : showScreen3()
                                            )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 20,
                            paddingBottom: 100,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <Card
                                width={width}
                                firstName={firstName}
                                lastName={lastName}
                                balance={balance}
                                cardNum={pan}
                                dollarPrice={dollarPrice}
                                euroPrice={euroPrice}
                                currency={currency}
                                haveBtn={false}
                                haveCard={false}
                            />
                            <div style={styles.smallCadreStyle_mobile}>
                                <strong style={{fontSize: 16}}>Refund</strong>
                                {pan === '---- ---- ---- ----'
                                    ? showScreen0()
                                    : (
                                        iranian_phone_number === ''
                                            ? showScreen1()
                                            : (
                                                show === true
                                                    ? showScreen2()
                                                    : showScreen3()
                                            )
                                    )
                                }
                            </div>
                        </div>
                        <BottomMenu />
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 0,
    },
    smallCadreStyle: {
        backgroundColor: 'white',
        width: '100%',
        padding: 20,
        paddingLeft: 35,
        paddingRight: 35,
        borderRadius: 10,
    },
    questionStyle: {
        textAlign: 'justify',
        fontSize: 14,
        color: '#626465'
    },
    inputStyle: {
        margin: 0,
        marginTop: 10,
    },
    refundBtnStyle: {
        backgroundColor: 'orange',
        color: 'white',
        fontSize: 14,
        height: 32,
        width: 100,
        margin: 0,
        marginTop: 10,
        paddingTop: 2.5,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 2,
        borderRadius: 15,
    },
    cancelBtnStyle: {
        backgroundColor: 'white',
        color: 'orange',
        fontSize: 14,
        height: 32,
        width: 100,
        margin: 0,
        marginTop: 10,
        paddingTop: 3,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 1.5,
        borderRadius: 15,
    },
    smallCadreStyle_mobile: {
        backgroundColor: 'white',
        width: '100%',
        marginTop: -220,
        padding: 15,
    },
    refundBtnStyle_mobile: {
        backgroundColor: 'orange',
        color: 'white',
        fontSize: 14,
        height: 35,
        width: '100%',
        margin: 0,
        marginTop: 10,
        paddingTop: 4,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 2,
        borderRadius: 8,
    },
    cancelBtnStyle_mobile: {
        backgroundColor: 'white',
        color: 'orange',
        fontSize: 14,
        height: 35,
        width: '100%',
        margin: 0,
        marginTop: 10,
        paddingTop: 4.5,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 1.5,
        borderRadius: 8,
    }
};

export default Refund;
