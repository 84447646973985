import React, {Fragment, useContext, useEffect, useState} from 'react';
import AuthContext from "../../../context/auth/authContext";
import PopUpLogin from "../../layout/PopUpLogin";
import Header from "../../layout/Header";
import Form from '../../layout/Forms/Form';
import BottomMenu from "../../layout/BottomMenu";
import '../../../Styles/NewStyles.css';
import '../../../App.css';

const CardReq = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated } = authContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[]);

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    return (
        <Fragment>
            {showPopUp()}
            <Header defaultSelected='packages'/>
            {width > 767
                ? <div
                    style={{
                        position: 'absolute',
                        width: width,
                        marginLeft: 0,
                        paddingLeft: 220,
                    }}
                >
                    <Form
                        type_num={2}
                        width={width}
                    />
                </div>
                : (
                    <>
                        <div style={{backgroundColor: 'white', height: height - 80}}>
                            <Form
                                type_num={2}
                                width={width}
                            />
                        </div>
                        <BottomMenu/>
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth,
        marginLeft: 0,
        paddingLeft: 220,
    },*/
};

export default CardReq;
