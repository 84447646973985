import React from 'react';
import '../../Styles/DialogBox.css';
import '../../App.css';

const SpinnerModal = () => {
    return (
        <>
            <div className='popup all-center' style={{zIndex: 5}}>
                <div className='popup_inner' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                    <div className='container m-1' style={{padding: 30}}>
                        <img
                            src='/Images/Spinner-light.svg'
                            alt='loading...'
                            style={{ width: 75, margin: 'auto', display: 'block' }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpinnerModal;
