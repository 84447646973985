import React, {useState, useEffect} from 'react';
import {Collapse} from 'react-collapse';
import PropTypes from 'prop-types';
import '../../Styles/NewStyles.css';
import '../../App.css';

const OrderDetails = ({order, width1}) => {
    const {orderType, createDateTime, price, status, firstName, lastName} = order;

    const orderOptions = ['Intravel Card & SIMCard', 'Intravel Card', 'SIMCard'];
    const statusOptions = ['Registered', 'In Progress', 'Issued'];

    const [icon, setIcon] = useState('fa fa-angle-down');
    const [detailOpen, setDetailOpen] = useState(false);

    const [width, setWidth] = useState('100%');

    useEffect(() => {
        if (detailOpen) {
            setIcon('fa fa-angle-up')
        } else {
            setIcon('fa fa-angle-down')
        }
        if (width1 < 800) {
            setWidth(86);
        } else {
            setWidth('100%');
        }
    },[detailOpen, status, width1]);

    const showDetail = (e) => {
        e.preventDefault();
        setDetailOpen(!detailOpen);
    };

    const showCard = () => {
        if (orderType !== 3) { //Card & SIMCard - Card
            return (
                <div>
                    {status === 1
                        ? <img
                            src='/Images/OrderImage/Card-s1.png'
                            alt='Intravel Card'
                            style={styles.cardImgStyle}
                        />
                        : (
                            status === 2
                                ? <img
                                    src='/Images/OrderImage/Card-s2.png'
                                    alt='Intravel Card'
                                    style={styles.cardImgStyle}
                                />
                                : <img
                                    src='/Images/OrderImage/Card-s3.png'
                                    alt='Intravel Card'
                                    style={styles.cardImgStyle}
                                />
                        )
                    }
                    <div style={styles.titleStyle}>
                        IntravelCard
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <a href='/packages'>
                        <img
                            src='/Images/OrderImage/Card-order.png'
                            alt='Intravel Card'
                            style={styles.cardImgStyle}
                        />
                    </a>
                    <div style={styles.titleStyle}>
                        IntravelCard
                    </div>
                </div>
            );
        }
    };

    const showSIMCard = () => {
        if (orderType !== 2) { //Card & SIMCard - SIMCard
            return (
                <div>
                    {status === 1
                        ? <img
                            src='/Images/OrderImage/SIMCard-s1.png'
                            alt='SIMCard'
                            style={styles.cardImgStyle}
                        />
                        : (
                            status === 2
                                ? <img
                                    src='/Images/OrderImage/SIMCard-s2.png'
                                    alt='SIMCard'
                                    style={styles.cardImgStyle}
                                />
                                : <img
                                    src='/Images/OrderImage/SIMCard-s3.png'
                                    alt='SIMCard'
                                    style={styles.cardImgStyle}
                                />
                        )
                    }
                    <div style={styles.titleStyle}>
                        Sim Card
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <a href='/packages'>
                        <img
                            src='/Images/OrderImage/SIMCard-order.png'
                            alt='SIMCard'
                            style={styles.cardImgStyle}
                        />
                    </a>
                    <div style={styles.titleStyle}>
                        Sim Card
                    </div>
                </div>
            );
        }
    };

    const showCard_mobile = () => {
        if (orderType !== 3) { //Card & SIMCard - Card
            return (
                <div className='grid-2-in-orders' style={styles.cardContentStyle}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            src='/Images/OrderImage/Card-mobile.png'
                            alt='Intravel Card'
                            style={styles.cardImgStyle}
                        />
                    </div>
                    <div style={styles.titleStyle_mobile} className='grid-2-in-orders2'>
                        <div style={{height: '50%'}}>
                            <label>IntravelCard</label>
                            <br/>
                            <div style={{marginLeft: 5, fontSize: 10}}>
                                {firstName} {lastName}
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '50%'}}>
                            {status === 1
                                ? <img
                                    src='/Images/OrderImage/Reg.png'
                                    alt='Register'
                                    style={styles.statusImgStyle}
                                />
                                : (
                                    status === 2
                                        ? <img
                                            src='/Images/OrderImage/Prog.png'
                                            alt='In Progress'
                                            style={styles.statusImgStyle}
                                        />
                                        : <img
                                            src='/Images/OrderImage/Isseu.png'
                                            alt='Issued'
                                            style={styles.statusImgStyle}
                                        />
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='grid-2-in-orders' style={styles.cardContentStyle}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            src='/Images/OrderImage/Card-mobile.png'
                            alt='Intravel Card'
                            style={styles.cardImgStyle}
                        />
                    </div>
                    <div style={styles.titleStyle_mobile} className='grid-2-in-orders2'>
                        <div style={{height: '50%'}}>
                            <label>IntravelCard</label>
                            <br/>
                            <div style={{marginTop: 5, fontSize: 10}}>
                                <i className="fa fa-check"  style={{color: '#0bdefa'}}/> {' '}
                                Withdraw cash from ATMs <br/>
                                <i className="fa fa-check"  style={{color: '#0bdefa'}}/> {' '}
                                Shop Online <br/>
                                <i className="fa fa-check"  style={{color: '#0bdefa'}}/> {' '}
                                Pay at local shops
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '50%'}}>
                            <a href='/packages'>
                                <img
                                    src='/Images/OrderImage/order.png'
                                    alt='Order Now'
                                    style={styles.orderImgStyle}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const showSIMCard_mobile = () => {
        if (orderType !== 2) { //Card & SIMCard - SIMCard
            return (
                <div className='grid-2-in-orders' style={styles.cardContentStyle}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            src='/Images/OrderImage/SIMCard-mobile.png'
                            alt='SIM Card'
                            style={styles.cardImgStyle}
                        />
                    </div>
                    <div style={styles.titleStyle_mobile} className='grid-2-in-orders2'>
                        <div style={{height: '50%'}}>
                            <label>SIM Card</label>
                            <br/>
                            <div style={{marginLeft: 5, fontSize: 10}}>
                                {firstName} {lastName}
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '50%'}}>
                            {status === 1
                                ? <img
                                    src='/Images/OrderImage/Reg.png'
                                    alt='Register'
                                    style={styles.statusImgStyle}
                                />
                                : (
                                    status === 2
                                        ? <img
                                            src='/Images/OrderImage/Prog.png'
                                            alt='In Progress'
                                            style={styles.statusImgStyle}
                                        />
                                        : <img
                                            src='/Images/OrderImage/Isseu.png'
                                            alt='Issued'
                                            style={styles.statusImgStyle}
                                        />
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='grid-2-in-orders' style={styles.cardContentStyle}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            src='/Images/OrderImage/SIMCard-mobile.png'
                            alt='SIM Card'
                            style={styles.cardImgStyle}
                        />
                    </div>
                    <div style={styles.titleStyle_mobile} className='grid-2-in-orders2'>
                        <div style={{height: '50%'}}>
                            <label>SIM Card</label>
                            <br/>
                            <div style={{marginTop: 5, fontSize: 10}}>
                                <i className="fa fa-check"  style={{color: 'orange'}}/> {' '}
                                Irancell SIM Card <br/>
                                <i className="fa fa-check"  style={{color: 'orange'}}/> {' '}
                                1.5 GB Data <br/>
                                <br/>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '50%'}}>
                            <a href='/packages'>
                                <img
                                    src='/Images/OrderImage/order.png'
                                    alt='Order Now'
                                    style={styles.orderImgStyle}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {width1 > 767
                ? (
                    <div style={styles.contentStyle}>
                        <div className='grid-4' style={{alignItems: 'center'}}>
                            <label>{orderOptions[orderType-1]}</label>
                            <label>{createDateTime.slice(0,10)}</label>
                            <label>{price}</label>
                            <label className='grid-2' style={{alignItems: 'center'}}>
                                {statusOptions[status-1]}
                                <button
                                    className='btn all-center'
                                    style={styles.openBtnStyle}
                                    onClick={showDetail}
                                    title='details'
                                >
                                    <i className={`${icon}`} style={styles.openIconStyle}/>
                                </button>
                            </label>
                        </div>
                        <Collapse isOpened={detailOpen}>
                            <div className='shadowBox-in-orders' style={styles.detailStyle}>
                                <div style={{ padding: 30, paddingTop: 15, paddingBottom: 15, fontSize: 16, color: 'black' }}>
                                    {firstName} {lastName}
                                </div>
                                <div className='shadowLine' style={styles.lineStyle}/>
                                <div className='grid-4' style={{padding: 30, paddingTop: 20, paddingBottom: 20}}>
                                    <div style={{width: width}}>
                                        {showCard()}
                                    </div>
                                    <div style={{width: width}}>
                                        {showSIMCard()}
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                )
                :(
                    <>
                        <div style={styles.contentStyle_mobile}>
                            <div className='grid-5-in-orders'
                                 style={{
                                    alignItems: 'center', textAlign: 'center',
                                    paddingLeft: 15, paddingRight: 15
                                 }}>
                                <label>{orderOptions[orderType-1]}</label>
                                <label>{createDateTime.slice(0,10)}</label>
                                <label>{price}</label>
                                <label className='grid-2' style={{alignItems: 'center'}}>
                                    {statusOptions[status-1]}
                                </label>
                                <button
                                    className='btn all-center'
                                    style={styles.openBtnStyle}
                                    onClick={showDetail}
                                    title='details'
                                >
                                    <i className={`${icon}`} style={styles.openIconStyle}/>
                                </button>
                            </div>
                            <Collapse isOpened={detailOpen}>
                                <div style={{marginTop: 10,}}>
                                    {showCard_mobile()}
                                    <div style={{
                                        width: '80%',
                                        margin: 'auto',
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderColor: '#ddd'
                                    }} />
                                    {showSIMCard_mobile()}
                                </div>
                                <div style={{
                                    width: '90%',
                                    margin: 'auto',
                                    borderStyle: 'solid',
                                    borderColor: '#003e57',
                                    borderWidth: 0,
                                    borderBottomWidth: 1.5,
                                }} />
                            </Collapse>
                        </div>
                    </>
                )
            }
        </>
    )
};

OrderDetails.propTypes = {
    order: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
};

const styles = {
    contentStyle: {
        padding: 15,
        fontSize: 13,
        color: '#626465',
        borderStyle: 'solid',
        borderColor: 'gray',
        borderWidth: 0,
        borderBottomWidth: 1,
    },
    openBtnStyle: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderWidth: 0,
        padding: 0,
        opacity: 1,
        alignItems: 'center',
        display: 'flex',
    },
    openIconStyle: {
        position: 'relative',
        color: '#69c8ce',
        fontSize: 18,
        fontWeight: 600,
    },
    detailStyle: {
        backgroundColor: 'white',
        marginTop: 30,
        marginBottom: 10,
        borderRadius: 10,
    },
    cardImgStyle: {
        width: '100%',
        borderRadius: 10,
    },
    lineStyle: {
        margin: 0,
        borderStyle: 'solid',
        borderColor: 'rgb(242, 242, 242)',
        borderWidth: 1
    },
    titleStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        color: 'black'
    },
    contentStyle_mobile: {
        backgroundColor: 'white',
        marginBottom: 1.5,
        padding: 12,
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: 10,
        color: '#626465',
    },
    cardContentStyle: {
        //backgroundColor: 'yellow',
        padding: 15,
        paddingTop: 15,
        paddingBottom: 15,
    },
    titleStyle_mobile: {
        fontSize: 13,
        color: '#626465',
    },
    statusImgStyle: {
        width: 80,
        //marginTop: 43,
        //marginBottom: -3
    },
    orderImgStyle: {
        width: 80,
        //marginTop: 6,
        marginBottom: -5
    }
};

export default OrderDetails;
