import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    CHANGE_PASS,
    LOAD_PROFILE,
    LOAD_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('id', action.payload.id);
            localStorage.setItem('username', action.payload.username);
            localStorage.setItem('first_name', action.payload.first_name);
            localStorage.setItem('last_name', action.payload.last_name);
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('isAuthenticated', 'true');

            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
            };
        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case LOAD_FAIL:
        case LOGOUT:
            localStorage.removeItem('id');
            localStorage.removeItem('username');
            localStorage.removeItem('first_name');
            localStorage.removeItem('last_name');
            localStorage.removeItem('token');
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('phone');
            localStorage.removeItem('iranian_phone_number');
            localStorage.removeItem('profile_pic');
            localStorage.removeItem('currency');
            return {
                ...state,
                id: null,
                username: null,
                first_name: null,
                last_name: null,
                token: null,
                isAuthenticated: false,
                phone: null,
                iranian_phone_number: null,
                profile_pic: null,
                currency: null,
                error: action.payload,
            };
        case CHANGE_PASS:
            return {
                ...state,
                error: action.payload,
            };

        case LOAD_PROFILE:
            action.payload.phone !== null
                ? localStorage.setItem('phone', action.payload.phone)
                : localStorage.setItem('phone', '');
            action.payload.iranian_phone_number !== null
                ? localStorage.setItem('iranian_phone_number', action.payload.iranian_phone_number)
                : localStorage.setItem('iranian_phone_number', '');
            action.payload.profile_pic !== null
                ? localStorage.setItem('profile_pic', action.payload.profile_pic)
                : localStorage.setItem('profile_pic', '');
            localStorage.setItem('currency', action.payload.currency);
            return {
                ...state,
                phone: action.payload.phone,
                iranian_phone_number: action.payload.iranian_phone_number,
                profile_pic: action.payload.profile_pic,
                currency: action.payload.currency,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};
