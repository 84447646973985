import React, {Fragment} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Switch, Route} from 'react-router';

import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import DoneState from "./context/doneProcess/DoneState";
import ToggleState from "./context/toggleOfSidebar/ToggleState";

import Dashboard from "./components/pages/Dashboard";
import TopUp from "./components/pages/TopUp";
import MyCard from "./components/pages/MyCard";
import Refund from "./components/pages/Refund";
import Lost from "./components/pages/Lost";
import Reports from "./components/pages/Reports";
import Converter from "./components/pages/Converter";
import Orders from "./components/pages/Orders";
import Packages from "./components/pages/Packages";
import CardSIMCardReq from "./components/pages/Orders/CardSIMCardReq";
import CardReq from "./components/pages/Orders/CardReq";
import SIMCardReq from "./components/pages/Orders/SIMCardReq";
import FamilyPackageReq from "./components/pages/Orders/FamilyPackageReq";
import Settings from "./components/pages/Settings";
import EditProfile from "./components/pages/EditProfile";
import NotFound from "./components/pages/NotFound";

import "./App.css";

const App = () => {
    return (
        <AuthState>
            <AlertState>
                <DoneState>
                    <ToggleState>
                        <Router>
                            <Fragment>
                                <Switch>
                                    <Route exact path='/' component={Dashboard}/>
                                    <Route exact path='/packages' component={Packages}/>
                                    <Route exact path='/Card&SIMCard-Order' component={CardSIMCardReq}/>
                                    <Route exact path='/Card-Order' component={CardReq}/>
                                    <Route exact path='/SIMCard-Order' component={SIMCardReq}/>
                                    <Route exact path='/Family-Package-Order' component={FamilyPackageReq}/>
                                    <Route exact path='/dashboard' component={Dashboard}/>
                                    <Route exact path='/top-up' component={TopUp}/>
                                    <Route exact path='/myCard' component={MyCard}/>
                                    <Route exact path='/refund' component={Refund}/>
                                    <Route exact path='/lost' component={Lost}/>
                                    <Route exact path='/reports' component={Reports}/>
                                    <Route exact path='/converter' component={Converter}/>
                                    <Route exact path='/orders' component={Orders}/>
                                    <Route exact path='/settings' component={Settings}/>
                                    {window.innerWidth > 767
                                        ? <Route exact path='/edit-profile' component={Settings}/>
                                        : <Route exact path='/edit-profile' component={EditProfile}/>
                                    }
                                    <Route component={NotFound}/>
                                </Switch>
                            </Fragment>
                        </Router>
                    </ToggleState>
                </DoneState>
            </AlertState>
        </AuthState>
    );
};

export default App;
