import {SET_Done, REMOVE_Done} from '../types';

export default (state, action) => {
    switch (action.type) {
        case SET_Done:
            return true;
        case REMOVE_Done:
            return false;
        default:
            return state;
    }
};
