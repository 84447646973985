import React, {Fragment, useContext} from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import PropTypes from 'prop-types';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import history from '../history';
import '../../Styles/NewStyles.css';

const Sidebar = ({ defaultSelected, width, height }) => {
    const authContext = useContext(AuthContext);
    const { logout } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle, setToggle } = toggleContext;

    return (
        <Fragment>
            {width > 767
                ? (
                    <SideNav
                        id='side'
                        expanded={toggle}
                        onToggle={() => setToggle()}
                        onSelect={(eventKey) => {
                            if (eventKey === 'logout') {
                                logout(defaultSelected);
                            }
                            if (eventKey !== 'logout') {
                                history.push(`/${eventKey}`);
                            }
                        }}
                        style={{
                            position: 'absolute',
                            marginTop: 135.6,
                            height: height,
                            backgroundColor: 'white',
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                        }}
                    >
                        <Toggle />
                        <Nav defaultSelected={defaultSelected}>
                            <NavItem eventKey='dashboard' style={{marginTop: -10}}>
                                <NavIcon>
                                    <i className='fa fa-fw fa-home' style={styles.homeIconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Dashboard</NavText>
                            </NavItem>
                            <NavItem eventKey='myCard'>
                                <NavIcon>
                                    <i className='fa fa-credit-card' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>My Card</NavText>
                            </NavItem>
                            <NavItem eventKey='reports'>
                                <NavIcon>
                                    <i className='fa fa-file-text-o' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Reports</NavText>
                            </NavItem>
                            <NavItem eventKey='converter'>
                                <NavIcon>
                                    <i className='fa fa-exchange' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Converter</NavText>
                            </NavItem>
                            <NavItem eventKey='orders'>
                                <NavIcon>
                                    <i className='fa fa-list-ul' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Orders</NavText>
                            </NavItem>
                            <NavItem eventKey='packages'>
                                <NavIcon>
                                    <i className='fa fa-th-large' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Services</NavText>
                            </NavItem>
                            <NavItem eventKey='settings'>
                                <NavIcon>
                                    <i className='fa fa-cog' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Settings</NavText>
                            </NavItem>
                            <NavItem eventKey='logout'>
                                <NavIcon>
                                    <i className='fa fa-sign-out' style={styles.iconStyle} />
                                </NavIcon>
                                <NavText style={styles.textStyle}>Log Out</NavText>
                            </NavItem>
                        </Nav>
                    </SideNav>
                )
                : null
            }
        </Fragment>
    )
};

Sidebar.propTypes = {
    defaultSelected: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

const styles = {
    /*sideNavStyle: {
        position: 'absolute',
        marginTop: 135.6,
        height: window.innerHeight,
        backgroundColor: 'white',
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
    },*/
    textStyle: {
        fontSize: 13,
        color: 'black'
    },
    iconStyle: {
        fontSize: 15,
        color: 'black'
    },
    homeIconStyle: {
        fontSize: 18,
        color: 'black'
    }
};

export default Sidebar;
