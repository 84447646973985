import React, {Fragment} from 'react';

const Spinner = () => {
    return (
        <Fragment>
            <img
                src='/Images/Spinner-dark.svg'
                alt='loading...'
                style={{ width: 30, margin: 'auto', marginTop: 15, display: 'block' }}
            />
        </Fragment>
    )
};

export default Spinner
