import React, {useReducer} from 'react';
import DoneContext from './doneContext';
import doneReducer from './doneReducer';
import {SET_Done, REMOVE_Done} from '../types';

const DoneState = props => {
    const initialState = null;

    const [state, dispatch] = useReducer(doneReducer, initialState);

    const setDone = () => {
        dispatch({
            type: SET_Done,
        });

        setTimeout(() => dispatch({
            type: REMOVE_Done
        }), 1000);
    };

    return (
        <DoneContext.Provider
            value={{
                done: state,
                setDone
            }}
        >
            {props.children}
        </DoneContext.Provider>
    )
};

export default DoneState;
