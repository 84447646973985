export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOAD_PROFILE = 'LOAD_PROFILE';
export const LOAD_FAIL = 'LOAD_FAIL';
export const LOGOUT = 'LOGOUT';
export const CHANGE_PASS = 'CHANGE_PASS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_Done = 'SET_Done';
export const REMOVE_Done = 'REMOVE_Done';
export const SET_TOGGLE = 'SET_TOGGLE';
