import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import '../../Styles/NewStyles.css';
import '../../App.css';

const Card = ({width, firstName, lastName, balance, cardNum, haveBtn, dollarPrice, euroPrice, currency, haveCard}) => {
    const divideNum = (num) => {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    return (
        <>
            {width > 767
                ? (
                    <div style={styles.row1Style}>
                        <img
                            src='/Images/Card.png'
                            alt='Intravel Card'
                            style={styles.cardImgStyle}
                        />
                        {cardNum === '---- ---- ---- ----'
                            ? (
                                <div style={{
                                    position: 'absolute',
                                    top: 107+135,
                                    marginLeft: 26,
                                    fontSize: 35,
                                    //fontWeight: 'bold',
                                    color: 'white'
                                }}>
                                    {cardNum}
                                </div>
                            )
                            : (
                                <div style={{
                                    position: 'absolute',
                                    top: 121+135,
                                    marginLeft: 23,
                                    fontSize: 19,
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}>
                                    {cardNum.toString().slice(0,4)} {' '}
                                    {cardNum.toString().slice(4,8)} {' '}
                                    {cardNum.toString().slice(8,12)} {' '}
                                    {cardNum.toString().slice(12,16)} {' '}
                                </div>
                            )
                        }
                        <div style={{
                            position: 'absolute',
                            top: 194+135,
                            marginLeft: 23,
                            fontSize: 10,
                            color: 'white'
                        }}>
                            {firstName} {' '} {lastName}
                        </div>
                        <div style={styles.cardInfoStyle}>
                            <div style={{marginBottom: 8}}>
                                <label style={styles.titleStyle}>Acc Holder</label>
                                <br/>
                                <label style={styles.infoStyle}>
                                    {firstName} {' '} {lastName}
                                </label>
                            </div>
                            <div style={{marginBottom: 8}}>
                                <label style={styles.titleStyle}>Your Balance</label>
                                <br/>
                                <label style={styles.infoStyle}>
                                    {balance !== null ? <label>{divideNum(balance)} IRR</label> : null}
                                </label>
                            </div>
                            <div style={{marginBottom: 8}}>
                                <label style={styles.titleStyle}>Card Num</label>
                                <br/>
                                <label style={styles.infoStyle}>
                                    {cardNum === '---- ---- ---- ----'
                                        ? cardNum
                                        :
                                        cardNum.toString().slice(0,4) + ' ' +
                                        cardNum.toString().slice(4,8) + ' ' +
                                        cardNum.toString().slice(8,12) + ' ' +
                                        cardNum.toString().slice(12,16) + ' '
                                    }
                                </label>
                            </div>
                        </div>
                        {haveBtn === true
                            ? (
                                width > 800
                                    ? (
                                        <div style={{
                                            position: 'absolute',
                                            top: 190+135,
                                            right: '2%',
                                            marginRight: 35,
                                            display: 'flex',
                                            alignItems: 'flex-end'
                                        }}>
                                            <Link
                                                to={{
                                                    pathname: '/top-up',
                                                    state: {firstName, lastName, balance, cardNum}
                                                }}
                                                className='btn text-center'
                                                style={styles.topUpBtnStyle}
                                            >
                                                <strong style={{fontSize: 16}}>+ </strong> Top Up
                                            </Link>
                                        </div>
                                    )
                                    : (
                                        <div style={{
                                            position: 'absolute',
                                            top: 190+135,
                                            right: '2%',
                                            marginRight: '1.5%',
                                            display: 'flex',
                                            alignItems: 'flex-end'
                                        }}>
                                            <Link
                                                to={{
                                                    pathname: '/top-up',
                                                    state: {firstName, lastName, balance, cardNum}
                                                }}
                                                className='btn text-center'
                                                style={styles.topUpBtnStyle2}
                                                title='Top Up'
                                            >
                                                <label style={{position: 'relative', top: -4, fontSize: 20}}>+</label>
                                            </Link>
                                        </div>
                                    )
                            )
                            : null
                        }
                    </div>
                )
                : (
                    <div style={{height: 300}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: 0,
                            height: 75,
                            color: '#003e57',
                        }}>
                            <div>
                                <label style={{fontSize: 12}}>Your Balance</label>
                                {balance !== 0
                                    ? (
                                        <div className='text-right' style={{width: 'max-content'}}>
                                            <div style={{marginTop: -3, fontSize: 18, fontWeight: 'bold'}}>
                                                {balance !== null ? <label>{divideNum(balance)} IRR</label> : null}
                                            </div>
                                            <div style={{marginTop: -4, fontSize: 10}}>
                                                Approx {' '}
                                                {dollarPrice === 1 || euroPrice === 1
                                                    ? '-'
                                                    : (
                                                        currency === '1'
                                                            ? (balance/euroPrice).toFixed(2) + ' €'
                                                            : (balance/dollarPrice).toFixed(2) + ' $'
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <div style={{width: 'max-content'}}>
                                            <div style={{marginTop: -3, fontSize: 18, fontWeight: 'bold'}}>
                                                {balance} IRR
                                            </div>
                                            <div style={{marginTop: 0, fontSize: 10}}>
                                                Approx {' '}
                                                {dollarPrice === 1 || euroPrice === 1
                                                    ? '-'
                                                    : (
                                                        currency === '1'
                                                            ? (balance/euroPrice).toFixed(2) + ' €'
                                                            : (balance/dollarPrice).toFixed(2) + ' $'
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {haveBtn === true
                                ? (
                                    <div style={{
                                        position: 'absolute',
                                        top: 130,
                                        right: 10,
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Link
                                            to={{
                                                pathname: '/top-up',
                                                state: {firstName, lastName, balance, cardNum}
                                            }}
                                            className='btn text-center'
                                            style={styles.topUpBtnStyle}
                                        >
                                            <strong style={{fontSize: 16}}>+ </strong> Top Up
                                        </Link>
                                    </div>
                                )
                                : null
                            }
                        </div>
                        {haveCard === true
                            ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 300,
                                    margin: 'auto',
                                    marginTop: 15,
                                }}>
                                    <div style={{width: 300}}>
                                        <img
                                            src='/Images/Card.png'
                                            alt='Intravel Card'
                                            style={{borderRadius: 10}}
                                        />
                                        {cardNum === '---- ---- ---- ----'
                                            ? (
                                                <div style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: 300,
                                                    top: 247,
                                                    //marginLeft: -35,
                                                    fontSize: 40,
                                                    color: 'white',
                                                }}>
                                                    {cardNum}
                                                </div>
                                            )
                                            : (
                                                <div style={{
                                                    position: 'absolute',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: 300,
                                                    top: 265,
                                                    //marginLeft: -30,
                                                    fontSize: 21,
                                                    fontWeight: 'bold',
                                                    color: 'white',
                                                }}>
                                                    {cardNum.toString().slice(0,4)} {' '}
                                                    {cardNum.toString().slice(4,8)} {' '}
                                                    {cardNum.toString().slice(8,12)} {' '}
                                                    {cardNum.toString().slice(12,16)} {' '}
                                                </div>
                                            )
                                        }
                                        <div style={{
                                            position: 'absolute',
                                            top: 350,
                                            marginLeft: 18,
                                            marginRight: 148,
                                            fontSize: 10,
                                            color: 'white',
                                        }}>
                                            {firstName} {' '} {lastName}
                                        </div>
                                    </div>
                                </div>
                            )
                            : null
                        }
                    </div>
                )
            }
        </>
    )
};

Card.propTypes = {
    width: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    cardNum: PropTypes.string.isRequired,
    haveBtn: PropTypes.bool,
    dollarPrice: PropTypes.number,
    euroPrice: PropTypes.number,
    currency: PropTypes.string,
    haveCard: PropTypes.bool
};
Card.defaultProps = {
    haveBtn: true,
    haveCard: true
};

const styles = {
    row1Style: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        width: '100%',
        marginBottom: 25,
        padding: 30,
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 10,
        gridGap: 0
    },
    cardImgStyle: {
        width: 260,
        alignSelf: 'center',
        borderRadius: 10
    },
    cardInfoStyle: {
        marginLeft: 20,
        padding: 10,
        alignSelf: 'center',
    },
    titleStyle: {
        fontSize: 12,
        color: 'gray',
    },
    infoStyle: {
        fontSize: 14
    },
    topUpBtnStyle: {
        backgroundColor: 'orange',
        color: 'white',
        fontSize: 14,
        height: 32,
        width: 82,
        padding: 0,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderRadius: 15,
    },
    topUpBtnStyle2: {
        backgroundColor: 'orange',
        color: 'white',
        fontSize: 14,
        height: 30,
        width: 30,
        padding: 0,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderRadius: 10,
    }
};

export default Card;
