import React, {Fragment, useContext, useEffect, useState} from 'react';
import Select from 'react-select';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import SpinnerModal from '../layout/SpinnerModal';
import DialogBox from '../layout/DialogBox';
import BottomMenu from "../layout/BottomMenu";
import '../../Styles/NewStyles.css';
import '../../App.css';

const Converter = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(null);

    const [dollarPrice, setDollarPrice] = useState(1);
    const [euroPrice, setEuroPrice] = useState(1);
    const [lastUpdate, setLastUpdate] = useState('');
    const [haveInfo, setHaveInfo] = useState(false);

    const [state1, setState1] = useState(true);
    const [state2, setState2] = useState(true);

    const onChangeCurr = (e) => {
        e.preventDefault();
        setState1(!state1);
        setState2(!state2);
        if (currency.value === 1) {
            setCurrency({ value: 2, label: l2() });
            setCurr(2)
        } else if (currency.value === 2) {
            setCurrency({ value: 1, label: l1() });
            setCurr(1)
        } else if (currency.value === 3) {
            setCurrency({ value: 4, label: l4() });
            setCurr(4)
        } else {
            setCurrency({ value: 3, label: l3() });
            setCurr(3)
        }
    };

    const l0 = () => {
        return (
            width > 767
                ? (
                    <div className='exchange'>
                        <div className='center-exchange' style={{width: '45%', color: 'black'}}>
                            From
                        </div>
                        <button className='btn' style={styles.changeBtnStyle} onClick={onChangeCurr}>
                            <i
                                className="fa fa-exchange center-exchange"
                                style={{
                                    color: '#414aff',
                                    fontSize: 20, fontWeight: 'bold'
                                }}/>
                        </button>
                        <div className='center-exchange' style={{width: '45%', color: 'black'}}>
                            To
                        </div>
                    </div>
                )
                : (
                    <div className='exchange' style={{fontSize: 10}}>
                        <div className='center-exchange' style={{width: '45%', color: 'black'}}>
                            From
                        </div>
                        <button className='btn' style={styles.changeBtnStyle} onClick={onChangeCurr}>
                            <i
                                className="fa fa-exchange center-exchange"
                                style={{
                                    color: '#414aff',
                                    fontSize: 16, fontWeight: 'bold'
                                }}/>
                        </button>
                        <div className='center-exchange' style={{width: '45%', color: 'black'}}>
                            To
                        </div>
                    </div>
                )
        )
    };

    const l1 = () => {
        return (
            width > 767
                ? (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/EUR.jpg'
                                alt='EUR'
                                style={styles.countryImgStyle}
                            />
                            <div>
                                <label style={{fontSize: 12}}>EUR</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>Euro</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 16}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImg2Style}
                            />
                            <div>
                                <label style={{fontSize: 12}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/EUR.jpg'
                                alt='EUR'
                                style={styles.countryImgStyle_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>EUR</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>Euro</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 14}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImg2Style_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                    </div>
                )
        )
    };

    const l2 = () => {
        return (
            width > 767
                ? (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImgStyle}
                            />
                            <div>
                                <label style={{fontSize: 12}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 16}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/EUR.jpg'
                                alt='EUR'
                                style={styles.countryImg2Style}
                            />
                            <div>
                                <label style={{fontSize: 12}}>EUR</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>Euro</label>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImgStyle_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 14}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/EUR.jpg'
                                alt='EUR'
                                style={styles.countryImg2Style_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>EUR</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>Euro</label>
                            </div>
                        </div>
                    </div>
                )
        )
    };

    const l3 = () => {
        return (
            width > 767
                ? (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/USD.jpg'
                                alt='USD'
                                style={styles.countryImgStyle}
                            />
                            <div>
                                <label style={{fontSize: 12}}>USD</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>US Dollar</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 16}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImg2Style}
                            />
                            <div>
                                <label style={{fontSize: 12}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/USD.jpg'
                                alt='USD'
                                style={styles.countryImgStyle_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>USD</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>US Dollar</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 14}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImg2Style_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                    </div>
                )
        )
    };

    const l4 = () => {
        return (
            width > 767
                ? (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImgStyle}
                            />
                            <div>
                                <label style={{fontSize: 12}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 16}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/USD.jpg'
                                alt=''
                                style={styles.countryImg2Style}
                            />
                            <div>
                                <label style={{fontSize: 12}}>USD</label>
                                <br/>
                                <label style={{fontSize: 10, color: 'gray'}}>US Dollar</label>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className='exchange'>
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/IRR.jpg'
                                alt='IRR'
                                style={styles.countryImgStyle_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>IRR</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>Iranian Rial</label>
                            </div>
                        </div>
                        <i
                            className="fa fa-exchange center-exchange"
                            style={{width: '10%', color: 'gray', fontSize: 14}}
                        />
                        <div className='center-exchange'
                             style={styles.colStyleInSelect}
                        >
                            <img
                                src='/Images/Icons/USD.jpg'
                                alt=''
                                style={styles.countryImg2Style_mobile}
                            />
                            <div>
                                <label style={{fontSize: 10}}>USD</label>
                                <br/>
                                <label style={{fontSize: 8, color: 'gray'}}>US Dollar</label>
                            </div>
                        </div>
                    </div>
                )
        )
    };

    const currencyOptions = [
        { value: 0, label: l0(), isDisabled: true },
        { value: state1 === true ? 1 : 2, label: state1 === true ? l1() : l2() },
        { value: state2 === true ? 3 : 4, label: state2 === true ? l3() : l4() },
    ];
    const [curr, setCurr] = useState(1);
    const [currency, setCurrency] = useState({ value: 1, label: l1() });
    const [value, setValue] = useState('');

    const [leftLabel, setLeftLabel] = useState('IRR');
    const [result, setResult] = useState(0);

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const currencyChangeHandler = (currency) => {
        setCurrency(currency);
        setCurr(currency.value);
    };

    const onClick = e => {
        e.preventDefault();
        if (value.length < 12)
            setValue(value + e.target.name.toString());
    };

    const onClick2 = e => {
        if (value.length < 10)
            setValue(value + e.target.name.toString());
    };

    const backspace = () => {
        if (value.length > 0)
            setValue(value.slice(0,value.length-1));
    };

    const divideNum = (num) => {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    const getCurrencies = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/curr/CurrenciesList/', config);
            setDollarPrice(res.data[0].dollar);
            setEuroPrice(res.data[0].euro);
            setLastUpdate(res.data[0].updateDateTime.slice(0,10));
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
            setDollarPrice(1);
            setEuroPrice(1);
            setLastUpdate('-');
        }
    };

    const exchange = () => {
        if (curr === 1) {
            setResult(value*euroPrice)
        } else if (curr === 2) {
            setResult(value/euroPrice)
        } else if (curr === 3) {
            setResult(value*dollarPrice)
        } else if (curr === 4) {
            setResult(value/dollarPrice)
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const calculator = () => {
        return (
            <div className='shadowBox-in-converter' style={styles.calcStyle}>
                <div style={{
                    backgroundColor: '#9cb399',
                    marginBottom: 30,
                    padding: 5,
                }}>
                    <div style={{fontFamily: 'Orbitron', fontSize: 12}}>{leftLabel}</div>
                    <div className='text-right font-in-calc'>
                        <label style={{color: '#9cb399'}}>-</label>
                        {divideNum(value)}
                    </div>
                </div>
                <div>
                    <div className='btn-calc'>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='1'
                        >1
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='2'
                        >2
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='3'
                        >3
                        </button>
                    </div>
                    <div className='btn-calc'>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='4'
                        >4
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='5'
                        >5
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='6'
                        >6
                        </button>
                    </div>
                    <div className='btn-calc'>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='7'
                        >7
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='8'
                        >8
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='9'
                        >9
                        </button>
                    </div>
                    <div className='btn-calc'>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick}
                            name='0'
                        >0
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={onClick2}
                            name='000'
                        >000
                        </button>
                        <button
                            className='btn font-in-calc shadowBox-btn-in-converter'
                            style={styles.btnStyle}
                            onClick={backspace}
                        ><i className='fa fa-long-arrow-left'/></button>
                    </div>
                </div>
            </div>
        )
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (curr === 2 || curr === 4) {
            setLeftLabel('IRR')
        } else if (curr === 3) {
            setLeftLabel('US Dollar')
        } else {
            setLeftLabel('Euro')
        }
        if (!haveInfo && isAuthenticated) {
            getCurrencies();
            setHaveInfo(true);
        }
        exchange();
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, curr, value, haveInfo, isAuthenticated, width]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={2}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='converter'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: 14
                                }}
                            >
                                <label>Currency:</label>
                                <div className='select-in-converter' style={{width: 270, marginLeft: -8}}>
                                    <Select
                                        className='select-curr'
                                        options={currencyOptions}
                                        value={currency}
                                        onChange={currencyChangeHandler}
                                        required
                                        //menuIsOpen={true}
                                        isOptionDisabled={(option) => option.isDisabled}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>

                            <div className='grid-2-in-converter'>
                                <div>
                                    <div
                                        className='shadowBox-in-converter'
                                        style={styles.resultStyle}
                                    >
                                        <label style={{color: 'gray'}}>Approx.</label>
                                        <div
                                            className='all-center'
                                            style={{
                                                width: '75%',
                                                borderStyle: 'solid',
                                                borderColor: '#ccc',
                                                borderWidth: 0,
                                                borderBottomWidth: 2,
                                                color: '#f4ac50',
                                                fontSize: 24,
                                                fontWeight: 600
                                            }}>
                                            {dollarPrice === 1 || euroPrice === 1
                                                ? '-'
                                                : curr === 4
                                                    ? divideNum(result.toFixed(3)) + ' $'
                                                    : curr === 2
                                                        ? divideNum(result.toFixed(3)) + ' €'
                                                        : divideNum(result.toFixed(0)) + ' IRR'
                                            }
                                        </div>
                                        <div style={{
                                            width: '80%',
                                            textAlign: 'right',
                                            margin: 'auto',
                                            marginTop: 5,
                                            fontSize: 13
                                        }}>
                                            {curr === 3 || curr === 4
                                                ? `1.00 $ = ${divideNum(dollarPrice)} IRR`
                                                : `1.00 € = ${divideNum(euroPrice)} IRR`
                                            }
                                        </div>
                                    </div>
                                    <div
                                         style={{
                                             width: '80%',
                                             margin: 'auto',
                                             marginTop: 3,
                                             color: '#626465',
                                             textAlign: 'right',
                                             fontSize: 14,
                                         }}>
                                        <label style={{marginRight: 10}}>Last Update</label>
                                        {lastUpdate.slice(0, 4)}/
                                        {lastUpdate.slice(5, 7)}/
                                        {lastUpdate.slice(8, 10)}
                                    </div>
                                </div>
                                {calculator()}
                            </div>
                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 20,
                            paddingBottom: 100,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: 12,
                                    width: 'max-content',
                                    margin: 'auto'
                                }}
                            >
                                <label>Currency:</label>
                                <div className='select-in-converter' style={{width: 240, marginTop: 0, marginLeft: -7}}>
                                    <Select
                                        className='select-curr'
                                        options={currencyOptions}
                                        value={currency}
                                        onChange={currencyChangeHandler}
                                        required
                                        //menuIsOpen={true}
                                        isOptionDisabled={(option) => option.isDisabled}
                                    />
                                </div>
                            </div>

                            <div className='grid-2-in-converter'>
                                <div>
                                    <div style={styles.resultStyle_mobile}>
                                        <label style={{color: 'gray', fontSize: 10}}>Approx.</label>
                                        <div
                                            className='all-center'
                                            style={{
                                                width: '100%',
                                                borderStyle: 'solid',
                                                borderColor: '#ccc',
                                                borderWidth: 0,
                                                borderBottomWidth: 2,
                                                color: '#f4ac50',
                                                fontSize: 19,
                                                fontWeight: 600,
                                            }}>
                                            {dollarPrice === 1 || euroPrice === 1
                                                ? '-'
                                                : curr === 4
                                                    ? divideNum(result.toFixed(3)) + ' $'
                                                    : curr === 2
                                                        ? divideNum(result.toFixed(3)) + ' €'
                                                        : divideNum(result.toFixed(0)) + ' IRR'
                                            }
                                        </div>
                                        <div style={{
                                            width: '100%',
                                            textAlign: 'right',
                                            margin: 'auto',
                                            marginTop: 5,
                                            fontSize: 10,
                                        }}>
                                            {curr === 3 || curr === 4
                                                ? `1.00 $ = ${divideNum(dollarPrice)} IRR`
                                                : `1.00 € = ${divideNum(euroPrice)} IRR`
                                            }
                                        </div>
                                    </div>
                                </div>
                                {calculator()}
                            </div>
                        </div>
                        <BottomMenu/>
                    </>
                )
            }
        </Fragment>
        );
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        backgroundColor: 'white',
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        paddingTop: 25,
        paddingBottom: 50,
        paddingLeft: 35,
        paddingRight: 35,
        borderRadius: 10,
    },
    colStyleInSelect: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: '45%',
        paddingLeft: 10,
    },
    countryImgStyle: {
        width: 30,
        height: 30,
        marginRight: 5,
        borderRadius: '50%'
    },
    countryImg2Style: {
        width: 30,
        height: 30,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: '50%'
    },
    changeBtnStyle: {
        backgroundColor: 'white',
        width: '10%',
        margin: 0,
        padding: 0,
        borderWidth: 0,
    },
    changeBtn2Style: {
        backgroundColor: 'white',
        width: '10%',
        margin: 0,
        marginLeft: 5,
        padding: 0,
        borderWidth: 0,
    },
    resultStyle: {
        backgroundColor: 'white',
        width: '80%',
        margin: 'auto',
        marginTop: 45,
        padding: 20,
        paddingLeft: 40,
        paddingRight: 40,
    },
    calcStyle: {
        backgroundColor: '#d3d3d3',
        width: 260,
        height: 312,
        margin: 'auto',
        marginTop: 5,
        padding: 12,
        borderRadius: 5,
    },
    btnStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        margin: 5,
        marginLeft: 0,
        marginRight: 0,
        padding: 4,
        borderRadius: 5,
    },
    countryImgStyle_mobile: {
        width: 25,
        height: 25,
        marginRight: 5,
        borderRadius: '50%'
    },
    countryImg2Style_mobile: {
        width: 25,
        height: 25,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: '50%'
    },
    resultStyle_mobile: {
        backgroundColor: '#f1f1f1',
        width: '60%',
        margin: 'auto',
        marginTop: 20,
    },
};

export default Converter;
