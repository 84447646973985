import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import axios from 'axios';
import uuid from 'react-uuid'
import PropTypes from 'prop-types';
import AuthContext from '../../../context/auth/authContext';
import ToggleContext from '../../../context/toggleOfSidebar/toggleContext';
import DialogBox from '../DialogBox';
import SpinnerModal from '../SpinnerModal';
import '../../../Styles/Steps.css';
import '../../../Styles/Form.css';
import '../../../Styles/FloatInputs.scss';
import '../../../Styles/NewStyles.css';
import '../../../App.css';

const Form = ({ type_num, width }) => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, id, first_name, last_name, username } = authContext; //+ phone , currency

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [backStyle, setBackStyle] = useState({
        backgroundColor: '#fbfeff',
        width: width > 1000 ? '80%' : '92%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        paddingTop: 20,
        borderRadius: 10,
    });

    const [contentStyle, setContentStyle] = useState({
        backgroundColor: 'white',
        width: width > 1000 ? '70%' : '85%',
        height: 'max-content',
        margin: 'auto',
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 15,
    });

    const [load, setLoad] = useState(null);

    const [form, setForm] = useState({
        firstName: first_name,
        lastName: last_name,
        email: username,
        phone: authContext.phone,
        genderId: '1',
        birthPlaceCountryId: null,
        nationalityId: null,
        fatherName: '',
        birthDate: '',
        identificationNumber: '',
        identificationIssueDate: '',
        identificationExpirationDate: '',
        identificationPhoto: '',
        amount: null,
        currency: authContext.currency ? authContext.currency.toString() : '1',
        deliveryCity: '1',
        deliveryAddr: '',
        arrivalDate: '',
        description: '',
    });

    const {firstName, lastName, email, phone, genderId,
        fatherName, birthDate, birthPlaceCountryId, nationalityId,
        identificationNumber, identificationIssueDate, identificationExpirationDate, identificationPhoto,
        amount, currency, deliveryCity, deliveryAddr, arrivalDate, description} = form;

    const [spareForm, setSpareForm] = useState({
        birthCountry: null,
        nationality: null,
        birth: null,
        issueDate: null,
        expirationDate: null,
        arrivalD: null,
    });

    const {birthCountry, nationality, birth, issueDate, expirationDate, arrivalD} = spareForm;

    const countries = [
        {
            title: 'Afghanistan',
            persianName: 'افغانستان',
            fidaId: 1,
            arabian: false
        },
        {
            title: 'Albania',
            persianName: 'آلبانی',
            fidaId: 2,
            arabian: false
        },
        {
            title: 'Algeria',
            persianName: 'الجزایر',
            fidaId: 3,
            arabian: true
        },
        {
            title: 'American Samoa',
            persianName: 'ساموای آمریکا',
            fidaId: 4,
            arabian: false
        },
        {
            title: 'Andorra',
            persianName: 'آندورا',
            fidaId: 5,
            arabian: false
        },
        {
            title: 'Angola',
            persianName: 'آنگولا',
            fidaId: 6,
            arabian: false
        },
        {
            title: 'Anguilla',
            persianName: 'آنگوئیلا',
            fidaId: 7,
            arabian: false
        },
        {
            title: 'Antarctica',
            persianName: 'آنتارکتیکا',
            fidaId: 8,
            arabian: false
        },
        {
            title: 'Antigua and Barbuda',
            persianName: 'آنتیگوا و باربودا',
            fidaId: 9,
            arabian: false
        },
        {
            title: 'Argentina',
            persianName: 'آرژانتین',
            fidaId: 10,
            arabian: false
        },
        {
            title: 'Armenia',
            persianName: 'ارمنستان',
            fidaId: 11,
            arabian: false
        },
        {
            title: 'Aruba',
            persianName: 'آروبا',
            fidaId: 12,
            arabian: false
        },
        {
            title: 'Australia',
            persianName: 'استرالیا',
            fidaId: 13,
            arabian: false
        },
        {
            title: 'Austria',
            persianName: 'اتریش',
            fidaId: 14,
            arabian: false
        },
        {
            title: 'Azerbaijan',
            persianName: 'آذربایجان',
            fidaId: 15,
            arabian: false
        },
        {
            title: 'Bahamas',
            persianName: 'باهاما',
            fidaId: 16,
            arabian: false
        },
        {
            title: 'Bahrain',
            persianName: 'بحرین',
            fidaId: 17,
            arabian: true
        },
        {
            title: 'Bangladesh',
            persianName: 'بنگلادش',
            fidaId: 18,
            arabian: false
        },
        {
            title: 'Barbados',
            persianName: 'باربادوس',
            fidaId: 19,
            arabian: false
        },
        {
            title: 'Belarus',
            persianName: 'بلاروس',
            fidaId: 20,
            arabian: false
        },
        {
            title: 'Belgium',
            persianName: 'بلژیک',
            fidaId: 21,
            arabian: false
        },
        {
            title: 'Belize',
            persianName: 'بلیز',
            fidaId: 22,
            arabian: false
        },
        {
            title: 'Benin',
            persianName: 'بنین ',
            fidaId: 23,
            arabian: false
        },
        {
            title: 'Bermuda',
            persianName: 'برمودا',
            fidaId: 24,
            arabian: false
        },
        {
            title: 'Bhutan',
            persianName: 'بوتان',
            fidaId: 25,
            arabian: false
        },
        {
            title: 'Bolivia',
            persianName: 'بولیوی ',
            fidaId: 26,
            arabian: false
        },
        {
            title: 'Bosnia and Herzegovina',
            persianName: 'بوسنی وهرزگوین',
            fidaId: 27,
            arabian: false
        },
        {
            title: 'Botswana',
            persianName: 'بوتسوانا',
            fidaId: 28,
            arabian: false
        },
        {
            title: 'Bouvet Island',
            persianName: 'جزیره بووت',
            fidaId: 29,
            arabian: false
        },
        {
            title: 'Brazil',
            persianName: 'برزیل',
            fidaId: 30,
            arabian: false
        },
        {
            title: 'British Indian Ocean Territory',
            persianName: 'سرزمین انگلیسی اقیانوس هند',
            fidaId: 31,
            arabian: false
        },
        {
            title: 'Brunei',
            persianName: 'برونئی',
            fidaId: 32,
            arabian: false
        },
        {
            title: 'Bulgaria',
            persianName: 'بلغارستان',
            fidaId: 33,
            arabian: false
        },
        {
            title: 'Burkina Faso',
            persianName: 'بورکینافاسو',
            fidaId: 34,
            arabian: false
        },
        {
            title: 'Burundi',
            persianName: 'بوروندی ',
            fidaId: 35,
            arabian: false
        },
        {
            title: 'Ivory Coast',
            persianName: 'ساحل عاج',
            fidaId: 36,
            arabian: false
        },
        {
            title: 'Cambodia',
            persianName: 'کامبوج',
            fidaId: 37,
            arabian: false
        },
        {
            title: 'Cameroon',
            persianName: 'کامرون',
            fidaId: 38,
            arabian: false
        },
        {
            title: 'Canada',
            persianName: 'کانادا',
            fidaId: 39,
            arabian: false
        },
        {
            title: 'Cape Verde',
            persianName: 'کیپ ورد',
            fidaId: 40,
            arabian: false
        },
        {
            title: 'Cayman Islands',
            persianName: 'جزایر کیمن',
            fidaId: 41,
            arabian: false
        },
        {
            title: 'Central African Republic',
            persianName: 'آفریقای مرکزی',
            fidaId: 42,
            arabian: false
        },
        {
            title: 'Chad',
            persianName: 'چاد',
            fidaId: 43,
            arabian: false
        },
        {
            title: 'Chile',
            persianName: 'شیلی ',
            fidaId: 45,
            arabian: false
        },
        {
            title: 'China',
            persianName: 'چین',
            fidaId: 46,
            arabian: false
        },
        {
            title: 'Christmas Island',
            persianName: 'جزیره کریسمس (‌استرالیا)',
            fidaId: 47,
            arabian: false
        },
        {
            title: 'Cocos (Keeling) Islands',
            persianName: 'جزایر (‌کلینیگ) کوکوس',
            fidaId: 48,
            arabian: false
        },
        {
            title: 'Colombia',
            persianName: 'کلمبیا',
            fidaId: 49,
            arabian: false
        },
        {
            title: 'Comoros',
            persianName: 'کومورو(قمر)',
            fidaId: 50,
            arabian: true
        },
        {
            title: 'Congo',
            persianName: 'کنگو',
            fidaId: 51,
            arabian: false
        },
        {
            title: 'congo brazzaville',
            persianName: 'برازاویل کنگو',
            fidaId: 52,
            arabian: false
        },
        {
            title: 'Cook Islands',
            persianName: 'جزایر کوک',
            fidaId: 53,
            arabian: false
        },
        {
            title: 'Costa Rica',
            persianName: 'کاستاریکا',
            fidaId: 55,
            arabian: false
        },
        {
            title: 'Croatia',
            persianName: 'کروواسی',
            fidaId: 56,
            arabian: false
        },
        {
            title: 'Cuba',
            persianName: 'کوبا',
            fidaId: 57,
            arabian: false
        },
        {
            title: 'Cyprus',
            persianName: 'قبرس',
            fidaId: 58,
            arabian: false
        },
        {
            title: 'Czech Republic',
            persianName: 'چک',
            fidaId: 59,
            arabian: false
        },
        {
            title: 'Denmark',
            persianName: 'دانمارک',
            fidaId: 60,
            arabian: false
        },
        {
            title: 'Djibouti',
            persianName: 'جیبوتی',
            fidaId: 61,
            arabian: true
        },
        {
            title: 'Dominica',
            persianName: 'دومینیکا',
            fidaId: 62,
            arabian: false
        },
        {
            title: 'Dominican Republic',
            persianName: 'دومینیکن',
            fidaId: 63,
            arabian: false
        },
        {
            title: 'East Timor',
            persianName: 'تیمور شرقی',
            fidaId: 64,
            arabian: false
        },
        {
            title: 'Ecuador',
            persianName: 'اکوادور',
            fidaId: 65,
            arabian: false
        },
        {
            title: 'Egypt',
            persianName: 'مصر',
            fidaId: 66,
            arabian: true
        },
        {
            title: 'El Salvador',
            persianName: 'السالوادور',
            fidaId: 67,
            arabian: false
        },
        {
            title: 'Equatorial Guinea',
            persianName: 'گینه استوائی',
            fidaId: 68,
            arabian: false
        },
        {
            title: 'Eritrea',
            persianName: 'اریتره',
            fidaId: 69,
            arabian: false
        },
        {
            title: 'Estonia',
            persianName: 'استونی',
            fidaId: 70,
            arabian: false
        },
        {
            title: 'Ethiopia',
            persianName: 'اتیوپی (حبشه)',
            fidaId: 71,
            arabian: false
        },
        {
            title: 'Falkland Islands (Islas Malvinas)',
            persianName: 'جزایر فالکند - مالویناس',
            fidaId: 72,
            arabian: false
        },
        {
            title: 'Faroe Islands',
            persianName: 'جزایر فائرو',
            fidaId: 73,
            arabian: false
        },
        {
            title: 'Fiji Islands',
            persianName: 'فیجی',
            fidaId: 74,
            arabian: false
        },
        {
            title: 'Finland',
            persianName: 'فنلاند',
            fidaId: 75,
            arabian: false
        },
        {
            title: 'France',
            persianName: 'فرانسه',
            fidaId: 76,
            arabian: false
        },
        {
            title: 'French Guiana',
            persianName: 'سرزمین گوئیانا فرانسه',
            fidaId: 78,
            arabian: false
        },
        {
            title: 'French Polynesia',
            persianName: 'پلی‌نزی فرانسه تاهیتی',
            fidaId: 79,
            arabian: false
        },
        {
            title: 'French Southern and Antarctic Lands',
            persianName: 'سرزمین‌های قطب جنوب و جنوبی فرانسه',
            fidaId: 80,
            arabian: false
        },
        {
            title: 'Gabon',
            persianName: 'گابون',
            fidaId: 81,
            arabian: false
        },
        {
            title: 'Gambia',
            persianName: 'گامبیا',
            fidaId: 82,
            arabian: false
        },
        {
            title: 'Georgia',
            persianName: 'گرجستان',
            fidaId: 83,
            arabian: false
        },
        {
            title: 'Germany',
            persianName: 'آلمان',
            fidaId: 84,
            arabian: false
        },
        {
            title: 'Ghana',
            persianName: 'غنا',
            fidaId: 85,
            arabian: false
        },
        {
            title: 'Gibraltar',
            persianName: 'جبل الطارق',
            fidaId: 86,
            arabian: false
        },
        {
            title: 'Greece',
            persianName: 'یونان',
            fidaId: 87,
            arabian: false
        },
        {
            title: 'Greenland',
            persianName: 'گرینلند',
            fidaId: 88,
            arabian: false
        },
        {
            title: 'Grenada',
            persianName: 'گرنادا',
            fidaId: 89,
            arabian: false
        },
        {
            title: 'Guadeloupe',
            persianName: 'گوادلوپ',
            fidaId: 90,
            arabian: false
        },
        {
            title: 'Guam',
            persianName: 'گوآم',
            fidaId: 91,
            arabian: false
        },
        {
            title: 'Guatemala',
            persianName: 'گواتمالا',
            fidaId: 92,
            arabian: false
        },
        {
            title: 'Guernsey',
            persianName: 'گرنزی',
            fidaId: 93,
            arabian: false
        },
        {
            title: 'Guinea',
            persianName: 'گینه',
            fidaId: 94,
            arabian: false
        },
        {
            title: 'Guinea-Bissau',
            persianName: 'گینه بیسائو',
            fidaId: 95,
            arabian: false
        },
        {
            title: 'Guyana',
            persianName: 'گویان',
            fidaId: 96,
            arabian: false
        },
        {
            title: 'Haiti',
            persianName: 'هائیتی',
            fidaId: 97,
            arabian: false
        },
        {
            title: 'Heard Island and McDonald Islands',
            persianName: 'جزیره هرد و جزایر مک‌دونالد',
            fidaId: 98,
            arabian: false
        },
        {
            title: 'Honduras',
            persianName: 'هندوراس',
            fidaId: 99,
            arabian: false
        },
        {
            title: 'Hong Kong SAR',
            persianName: 'هنگ کنگ',
            fidaId: 100,
            arabian: false
        },
        {
            title: 'Hungary',
            persianName: 'مجارستان',
            fidaId: 101,
            arabian: false
        },
        {
            title: 'Iceland',
            persianName: 'ایسلند',
            fidaId: 102,
            arabian: false
        },
        {
            title: 'India',
            persianName: 'هند',
            fidaId: 103,
            arabian: false
        },
        {
            title: 'Indonesia',
            persianName: 'اندونزی',
            fidaId: 104,
            arabian: false
        },
        {
            title: 'Iran',
            persianName: 'ایران (جمهوری اسلامی)',
            fidaId: 105,
            arabian: false
        },
        {
            title: 'Iraq',
            persianName: 'عراق',
            fidaId: 106,
            arabian: true
        },
        {
            title: 'Ireland',
            persianName: 'ایرلند',
            fidaId: 107,
            arabian: false
        },
        {
            title: 'Italy',
            persianName: 'ایتالیا',
            fidaId: 109,
            arabian: false
        },
        {
            title: 'Jamaica',
            persianName: 'جامائیکا',
            fidaId: 110,
            arabian: false
        },
        {
            title: 'Japan',
            persianName: 'ژاپن ',
            fidaId: 111,
            arabian: false
        },
        {
            title: 'Jersey',
            persianName: 'جرزی',
            fidaId: 112,
            arabian: false
        },
        {
            title: 'Jordan',
            persianName: 'اردن',
            fidaId: 113,
            arabian: true
        },
        {
            title: 'Kazakhstan',
            persianName: 'قزاقستان',
            fidaId: 114,
            arabian: false
        },
        {
            title: 'Kenya',
            persianName: 'کنیا',
            fidaId: 115,
            arabian: false
        },
        {
            title: 'Kiribati',
            persianName: 'کیریباتی',
            fidaId: 116,
            arabian: false
        },
        {
            title: 'Korea',
            persianName: 'کره جنوبی',
            fidaId: 117,
            arabian: false
        },
        {
            title: 'Kuwait',
            persianName: 'کویت',
            fidaId: 118,
            arabian: true
        },
        {
            title: 'Kyrgyzstan',
            persianName: 'قرقیزستان',
            fidaId: 119,
            arabian: false
        },
        {
            title: 'Laos',
            persianName: 'لائوس',
            fidaId: 120,
            arabian: false
        },
        {
            title: 'Latvia',
            persianName: 'لاتویا',
            fidaId: 121,
            arabian: false
        },
        {
            title: 'Lebanon',
            persianName: 'لبنان',
            fidaId: 122,
            arabian: true
        },
        {
            title: 'Lesotho',
            persianName: 'لسوتو',
            fidaId: 123,
            arabian: false
        },
        {
            title: 'Liberia',
            persianName: 'لیبریا',
            fidaId: 124,
            arabian: false
        },
        {
            title: 'Libya',
            persianName: 'لیبی',
            fidaId: 125,
            arabian: true
        },
        {
            title: 'Liechtenstein',
            persianName: 'لیختن اشتاین',
            fidaId: 126,
            arabian: false
        },
        {
            title: 'Lithuania',
            persianName: 'لیتوانی',
            fidaId: 127,
            arabian: false
        },
        {
            title: 'Luxembourg',
            persianName: 'لوکزامبورگ',
            fidaId: 128,
            arabian: false
        },
        {
            title: 'Macau SAR',
            persianName: 'ماکائو',
            fidaId: 129,
            arabian: false
        },
        {
            title: 'Macedonia',
            persianName: 'مقدونیه',
            fidaId: 130,
            arabian: false
        },
        {
            title: 'Madagascar',
            persianName: 'ماداگاسکار',
            fidaId: 131,
            arabian: false
        },
        {
            title: 'Malawi',
            persianName: 'مالاوی',
            fidaId: 132,
            arabian: false
        },
        {
            title: 'Malaysia',
            persianName: 'مالزی',
            fidaId: 133,
            arabian: false
        },
        {
            title: 'Maldives',
            persianName: 'مالدیو',
            fidaId: 134,
            arabian: false
        },
        {
            title: 'Mali',
            persianName: 'مالی',
            fidaId: 135,
            arabian: false
        },
        {
            title: 'Malta',
            persianName: 'مالت',
            fidaId: 136,
            arabian: false
        },
        {
            title: 'Isle of Man',
            persianName: 'جزیره من',
            fidaId: 137,
            arabian: false
        },
        {
            title: 'Marshall Islands',
            persianName: 'جزایر مارشال',
            fidaId: 138,
            arabian: false
        },
        {
            title: 'Martinique',
            persianName: 'مارتینیک',
            fidaId: 139,
            arabian: false
        },
        {
            title: 'Mauritania',
            persianName: 'موریتانی',
            fidaId: 140,
            arabian: true
        },
        {
            title: 'Mauritius',
            persianName: 'موریس',
            fidaId: 141,
            arabian: false
        },
        {
            title: 'Mayotte',
            persianName: 'مایوت',
            fidaId: 142,
            arabian: false
        },
        {
            title: 'Mexico',
            persianName: 'مکزیک',
            fidaId: 143,
            arabian: false
        },
        {
            title: 'Micronesia',
            persianName: 'میکرونزی',
            fidaId: 144,
            arabian: false
        },
        {
            title: 'Moldova',
            persianName: 'مالداوا',
            fidaId: 145,
            arabian: false
        },
        {
            title: 'Monaco',
            persianName: 'موناکو',
            fidaId: 146,
            arabian: false
        },
        {
            title: 'Mongolia',
            persianName: 'مغولستان',
            fidaId: 147,
            arabian: false
        },
        {
            title: 'Montserrat',
            persianName: 'مونتسرات',
            fidaId: 148,
            arabian: false
        },
        {
            title: 'Morocco',
            persianName: 'مغرب (مراکش)',
            fidaId: 149,
            arabian: true
        },
        {
            title: 'Mozambique',
            persianName: 'موزامبیک',
            fidaId: 150,
            arabian: false
        },
        {
            title: 'Myanmar',
            persianName: 'میانمار(برمه)',
            fidaId: 151,
            arabian: false
        },
        {
            title: 'Namibia',
            persianName: 'نامیبیا',
            fidaId: 152,
            arabian: false
        },
        {
            title: 'Nauru',
            persianName: 'نائورو',
            fidaId: 153,
            arabian: false
        },
        {
            title: 'Nepal',
            persianName: 'نپال',
            fidaId: 154,
            arabian: false
        },
        {
            title: 'Netherlands Antilles',
            persianName: 'جزایر انتیل هلند',
            fidaId: 155,
            arabian: false
        },
        {
            title: 'Netherlands',
            persianName: 'هلند',
            fidaId: 156,
            arabian: false
        },
        {
            title: 'New Caledonia',
            persianName: 'کالدونیای جدید',
            fidaId: 157,
            arabian: false
        },
        {
            title: 'New Zealand',
            persianName: 'نیوزلند(زلاند نو)',
            fidaId: 158,
            arabian: false
        },
        {
            title: 'Nicaragua',
            persianName: 'نیکاراگوا',
            fidaId: 159,
            arabian: false
        },
        {
            title: 'Niger',
            persianName: 'نیجر',
            fidaId: 160,
            arabian: false
        },
        {
            title: 'Nigeria',
            persianName: 'نیجریه',
            fidaId: 161,
            arabian: false
        },
        {
            title: 'Niue',
            persianName: 'نیووی',
            fidaId: 162,
            arabian: false
        },
        {
            title: 'Norfolk Island',
            persianName: 'جزیره نورفک',
            fidaId: 163,
            arabian: false
        },
        {
            title: 'North Korea',
            persianName: 'کره شمالی',
            fidaId: 164,
            arabian: false
        },
        {
            title: 'Northern Mariana Islands',
            persianName: 'جزایر ماریانای شمالی',
            fidaId: 165,
            arabian: false
        },
        {
            title: 'Norway',
            persianName: 'نروژ',
            fidaId: 166,
            arabian: false
        },
        {
            title: 'Oman',
            persianName: 'عمان',
            fidaId: 167,
            arabian: true
        },
        {
            title: 'Pakistan',
            persianName: 'پاکستان',
            fidaId: 168,
            arabian: false
        },
        {
            title: 'Palau',
            persianName: 'پالائو',
            fidaId: 169,
            arabian: false
        },
        {
            title: 'Panama',
            persianName: 'پاناما',
            fidaId: 170,
            arabian: false
        },
        {
            title: 'Papua New Guinea',
            persianName: 'پاپوا، گینه نو',
            fidaId: 171,
            arabian: false
        },
        {
            title: 'Paraguay',
            persianName: 'پاراگوئه ',
            fidaId: 172,
            arabian: false
        },
        {
            title: 'Peru',
            persianName: 'پرو',
            fidaId: 173,
            arabian: false
        },
        {
            title: 'Philippines',
            persianName: 'فیلیپین',
            fidaId: 174,
            arabian: false
        },
        {
            title: 'Pitcairn Islands',
            persianName: 'جزایر پیت‌کرن',
            fidaId: 175,
            arabian: false
        },
        {
            title: 'Poland',
            persianName: 'لهستان',
            fidaId: 176,
            arabian: false
        },
        {
            title: 'Portugal',
            persianName: 'پرتغال',
            fidaId: 177,
            arabian: false
        },
        {
            title: 'Puerto Rico',
            persianName: 'پورتوریکو',
            fidaId: 178,
            arabian: false
        },
        {
            title: 'Qatar',
            persianName: 'قطر',
            fidaId: 179,
            arabian: true
        },
        {
            title: 'Reunion',
            persianName: 'ریونیون',
            fidaId: 180,
            arabian: false
        },
        {
            title: 'Romania',
            persianName: 'رومانی',
            fidaId: 181,
            arabian: false
        },
        {
            title: 'Russia',
            persianName: 'روسیه(فدراتیو)',
            fidaId: 182,
            arabian: false
        },
        {
            title: 'Rwanda',
            persianName: 'رواندا',
            fidaId: 183,
            arabian: false
        },
        {
            title: 'Sao Tome and Principe',
            persianName: 'سائوتومه وپرنسیپ',
            fidaId: 184,
            arabian: false
        },
        {
            title: 'Samoa',
            persianName: 'ساموآ',
            fidaId: 185,
            arabian: false
        },
        {
            title: 'San Marino',
            persianName: 'سان مارینو',
            fidaId: 186,
            arabian: false
        },
        {
            title: 'Saudi Arabia',
            persianName: 'عربستان سعودی',
            fidaId: 187,
            arabian: true
        },
        {
            title: 'Senegal',
            persianName: 'سنگال',
            fidaId: 188,
            arabian: false
        },
        {
            title: 'Seychelles',
            persianName: 'سیشل',
            fidaId: 189,
            arabian: false
        },
        {
            title: 'Sierra Leone',
            persianName: 'سیرالئون ',
            fidaId: 190,
            arabian: false
        },
        {
            title: 'Singapore',
            persianName: 'سنگاپور',
            fidaId: 191,
            arabian: false
        },
        {
            title: 'Slovakia',
            persianName: 'اسلوواکی',
            fidaId: 192,
            arabian: false
        },
        {
            title: 'Slovenia',
            persianName: 'اسلوونی',
            fidaId: 193,
            arabian: false
        },
        {
            title: 'Solomon Islands',
            persianName: 'جزایرسلیمان',
            fidaId: 194,
            arabian: false
        },
        {
            title: 'Somalia',
            persianName: 'سومالی',
            fidaId: 195,
            arabian: true
        },
        {
            title: 'South Africa',
            persianName: 'آفریقای جنوبی',
            fidaId: 196,
            arabian: false
        },
        {
            title: 'South Georgia and the South Sandwich Islands',
            persianName: 'جزایر جورجیای جنوبی و ساندویچ جنوبی',
            fidaId: 197,
            arabian: false
        },
        {
            title: 'Spain',
            persianName: 'اسپانیا',
            fidaId: 198,
            arabian: false
        },
        {
            title: 'Sri Lanka',
            persianName: 'سریلانکا',
            fidaId: 199,
            arabian: false
        },
        {
            title: 'Saint Helena',
            persianName: 'سنت هلن',
            fidaId: 200,
            arabian: false
        },
        {
            title: 'Saint Kitts and Nevis',
            persianName: 'سنت کیتس و نویس ',
            fidaId: 201,
            arabian: false
        },
        {
            title: 'Saint Lucia',
            persianName: 'سنت لوسیا',
            fidaId: 202,
            arabian: false
        },
        {
            title: 'Saint Pierre and Miquelon',
            persianName: 'سنت پیئرو میگوئلون',
            fidaId: 203,
            arabian: false
        },
        {
            title: 'Saint Vincent and the Grenadines',
            persianName: 'سنت وین سنت وگرنادین ',
            fidaId: 204,
            arabian: false
        },
        {
            title: 'Sudan',
            persianName: 'سودان',
            fidaId: 205,
            arabian: true
        },
        {
            title: 'Suriname',
            persianName: 'سورینام',
            fidaId: 206,
            arabian: false
        },
        {
            title: 'Svalbard and Jan Mayen',
            persianName: 'سوالبارد و یان ماین',
            fidaId: 207,
            arabian: false
        },
        {
            title: 'Swaziland',
            persianName: 'سوازیلند',
            fidaId: 208,
            arabian: false
        },
        {
            title: 'Sweden',
            persianName: 'سوئد',
            fidaId: 209,
            arabian: false
        },
        {
            title: 'Switzerland',
            persianName: 'سوئیس',
            fidaId: 210,
            arabian: false
        },
        {
            title: 'Syria',
            persianName: 'سوریه',
            fidaId: 211,
            arabian: true
        },
        {
            title: 'Taiwan',
            persianName: 'تایوان',
            fidaId: 212,
            arabian: false
        },
        {
            title: 'Tajikistan',
            persianName: 'تاجیکستان',
            fidaId: 213,
            arabian: false
        },
        {
            title: 'Tanzania',
            persianName: 'تانزانیا',
            fidaId: 214,
            arabian: false
        },
        {
            title: 'Thailand',
            persianName: 'تایلند',
            fidaId: 215,
            arabian: false
        },
        {
            title: 'Togo',
            persianName: 'توگو',
            fidaId: 216,
            arabian: false
        },
        {
            title: 'Tokelau',
            persianName: 'توکلائو',
            fidaId: 217,
            arabian: false
        },
        {
            title: 'Tonga',
            persianName: 'تونگا',
            fidaId: 218,
            arabian: false
        },
        {
            title: 'Trinidad and Tobago',
            persianName: 'توباگو',
            fidaId: 219,
            arabian: false
        },
        {
            title: 'Tunisia',
            persianName: 'تونس',
            fidaId: 220,
            arabian: true
        },
        {
            title: 'Turkey',
            persianName: 'ترکیه',
            fidaId: 221,
            arabian: false
        },
        {
            title: 'Turkmenistan',
            persianName: 'ترکمنستان',
            fidaId: 222,
            arabian: false
        },
        {
            title: 'Turks and Caicos Islands',
            persianName: 'جزایر تورکس و کایکوس',
            fidaId: 223,
            arabian: false
        },
        {
            title: 'Tuvalu',
            persianName: 'تووالو',
            fidaId: 224,
            arabian: false
        },
        {
            title: 'U.S. Minor Outlying Islands',
            persianName: 'جزایر کوچک حاشیه ایالات متحده آمریکا',
            fidaId: 225,
            arabian: false
        },
        {
            title: 'Uganda',
            persianName: 'اوگاندا',
            fidaId: 226,
            arabian: false
        },
        {
            title: 'Ukraine',
            persianName: 'اوکراین',
            fidaId: 227,
            arabian: false
        },
        {
            title: 'United Arab Emirates',
            persianName: 'امارات عربی متحده',
            fidaId: 228,
            arabian: true
        },
        {
            title: 'United Kingdom',
            persianName: 'انگلستان(اسکاتلند، ویلز)',
            fidaId: 229,
            arabian: false
        },
        {
            title: 'United States',
            persianName: 'ایالات متحده آمریکا',
            fidaId: 230,
            arabian: false
        },
        {
            title: 'Uruguay',
            persianName: 'اروگوئه',
            fidaId: 231,
            arabian: false
        },
        {
            title: 'Uzbekistan',
            persianName: 'ازبکستان',
            fidaId: 232,
            arabian: false
        },
        {
            title: 'Vanuatu',
            persianName: 'وانواتو',
            fidaId: 233,
            arabian: false
        },
        {
            title: 'Vatican City',
            persianName: 'واتیکان',
            fidaId: 234,
            arabian: false
        },
        {
            title: 'Venezuela',
            persianName: 'ونزوئلا',
            fidaId: 235,
            arabian: false
        },
        {
            title: 'Viet Nam',
            persianName: 'ویتنام',
            fidaId: 236,
            arabian: false
        },
        {
            title: 'United States Virgin Islands',
            persianName: 'جزیره انگلیسی ویرجین',
            fidaId: 237,
            arabian: false
        },
        {
            title: 'British Virgin Islands',
            persianName: 'جزیره انگلیسی ویرجین',
            fidaId: 238,
            arabian: false
        },
        {
            title: 'Wallis and Futuna',
            persianName: 'والیس و فوتونا',
            fidaId: 239,
            arabian: false
        },
        {
            title: 'Yemen',
            persianName: 'یمن',
            fidaId: 240,
            arabian: true
        },
        {
            title: 'Yugoslavia',
            persianName: 'یوگسلاوی',
            fidaId: 241,
            arabian: false
        },
        {
            title: 'Zambia',
            persianName: 'زامبیا',
            fidaId: 242,
            arabian: false
        },
        {
            title: 'Zimbabwe',
            persianName: 'زیمبابوه',
            fidaId: 243,
            arabian: false
        },
        {
            title: 'Aland Islands',
            persianName: 'جزیره آلند',
            fidaId: 244,
            arabian: false
        },
        {
            title: 'Caribbean Netherlands',
            persianName: 'جزایر کارائیب هلند',
            fidaId: 245,
            arabian: false
        },
        {
            title: 'Curacao',
            persianName: 'کوراسائو',
            fidaId: 246,
            arabian: false
        },
        {
            title: 'Montenegro',
            persianName: 'مونته‌نگرو',
            fidaId: 247,
            arabian: false
        },
        {
            title: 'Serbia',
            persianName: 'صربستان',
            fidaId: 248,
            arabian: false
        },
        {
            title: 'Palestine',
            persianName: 'فلسطین',
            fidaId: 249,
            arabian: true
        },
        {
            title: 'Western Sahara',
            persianName: 'صحرای غربی',
            fidaId: 250,
            arabian: false
        },
        {
            title: 'Chechen',
            persianName: 'چچن اینگوش',
            fidaId: 253,
            arabian: false
        },
        {
            title: 'Kosovo',
            persianName: 'کوزوو',
            fidaId: 257,
            arabian: false
        },
        {
            title: 'Dagestan',
            persianName: 'داغستان',
            fidaId: 261,
            arabian: false
        }
    ];
    const [countryOptions, setCountryOptions] = useState([{
        value: null,
        label: '',
        arabian: null,
    }]);
    const [haveCountries, setHaveCountries] = useState(false);

    const [haveInfo, setHaveInfo] = useState(false);

    const [haveLabel1, setHaveLabel1] = useState(false);
    const [haveLabel2, setHaveLabel2] = useState(false);
    const [haveLabel3, setHaveLabel3] = useState(false);

    const [step, setStep] = useState(0);

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const [finalPrice, setFinalPrice] = useState('');

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const genderChangeHandler = (gender) => {
        setForm({ ...form, genderId: gender.target.value });
    };

    const birthCountryChangeHandler = (country) => {
        setSpareForm({ ...spareForm, birthCountry: country });
        setForm({ ...form, birthPlaceCountryId: country.value });
    };

    const nationalityChangeHandler = (country) => {
        setSpareForm({ ...spareForm, nationality: country });
        setForm({ ...form, nationalityId: country.value });
    };

    const birthDateChangeHandler = (date) => {
        if (date !== null) {
            setSpareForm({ ...spareForm, birth: date });
            setForm({ ...form, birthDate:
                    moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
        }
        else {
            setSpareForm({ ...spareForm, birth: null });
            setForm({ ...form, birthDate: ''})
        }
    };

    const issueDateChangeHandler = (date) => {
        if (date !== null) {
            setSpareForm({ ...spareForm, issueDate: date });
            setForm({ ...form, identificationIssueDate:
                    moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
        }
        else {
            setSpareForm({ ...spareForm, issueDate: null });
            setForm({ ...form, identificationIssueDate: ''})
        }
    };

    const expirationDateChangeHandler = (date) => {
        if (date !== null) {
            setSpareForm({ ...spareForm, expirationDate: date });
            setForm({ ...form, identificationExpirationDate:
                    moment(date, 'DD.MM.YYYY H:mm:ss').add(1,'day').toISOString()});
        }
        else {
            setSpareForm({ ...spareForm, expirationDate: null });
            setForm({ ...form, identificationExpirationDate: ''})
        }
    };

    const uploadIdentificationPhoto  = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setForm({ ...form, identificationPhoto: base64.split('base64,')[1] });
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const currencyChangeHandler = (curr) => {
        setForm({ ...form, currency: curr.target.value });
    };

    const cityChangeHandler = (city) => {
        setForm({ ...form, deliveryCity: city.target.value });
    };

    const arrivalDateChangeHandler = (date) => {
        if (date !== null) {
            setForm({ ...form, arrivalDate:
                    date.toString().split(' ')[2] + ' ' +
                    date.toString().split(' ')[1] + ' ' +
                    date.toString().split(' ')[3] });
            setSpareForm({ ...spareForm, arrivalD: date });
        }
        else {
            setSpareForm({ ...spareForm, arrivalD: null });
            setForm({ ...form, arrivalDate: ''})
        }
    };

    const onChangeStep = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
    };

    const onNext = (e) => {
        e.preventDefault();
        if (step === 0) {
            if (type_num !== 3) {
                if (arrivalDate && amount) {
                    onChangeStep(step + 1);
                }
            } else {
                if (arrivalDate) {
                    onChangeStep(step + 1);
                }
            }
        } else if (step === 1) {
            if (identificationIssueDate && identificationExpirationDate && identificationNumber && identificationPhoto) {
                onChangeStep(step + 1);
            }
        } else if (step === 2) {
            if (birthDate && birthPlaceCountryId && nationalityId && genderId) {
                onChangeStep(step + 1);
            }
        }
    };

    const onPrevious = (e) => {
        e.preventDefault();
        onChangeStep(step - 1);
    };

    const progressBar1 = () => {
        return (
            width > 500
                ? <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">1</span>
                            <div className="_1hzhf ">Delivery Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">2</span>
                            <div className="_1hzhf ">Passport Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">3</span>
                            <div className="_1hzhf ">Personal Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">4</span>
                            <div className="_1hzhf ">Complete Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{deliveryInfo()}</div>
                </div>
                : <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">1</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Delivery<br/>Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">2</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Passport<br/>Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">3</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Personal<br/>Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">4</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Complete<br/>Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{deliveryInfo_mobile()}</div>
                </div>
        )
    };

    const progressBar2 = () => {
        return (
            width > 500
                ? <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf ">Delivery Information</div>
                        </li>
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">2</span>
                            <div className="_1hzhf ">Passport Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">3</span>
                            <div className="_1hzhf ">Personal Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">4</span>
                            <div className="_1hzhf ">Complete Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{passportInfo()}</div>
                </div>
                : <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Delivery<br/>Information</div>
                        </li>
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">2</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Passport<br/>Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">3</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Personal<br/>Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">4</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Complete<br/>Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{passportInfo()}</div>
                </div>
        )
    };

    const progressBar3 = () => {
        return (
            width > 500
                ? <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf ">Delivery Information</div>
                        </li>
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf ">Passport Information</div>
                        </li>
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">3</span>
                            <div className="_1hzhf ">Personal Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">4</span>
                            <div className="_1hzhf ">Complete Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{personalInfo()}</div>
                </div>
                : <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Delivery<br/>Information</div>
                        </li>
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Passport<br/>Information</div>
                        </li>
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">3</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Personal<br/>Information</div>
                        </li>
                        <li className="_2Jtxm "><span className="_2kL0S">4</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Complete<br/>Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{personalInfo()}</div>
                </div>
        )
    };

    const progressBar4 = () => {
        return (
            width > 500
                ? <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf ">Delivery Information</div>
                        </li>
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf ">Passport Information</div>
                        </li>
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf ">Personal Information</div>
                        </li>
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">4</span>
                            <div className="_1hzhf ">Complete Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{profileInfo()}</div>
                </div>
                : <div className="_53Ji7 ">
                    <ul className="_1Lo2h ">
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Delivery<br/>Information</div>
                        </li>
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Passport<br/>Information</div>
                        </li>
                        <li className="_2Jtxm _2ZUAI ">
                        <span className="_2JvrO">
                            <svg width="1.5rem" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 3.5L4.5 7.5L12 1" stroke="white" stroke-width="1.5"></path>
                            </svg>
                        </span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Personal<br/>Information</div>
                        </li>
                        <li className="_2Jtxm _35Ago "><span className="_2kL0S">4</span>
                            <div className="_1hzhf " style={styles.mobileBarLabelStyle}>Complete<br/>Profile</div>
                        </li>
                    </ul>
                    <div className="_2_g61 ">{profileInfo()}</div>
                </div>
        )
    };

    const showAmount = () => {
        if (type_num !== 3) {
            return (
                width > 500
                    ? <div style={{fontSize: 14, color: '#555'}}>
                        <label>Amount to be deposited to your debit card:</label>
                        <div style={{display: 'flex', marginTop: 15, marginBottom: 20}}>
                            <div className='form__group field' style={{width: 150, margin: 0,}}>
                                <input
                                    className='form__field amount'
                                    type='number'
                                    id='amount'
                                    name='amount'
                                    value={amount}
                                    onChange={onChange}
                                    required
                                    placeholder='Amount'
                                />
                                <label htmlFor='amount' className='form__label'>Amount</label>
                            </div>
                            <div style={{marginTop: -3, marginLeft: 10, width: 280}}>
                                <RadioGroup
                                    name='currency'
                                    value={currency}
                                    onChange={currencyChangeHandler}
                                    row
                                    required
                                >
                                    <FormControlLabel value='1' control={<Radio />} label='Euro(€)'/>
                                    <FormControlLabel value='2' control={<Radio />} label='USD($)' style={{marginLeft: 10}}/>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    : <div style={{fontSize: 14, color: '#555'}}>
                        <label>Amount to be deposited to your debit card:</label>
                        <div style={{marginTop: 15, marginBottom: 20}}>
                            <div className='form__group field' style={{width: 150, margin: 0,}}>
                                <input
                                    className='form__field amount'
                                    type='number'
                                    id='amount'
                                    name='amount'
                                    value={amount}
                                    onChange={onChange}
                                    required
                                    placeholder='Amount'
                                />
                                <label htmlFor='amount' className='form__label'>Amount</label>
                            </div>
                            <div style={{marginTop: 5, marginLeft: 3, width: 280}}>
                                <RadioGroup
                                    name='currency'
                                    value={currency}
                                    onChange={currencyChangeHandler}
                                    row
                                    required
                                >
                                    <FormControlLabel value='1' control={<Radio />} label='Euro(€)'/>
                                    <FormControlLabel value='2' control={<Radio />} label='USD($)' style={{marginLeft: 10}}/>
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
            )
        }
    };

    const showFatherName = () => {
        if (nationality && nationality.arabian === true) {
            return (
                <div className='form__group field' style={{marginTop: width > 500 ? 20 : -5}}>
                    <input
                        className='form__field amount name'
                        type='text'
                        id='fatherName'
                        name='fatherName'
                        value={fatherName}
                        onChange={onChange}
                        maxLength='100'
                        required
                        placeholder='Father Name'
                    />
                    <label htmlFor='fatherName' className='form__label'>Father Name</label>
                </div>
            )
        } else {
            return <div />
        }
    };

    const deliveryInfo = () => {
        return (
            <div style={contentStyle} className='shadowBox-in-converter'>
                <label className='all-center' style={{fontSize: 18, color: '#777', marginBottom: 15}}>Delivery Information</label>
                <label style={{fontSize: 14, color: 'gray'}}>Please provide your desire delivery location and date:</label>
                <div style={styles.smallForm}>
                    <div id='arrivalD'>
                        <DatePicker
                            className='form__field-datepicker'
                            id='arrivalDate'
                            name='arrivalDate'
                            dateFormat='MMMM dd, yyyy'
                            selected={arrivalD}
                            onChange={arrivalDateChangeHandler}
                            required
                            placeholderText='November 17,2020'
                        />
                    </div>
                    <div className='form__group' style={{fontSize: 14, color: '#555'}}>
                        <label htmlFor='deliveryCity' >Card delivery city:</label>
                        <RadioGroup
                            name='deliveryCity'
                            value={deliveryCity}
                            onChange={cityChangeHandler}
                            row
                            required
                            style={{marginTop: 5, marginBottom: -5}}
                        >
                            <FormControlLabel value='1' control={<Radio />} label='Tehran'/>
                            <FormControlLabel value='2' control={<Radio />} label='Isfahan'  style={{marginLeft: 30}}/>
                            <FormControlLabel value='3' control={<Radio />} label='Shiraz'  style={{marginLeft: 30}}/>
                        </RadioGroup>
                    </div>
                    {showAmount()}
                    <div className='form__group field'>
                        <textarea
                            className='form__field addr'
                            id='deliveryAddr'
                            name='deliveryAddr'
                            value={deliveryAddr}
                            onChange={onChange}
                            placeholder='ex. IKA Airport, Spinas Hotel, Pasargad Hotel Apartment or any other hustle'
                        />
                        <label htmlFor='deliveryAddr' className='form__label'>Deliver Address and Description (optional)</label>
                    </div>
                    <div className="_3uApM " style={{marginTop: type_num === 3 ? 73 : 0}}>
                        <a className="_2pGos _3CDiP _2RWmX ">Back</a>
                        <a className="_2pGos _hsN1w " onClick={onNext}>Next</a>
                    </div>
                </div>
            </div>
        )
    };

    const deliveryInfo_mobile = () => {
        return (
            <div style={styles.contentStyle_mobile} className='shadowBox-in-converter'>
                <label className='all-center' style={{fontSize: 18, color: '#777', marginBottom: 15}}>Delivery Information</label>
                <label style={{fontSize: 14, color: 'gray'}}>Please provide your desire delivery location and date:</label>
                <div style={styles.smallForm}>
                    <div id='arrivalD'>
                        <DatePicker
                            className='form__field-datepicker'
                            id='arrivalDate'
                            name='arrivalDate'
                            dateFormat='MMMM dd, yyyy'
                            selected={arrivalD}
                            onChange={arrivalDateChangeHandler}
                            required
                            placeholderText='November 17,2020'
                        />
                    </div>
                    <div className='form__group' style={{fontSize: 14, color: '#555'}}>
                        <label htmlFor='deliveryCity' >Card delivery city:</label>
                        <RadioGroup
                            name='deliveryCity'
                            value={deliveryCity}
                            onChange={cityChangeHandler}
                            required
                            style={{marginTop: 5, marginBottom: -5, marginLeft: 8}}
                        >
                            <FormControlLabel value='1' control={<Radio />} label='Tehran'/>
                            <FormControlLabel value='2' control={<Radio />} label='Isfahan'/>
                            <FormControlLabel value='3' control={<Radio />} label='Shiraz'/>
                        </RadioGroup>
                    </div>
                    {showAmount()}
                    <div className='form__group field'>
                        <textarea
                            className='form__field addr'
                            id='deliveryAddr'
                            name='deliveryAddr'
                            value={deliveryAddr}
                            onChange={onChange}
                            placeholder='ex. IKA Airport, Spinas Hotel, Pasargad Hotel Apartment or any other hustle'
                        />
                        <label htmlFor='deliveryAddr' className='form__label'>Deliver Address and Description (optional)</label>
                    </div>
                    <div className="_3uApM " style={{marginTop: 25}} >
                        <a className="_2pGos _3CDiP _2RWmX ">Back</a>
                        <a className="_2pGos _hsN1w " onClick={onNext}>Next</a>
                    </div>
                </div>
            </div>
        )
    };

    const passportInfo = () => {
        return (
            <div
                style={width > 500 ? contentStyle : styles.contentStyle_mobile}
                className='shadowBox-in-converter'
            >
                <label className='all-center' style={{fontSize: 18, color: '#777', marginBottom: 15}}>Passport Information</label>
                <label style={{fontSize: 14, color: 'gray'}}>Please complete your passport information:</label>
                <div style={styles.smallForm}>
                    <div className='grid-2-in-form' style={{marginTop: -20}}>
                        <div id='issueD' className='form__group field' style={{marginBottom: -5}}>
                            <DatePicker
                                className='form__field-datepicker'
                                id='identificationIssueDate'
                                name='identificationIssueDate'
                                dateFormat='dd MMM yyyy'
                                selected={issueDate}
                                onChange={issueDateChangeHandler}
                                required
                                placeholderText='07 JUN 2017'
                            />
                        </div>
                        <div id='expirationD' className='form__group field'>
                            <DatePicker
                                className='form__field-datepicker'
                                id='identificationExpirationDate'
                                name='identificationExpirationDate'
                                dateFormat='dd MMM yyyy'
                                selected={expirationDate}
                                onChange={expirationDateChangeHandler}
                                required
                                placeholderText='07 JUN 2017'
                            />
                        </div>
                    </div>
                    <div className='grid-2-in-form' style={{marginTop: -10}}>
                        <div className='form__group field'>
                            <input
                                className='form__field amount passNo'
                                type='text'
                                id='identificationNumber'
                                name='identificationNumber'
                                value={identificationNumber}
                                onChange={onChange}
                                required
                                placeholder='Passport Number'
                            />
                            <label htmlFor='identificationNumber' className='form__label'>Passport Number</label>
                        </div>
                        <div/>
                    </div>
                    <div
                        //style={styles.uploadPhoto}
                        style={{
                            width: '100%',
                            height: 110,
                            marginTop: width > 500 ? 15 : 0,
                            padding: 10,
                            textAlign: 'center',
                            borderStyle: 'dashed',
                            borderColor: 'gray',
                            borderWidth: 1.5,
                        }}
                    >
                        <i className='fa fa-cloud-upload' style={{fontSize: 35}} />
                        <div>
                            <label htmlFor='identificationPhoto' style={{fontSize: 13}}>Browse your passport photo</label>
                            <br/>
                            <input
                                type='file'
                                name='identificationPhoto'
                                onChange={(e) => {
                                    uploadIdentificationPhoto(e);
                                }}
                                required
                                data-max-size='100000' //(Size: Less than 100 kb)
                                style={{width: 100, margin: 0, fontSize: 12}}
                            />
                        </div>
                    </div>
                    {width > 500
                        ? <div className="_3uApM " style={{marginTop: type_num === 3 ? 20 : 60}}>
                            <a className="_2pGos _3CDiP  " onClick={onPrevious}>Back</a>
                            <a className="_2pGos _hsN1w " onClick={onNext}>Next</a>
                        </div>
                        : <div className="_3uApM " style={{marginTop: 25}}>
                            <a className="_2pGos _3CDiP  " onClick={onPrevious}>Back</a>
                            <a className="_2pGos _hsN1w " onClick={onNext}>Next</a>
                        </div>
                    }
                </div>
            </div>
        )
    };

    const personalInfo = () => {
        return (
            <div
                style={width > 500 ? contentStyle : styles.contentStyle_mobile}
                className='shadowBox-in-converter'
            >
                <label className='all-center' style={{fontSize: 18, color: '#777', marginBottom: 15}}>Personal Information</label>
                <label style={{fontSize: 14, color: 'gray'}}>Please complete your personal information:</label>
                <div style={styles.smallForm}>
                    <div className='grid-2-in-form' style={{marginTop: -20}}>
                        <div id='birthD' className='form__group field' style={{marginBottom: -10}}>
                            <DatePicker
                                className='form__field-datepicker'
                                id='birthDate'
                                name='birthDate'
                                dateFormat='dd MMM yyyy'
                                selected={birth}
                                onChange={birthDateChangeHandler}
                                required
                                placeholderText='07 JUN 2017'
                            />
                        </div>
                        <div style={{width: '100%', maxWidth: 185, fontSize: 14}}>
                            <Select
                                className='form2'
                                options={countryOptions}
                                value={birthCountry}
                                onChange={birthCountryChangeHandler}
                                required
                                placeholder='Country of Birth'
                            />
                        </div>
                    </div>
                    <div className='grid-2-in-form' style={{marginTop: -10}}>
                        <div style={{width: '100%', maxWidth: 185, fontSize: 14}}>
                            <Select
                                className='form2'
                                options={countryOptions}
                                value={nationality}
                                onChange={nationalityChangeHandler}
                                required
                                placeholder='Nationality'
                            />
                        </div>
                        <div/>
                    </div>
                    <div style={{marginTop: width > 500 ? 10 : -5, fontSize: 14, color: '#555'}}>
                        <label>Choose your gender:</label>
                        <RadioGroup
                            name='genderId'
                            value={genderId}
                            onChange={genderChangeHandler}
                            required
                            style={{marginTop: 5, marginLeft: 5, width:'max-content'}}
                        >
                            <FormControlLabel value='1' control={<Radio />} label='Male'/>
                            <FormControlLabel value='2' control={<Radio />} label='Female' style={{marginTop: -5}}/>
                        </RadioGroup>
                    </div>
                    {width > 500
                        ? <div className="_3uApM " style={{marginTop: type_num === 3 ? 42 : 82}}>
                            <a className="_2pGos _3CDiP  " onClick={onPrevious}>Back</a>
                            <a className="_2pGos _hsN1w " onClick={onNext}>Next</a>
                        </div>
                        : <div className="_3uApM " style={{marginTop: 30}}>
                            <a className="_2pGos _3CDiP  " onClick={onPrevious}>Back</a>
                            <a className="_2pGos _hsN1w " onClick={onNext}>Next</a>
                        </div>
                    }
                </div>
            </div>
        )
    };

    const profileInfo = () => {
        return (
            <div
                style={width > 500 ? contentStyle : styles.contentStyle_mobile}
                className='shadowBox-in-converter'
            >
                <label className='all-center' style={{fontSize: 18, color: '#777', marginBottom: 15}}>Complete Profile</label>
                <label style={{fontSize: 14, color: 'gray'}}>Please complete your profile:</label>
                <div style={styles.smallForm}>
                    <div className='grid-2-in-form' style={{marginTop: -20}}>
                        <div className='form__group field' style={{marginBottom: -10}}>
                            <input
                                className='form__field amount name'
                                type='text'
                                id='firstName'
                                name='firstName'
                                value={firstName}
                                onChange={onChange}
                                maxLength='50'
                                required
                                placeholder='First Name'
                            />
                            <label htmlFor='firstName' className='form__label'>First Name</label>
                        </div>
                        <div className='form__group field'>
                            <input
                                className='form__field amount name'
                                type='text'
                                id='lastName'
                                name='lastName'
                                value={lastName}
                                onChange={onChange}
                                maxLength='50'
                                required
                                placeholder='last Name'
                            />
                            <label htmlFor='lastName' className='form__label'>Last Name</label>
                        </div>
                    </div>
                    <div className='form__group field' style={{marginTop: 15}}>
                        <input
                            className='form__field amount email'
                            type='email'
                            id='email'
                            name='email'
                            value={email}
                            onChange={onChange}
                            maxLength='150'
                            required
                            disabled
                            placeholder='Email'
                        />
                        <label htmlFor='email' className='form__label'>Email Address</label>
                    </div>
                    <div className='grid-2-in-form' style={{marginTop: -5}}>
                        <div className='form__group field'>
                            <input
                                className='form__field amount name'
                                type='text'
                                id='phone'
                                name='phone'
                                value={phone}
                                onChange={onChange}
                                maxLength='20'
                                placeholder='Phone Number (optional)'
                            />
                            <label htmlFor='phone' className='form__label'>Phone Number (optional)</label>
                        </div>
                        {showFatherName()}
                    </div>
                    <div style={{marginTop: -10, fontSize: 11, color: '#555', textAlign: 'justify'}}>
                        (We recommend to provide WhatsApp number for faster communications.)
                    </div>
                    {width > 500
                        ? <div className="_3uApM " style={{marginTop: type_num === 3 ? 62 : 102}}>
                            <a className="_2pGos _3CDiP  " onClick={onPrevious}>Back</a>
                            <input
                                type='submit'
                                value='Submit'
                                className="_2pGos _hsN1w "
                                style={{borderWidth: 0, margin: 0}}
                            />
                        </div>
                        : <div className="_3uApM " style={{marginTop: 30}}>
                            <a className="_2pGos _3CDiP  " onClick={onPrevious}>Back</a>
                            <input
                                type='submit'
                                value='Submit'
                                className="_2pGos _hsN1w "
                                style={{borderWidth: 0, margin: 0}}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    };

    const getPackInfo = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/packages/PackInfoList/', config);
            setFinalPrice(res.data[type_num-1].final_price.toString() + ' €');
            setLoad(true);
        } catch (e) {
            setLoad(true);
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const data = { ...form, ...{
                    user_ID: id,
                    //genderId: Number(genderId),
                    //currency: Number(currency),
                    //deliveryCity: Number(deliveryCity),
                    birthPlaceCountry: birthCountry.label,
                    nationality: nationality.label,
                    orderType: type_num,
                    price: finalPrice,
                    status: 1 }
            };
            const factorData = {
                factor_number : uuid(),
                family_package: false,
                user_ID: Number(id),
                email : username,
                firstName: firstName,
                lastName: lastName,
                orderType: type_num,
                price: finalPrice,
                status: false,
            };
            const data2 = { first_name: firstName, last_name: lastName };
            const data3 = { phone: phone };
            await axios.post('https://admin.intravelcard.com/api/req/CreateTourist/', data, config);
            await axios.post('https://admin.intravelcard.com/api/req/CreateFactor/', factorData, config);
            await axios.put(`https://admin.intravelcard.com/api/auth/updateName/${username}`, data2, config);
            await axios.put(`https://admin.intravelcard.com/api/auth/updateProfile/${username}`, data3, config);
            localStorage.setItem('first_name', firstName);
            localStorage.setItem('last_name', lastName);
            localStorage.setItem('phone', phone);
            setLoad(true);
            setShowDialogBox(true);
            setDialogBoxData('done');
        } catch (e) {
            setLoad(true);
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'relative',
                left: -78,
                backgroundColor: '#fbfeff',
                width: width > 1000 ? '80%' : '92%',
                margin: 'auto',
                marginTop: 25+135,
                marginBottom: 25,
                paddingTop: 20,
                borderRadius: 10,
            });
        } else {
            setTimeout(() => setBackStyle ({
                backgroundColor: '#fbfeff',
                width: width > 1000 ? '80%' : '92%',
                margin: 'auto',
                marginTop: 25+135,
                marginBottom: 25,
                paddingTop: 20,
                borderRadius: 10,
            }), 200);
        }
        setContentStyle({
            backgroundColor: 'white',
            width: width > 1000 ? '70%' : '85%',
            height: 'max-content',
            margin: 'auto',
            padding: 20,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 15,
        });
        if (!haveInfo && isAuthenticated) {
            getPackInfo();
            setHaveInfo(true)
        }
        if (!haveCountries) {
            countries.map( c => countryOptions.push({ value: c.fidaId, label: c.title, arabian: c.arabian }));
            setHaveCountries(true);
        }

        if (!haveLabel1 && step === 0) {
            const l = document.createElement('label');
            const d = document.createTextNode('Arrival Date');
            l.appendChild(d);
            l.setAttribute('class', 'form__label-datepicker');
            l.setAttribute('for', 'arrivalDate');
            document.getElementById('arrivalD').childNodes[0].childNodes[0].appendChild(l);
            setHaveLabel1(true);
        }
        if (!haveLabel2 && step === 1) {
            const l1 = document.createElement('label');
            const d1 = document.createTextNode('Date of Issue');
            l1.appendChild(d1);
            l1.setAttribute('class', 'form__label-datepicker');
            l1.setAttribute('for', 'identificationIssueDate');
            document.getElementById('issueD').childNodes[0].childNodes[0].appendChild(l1);
            const l2 = document.createElement('label');
            const d2 = document.createTextNode('Date of Expiry');
            l2.appendChild(d2);
            l2.setAttribute('class', 'form__label-datepicker');
            l2.setAttribute('for', 'identificationExpirationDate');
            document.getElementById('expirationD').childNodes[0].childNodes[0].appendChild(l2);
            setHaveLabel2(true);
        }
        if (!haveLabel3 && step === 2) {
            const l = document.createElement('label');
            const d = document.createTextNode('Date of Birth');
            l.appendChild(d);
            l.setAttribute('class', 'form__label-datepicker label-width-in-form');
            l.setAttribute('for', 'birthDate');
            document.getElementById('birthD').childNodes[0].childNodes[0].appendChild(l);
            setHaveLabel3(true);
        }
        if (step !== 0) {
            setHaveLabel1(false);
        }
        if (step !== 1) {
            setHaveLabel2(false);
        }
        if (step !== 2) {
            setHaveLabel3(false);
        }
    }, [toggle, haveInfo, haveCountries, haveLabel1, haveLabel2, haveLabel3, step, isAuthenticated, width]);

    return (
        <>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={1}/>
                        : null
                )
            }
            {width > 767
                ? <form style={backStyle} onSubmit={onSubmit}>
                    {step === 0
                        ? progressBar1()
                        : step === 1
                            ? progressBar2()
                            : step === 2
                                ? progressBar3()
                                : progressBar4()
                    }
                </form>
                : <form
                    style={{
                        backgroundColor: 'white',
                        //padding: 0,
                        paddingTop: width > 500 ? 15 : 25,
                        //paddingBottom: window.innerWidth > 500 ? 15 : 80,
                        paddingBottom: 80
                    }}
                    onSubmit={onSubmit}
                >
                    {step === 0
                        ? progressBar1()
                        : step === 1
                            ? progressBar2()
                            : step === 2
                                ? progressBar3()
                                : progressBar4()
                    }
                </form>
            }

        </>
    );
};

Form.propTypes = {
    type_num: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    //initialPrice: PropTypes.string,
    //CardPrice: PropTypes.string.isRequired,
    //SIMCardPrice: PropTypes.string.isRequired
};

const styles = {
    /*formStyle: {
        backgroundColor: '#fbfeff',
        width: window.innerWidth > 1000 ? '80%' : '92%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        paddingTop: 20,
        borderRadius: 10,
    },
    form2Style: {
        position: 'relative',
        left: -78,
        backgroundColor: '#fbfeff',
        width: window.innerWidth > 1000 ? '80%' : '92%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        paddingTop: 20,
        borderRadius: 10,
    },
    contentStyle: {
        backgroundColor: 'white',
        width: window.innerWidth > 1000 ? '70%' : '85%',
        height: 'max-content',
        margin: 'auto',
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 15,
    },*/
    contentStyle_mobile: {
        backgroundColor: 'white',
        width: '90%',
        height: 'max-content',
        margin: 'auto',
        padding: 20,
        borderRadius: 15,
    },
    smallForm: {
        marginTop: 20,
        marginBottom: 0,
        paddingLeft: 15,
        paddingRight: 15,
    },
    /*uploadPhoto: {
        width: '100%',
        height: 110,
        marginTop: window.innerWidth > 500 ? 15 : 0,
        padding: 10,
        textAlign: 'center',
        borderStyle: 'dashed',
        borderColor: 'gray',
        borderWidth: 1.5,
    },*/
    mobileBarLabelStyle: {
        top: -40,
    }
};

export default Form;
