import React, {useContext} from 'react';
import AlertContext from '../../context/alert/alertContext';

const Alerts = ({type}) => {
    const alertContext = useContext(AlertContext);
    const { alert } = alertContext;

    return (
        (alert !== null)
        ? (
            <div className='all-center' style={styles.errorStyle}>
                <label className='text-center'>{alert.msg}</label>
            </div>
        )
        : (
            (type === 1)
                ? (
                    <div className={'all-center'} style={styles.errorStyle}>
                        <label className='text-center'> </label>
                    </div>
                )
                : null
        )
    );
};

Alerts.defaultProps = {
    type: 1
};

const styles = {
    errorStyle: {
        position: 'relative',
        alignSelf: 'center',
        margin: 'auto',
        marginTop: 10,
        width: '80%',
        height: 30,
        borderRadius: 5,
        color: 'red',
        zIndex: 5
    }
};

export default Alerts;
