import React, {Fragment, useState, useEffect, useContext} from 'react';
import moment from 'moment';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import BottomMenu from "../layout/BottomMenu";
import Spinner from '../layout/Spinner';
import SpinnerModal from '../layout/SpinnerModal';
import DialogBox from '../layout/DialogBox';
import Pagination from "../layout/Pagination";
import '../../Styles/NewStyles.css';
import '../../App.css';

const Reports = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, username, currency } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(null);

    const [balance, setBalance] = useState(0);

    const [fromDate, setFromDate] = useState('');
    const [cardNum, setCardNum] = useState('');

    const [transactions, setTransactions] = useState([]);
    const [transactionsBySearch, setTransactionsBySearch] = useState([]);
    const [haveTrans, setHaveTrans] = useState(false);
    const [haveT, setHaveT] = useState(false);

    const [label, setLabel] = useState("You didn't make any transactions!");

    const [reverse, setReverse] = useState(false);
    const [sortIcon, setSortIcon] = useState('fa fa-angle-down');
    const [search, setSearch] = useState('');

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const [dollarPrice, setDollarPrice] = useState(1);
    const [euroPrice, setEuroPrice] = useState(1);

    const [pageOfItems, setPageOfItems] = useState([]);

    const onChange = (e) => {
        setSearch(e.target.value);
    };

    const onChangePage = (pageOfItems) => {
        setPageOfItems(pageOfItems);
    };

    const divideNum = (num) => {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    const sort = (e) => {
        e.preventDefault();
        if (sortIcon === 'fa fa-angle-down') {
            setSortIcon('fa fa-angle-up')
        } else {
            setSortIcon('fa fa-angle-down')
        }
        setTransactionsBySearch(transactionsBySearch.reverse());
        setReverse(!reverse);
    };

    const onSearch = () => {
        if (reverse === true) {
            setTransactionsBySearch(transactions.filter(t => t.transDate.slice(0, 10) === search).reverse());
        } else {
            setTransactionsBySearch(transactions.filter(t => t.transDate.slice(0, 10) === search));
        }
    };

    const clear = () => {
        setSearch('');
    };

    const getCurrencies = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/curr/CurrenciesList/', config);
            setDollarPrice(res.data[0].dollar);
            setEuroPrice(res.data[0].euro);
            setShowDialogBox(false);
            //setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
            setDollarPrice(1);
            setEuroPrice(1);
        }
    };

    const getInfo = async () => {
        setLoad(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        try {
            //django server - name & pan
            const res1 = await axios.get(`https://admin.intravelcard.com/api/req/TouristsList-Email/${username}`, config1);

            if (res1.data.length !== 0 && res1.data[res1.data.length - 1].status !== 1) {
                const pan = res1.data[res1.data.length - 1].pan;
                setCardNum(pan.toString());
                setFromDate(res1.data[res1.data.length - 1].createDateTime.slice(0,8) + '01' +
                    res1.data[res1.data.length - 1].createDateTime.slice(10,23) + 'Z');

                //bank token
                const res2 = await axios.get('https://admin.intravelcard.com/getBankInfo/getToken/', config1);
                const bankToken = res2.data; //access_token

                //bank service
                //balance & transactions
                const params4 = `Pan=${Number(pan)}`;
                const req4 = { bankToken: `Bearer ${bankToken}` , params: params4 };
                const res4 = await axios.post('https://admin.intravelcard.com/getBankInfo/getBalance/', req4, config1);
                setBalance(res4.data.data.balance);

                const fromdate =
                    res1.data[res1.data.length - 1].createDateTime.slice(0,8) + '01' +
                    res1.data[res1.data.length - 1].createDateTime.slice(10,23) + 'Z';
                const todate = moment()._d.toISOString();
                const Count = 150;
                const params5 = `pan=${Number(pan)}&fromdate=${fromdate}&todate=${todate}&Count=${Count}&paymentId=&TransactionType=`;
                const req5 = { bankToken: `Bearer ${bankToken}` , params: params5};
                const res5 = await axios.post('https://admin.intravelcard.com/getBankInfo/getTransactions/', req5, config1);

                if (res5.data.data === null) {
                    setTransactions([]);
                    setLabel("You didn't make any transactions!");
                } else {
                    setTransactions(res5.data.data);
                    setHaveT(!haveT);
                }
            } else {
                setCardNum('');
                setBalance(0);
                setTransactions([]);
                setLabel("You didn't make any transactions!");
            }
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setCardNum('');
            setBalance(0);
            setTransactions([]);
            setLabel("You didn't make any transactions!");
            setLoad(true);
        }
    };

    const getTransactions = async (e) => {
        e.preventDefault();
        setLoad(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            if (cardNum !== '') {
                //bank token
                const res2 = await axios.get('https://admin.intravelcard.com/getBankInfo/getToken/', config1);
                const bankToken = res2.data; //access_token

                const todate = moment()._d.toISOString();
                const Count = 150;
                const params5 = `pan=${Number(cardNum)}&fromdate=${fromDate}&todate=${todate}&Count=${Count}&paymentId=&TransactionType=`;
                const req5 = { bankToken: `Bearer ${bankToken}` , params: params5};
                const res5 = await axios.post('https://admin.intravelcard.com/getBankInfo/getTransactions/', req5, config1);
                if (res5.data.data === null) {
                    setTransactions([]);
                    setLabel("You didn't make any transactions!");
                } else {
                    setTransactions(res5.data.data);
                    setHaveT(!haveT);
                }
                setShowDialogBox(false);
                setLoad(true);
            } else {
                setShowDialogBox(true);
                setDialogBoxData('No Card!');
                setBalance(0);
                setTransactions([]);
                setLabel("You didn't make any transactions!");
                setLoad(true);
            }
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setTransactions([]);
            setLabel("You didn't make any transactions!");
            setLoad(true);
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const showResult = () => {
        if (load === true && transactionsBySearch) {
            return (
                <>
                    {transactionsBySearch.length === 0
                        ? (
                            <label
                                className='all-center'
                                style={{
                                    marginTop: 20,
                                    marginBottom: 5,
                                    color: 'red',
                                    fontSize: 15
                                }}>
                                {label}
                            </label>
                        )
                        : (
                            <div>
                                {pageOfItems.map(t =>
                                    <div
                                        key={t.paymentID}
                                        style={styles.contentStyle}
                                        className='report'
                                    >
                                        <div className='grid-4' style={{alignItems: 'center'}}>
                                            <div>
                                                <label>{t.transDate.slice(0, 10)}</label>
                                                <div style={{fontSize: 10.5, color: '#626465'}}>{t.transDate.slice(11, 16)}</div>
                                            </div>
                                            <label>{t.transactionDocumentNumber}</label>
                                            <div>
                                                {t.transactionType === 2 ? '+' : '-'}
                                                <label>
                                                    {divideNum(t.transAmount)} IRR
                                                </label>
                                                <br/>
                                                <label style={{fontSize: 10.5, color: '#626465'}}>
                                                    Approx {' '}
                                                    {dollarPrice === 1 || euroPrice === 1
                                                        ? '-'
                                                        : (
                                                            currency === '1'
                                                                ? (t.transAmount/euroPrice).toFixed(2) + ' €'
                                                                : (t.transAmount/dollarPrice).toFixed(2) + ' $'
                                                        )
                                                    }
                                                </label>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div>
                                                    <label>
                                                        {t.transactionType === 2
                                                            ? divideNum(t.oldBalance + t.transAmount) + ' IRR'
                                                            : divideNum(t.oldBalance - t.transAmount) + ' IRR'
                                                        }
                                                    </label>
                                                    <div style={{fontSize: 10.5, color: '#626465'}}>
                                                        Approx {' '}
                                                        {dollarPrice === 1 || euroPrice === 1
                                                            ? '-'
                                                            : (
                                                                currency === '1'
                                                                    ? t.transactionType === 2
                                                                        ? ((t.oldBalance + t.transAmount)/euroPrice).toFixed(2) + ' €'
                                                                        : ((t.oldBalance - t.transAmount)/euroPrice).toFixed(2) + ' €'
                                                                    : t.transactionType === 2
                                                                        ? ((t.oldBalance + t.transAmount)/dollarPrice).toFixed(2) + ' $'
                                                                        : ((t.oldBalance - t.transAmount)/dollarPrice).toFixed(2) + ' $'
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <button
                                                    style={{
                                                        backgroundColor: 'white',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        right: '9%',
                                                        margin: 0,
                                                        marginTop: 9,
                                                        padding: 0,
                                                        border: 0,
                                                        cursor: 'pointer',
                                                    }} title='add note'>
                                                    <img
                                                        src='/Images/Icons/notepad.png'
                                                        alt='Note'
                                                        style={{width: 16, height: 16}}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='text-right'>
                                    <Pagination
                                        pageSize={6}
                                        items={transactionsBySearch}
                                        onChangePage={onChangePage}
                                    />
                                </div>
                            </div>
                        )
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    const showResult_mobile = () => {
        if (load === true && transactionsBySearch) {
            return (
                <div>
                    {transactionsBySearch.length !== 0
                        ? (
                            transactionsBySearch.map(t => {
                                return (
                                    <div key={t.paymentID} className='trans-shadow'
                                         style={{
                                             display: 'flex',
                                             flexDirection: 'row',
                                             backgroundColor: 'white',
                                             borderRadius: 10,
                                             padding: 12,
                                             marginTop: 12
                                         }}>
                                        <div style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            width: 'max-content',
                                        }}>
                                            {t.transactionType === 0
                                                ? (
                                                    <>
                                                        <i
                                                            className='fa fa-credit-card'
                                                            style={{color: 'black', fontSize: 25}}
                                                        />
                                                        <i className = 'fa fa-arrow-circle-o-down'
                                                           style={{
                                                               position: 'relative',
                                                               top: -10, left: -7,
                                                               backgroundColor: 'white',
                                                               borderRadius: '50%',
                                                               borderWidth: 0,
                                                               color: 'red',
                                                               fontSize: 14,
                                                           }}
                                                        />
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <i
                                                            className='fa fa-credit-card'
                                                            style={{color: 'black', fontSize: 25}}
                                                        />
                                                        <i className = 'fa fa-arrow-circle-o-up'
                                                           style={{
                                                               position: 'relative',
                                                               top: -10, left: -7,
                                                               backgroundColor: 'white',
                                                               borderRadius: '50%',
                                                               borderWidth: 0,
                                                               color: '#04ac0a',
                                                               fontSize: 14,
                                                           }}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div style={{
                                            fontSize: 13,
                                            marginLeft: 5,
                                            color: 'black',
                                        }}>
                                            <label>
                                                {t.transDate.slice(0, 4)}/
                                                {t.transDate.slice(5, 7)}/
                                                {t.transDate.slice(8, 10)}
                                            </label>
                                            <br/>
                                            <label style={{color: '#716969', fontSize: 11}}>
                                                {t.transDate.slice(11, 16)}
                                            </label>
                                        </div>
                                        <div style={{
                                            color: 'black',
                                            position: 'absolute',
                                            width: 120,
                                            right: 40
                                        }}>
                                            <div style={{fontSize: 14, fontWeight: 'bold'}}>
                                                {t.transactionType === 2
                                                    ? <label style={{color: '#04ac0a'}}>
                                                        + {divideNum(t.transAmount)} IRR
                                                    </label>
                                                    : '- ' + divideNum(t.transAmount) + ' IRR'
                                                }
                                            </div>
                                            <div style={{color: '#716969', fontSize: 11}}>
                                                Approx {' '}
                                                {dollarPrice === 1 || euroPrice === 1
                                                    ? '-'
                                                    : (
                                                        currency === '1'
                                                            ? (t.transAmount/euroPrice).toFixed(2) + ' €'
                                                            : (t.transAmount/dollarPrice).toFixed(2) + ' $'
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <button style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            right: 16,
                                            marginTop: 30,
                                            backgroundColor: 'white',
                                            border: 0,
                                            cursor: 'pointer',
                                        }} title='add note'>
                                            <img
                                                src='/Images/Icons/notepad.png'
                                                alt='Note'
                                                style={{width: 16, height: 16}}
                                            />
                                        </button>
                                    </div>
                                )
                            })
                        )
                        : <label
                            className='all-center'
                            style={{
                                marginTop: 15,
                                color: 'red',
                                fontSize: 14
                            }}>
                            {label}
                        </label>
                    }
                </div>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (!haveTrans && isAuthenticated) {
            getCurrencies();
            getInfo();
            setHaveTrans(true);
        }
        if (search !== '') {
            onSearch();
        } else {
            setTransactionsBySearch(transactions);
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, haveTrans, reverse, search, isAuthenticated, haveT, width]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={2}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='reports'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            <div style={styles.row1Style}>
                                <div style={{marginRight: 80}}>
                                    <button style={styles.sortBtnStyle} onClick={sort}>
                                        <i className="fa fa-sort" style={{marginRight: 8}}/>
                                        Sort by Date
                                        <i className={sortIcon} style={styles.sortIconStyle}/>
                                    </button>
                                </div>
                                <div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: 200
                                        }}>
                                            <i className="fa fa-calendar" style={{marginRight: 8}}/>
                                            <label style={styles.sortBtnStyle} >
                                                Any Date
                                                <i className='fa fa-angle-down' style={styles.sortIconStyle}/>
                                            </label>
                                        </div>
                                        <input
                                            type='date'
                                            name='search'
                                            value={search}
                                            onChange={onChange}
                                            style={{
                                                margin: 0,
                                                marginLeft: -200,
                                                width: 110,
                                                opacity: 0,
                                            }}
                                        />
                                    </div>
                                    {search
                                        ? (
                                            <div style={{
                                                textAlign: 'center',
                                                width: 100,
                                                margin: 0,
                                                marginTop: -5,
                                                marginBottom: -9,
                                                fontSize: 8
                                            }}>
                                                {search}
                                            <button
                                                onClick={clear}
                                                style={{
                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                    marginLeft: 5,
                                                    borderWidth: 0,
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                    fontSize: 9
                                                }}>
                                                <i className="fa fa-times" />
                                            </button>
                                            </div>
                                        )
                                        : null
                                    }
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    right: '2.5%',
                                    marginRight: 13,
                                    marginTop: 4
                                }}>
                                    <button
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0)',
                                            width: 25,
                                            borderWidth: 0,
                                            cursor: 'pointer'
                                        }}
                                        onClick={getTransactions}
                                        title='refresh'
                                    >
                                        <i className="fa fa-refresh" style={{fontSize: 14, color: '#0bdefa'}}/>
                                    </button>
                                </div>
                            </div>
                            <div style={styles.row2Style}>
                                <div className='grid-4' style={{padding: 10, paddingTop: 0, alignItems: 'center'}}>
                                    <label>Date</label>
                                    <label>Trace ID</label>
                                    <label>Amount</label>
                                    <label>Last Balance</label>
                                </div>
                                <div style={styles.lineStyle}/>
                                {showResult()}
                            </div>
                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 0,
                            paddingTop: 20,
                            paddingBottom: 75,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 0,
                                height: 80,
                                color: '#003e57',
                            }}>
                                <div style={{marginLeft: 20}}>
                                    <label style={{fontSize: 12}}>Your Balance</label>
                                    {balance !== 0
                                        ? (
                                            <div className='text-right' style={{width: 'max-content'}}>
                                                <div style={{marginTop: -3, fontSize: 18, fontWeight: 'bold'}}>
                                                    {divideNum(balance)} IRR
                                                </div>
                                                <div style={{marginTop: -4, fontSize: 10}}>
                                                    Approx {' '}
                                                    {dollarPrice === 1 || euroPrice === 1
                                                        ? '-'
                                                        : (
                                                            currency === '1'
                                                                ? (balance/euroPrice).toFixed(2) + ' €'
                                                                : (balance/dollarPrice).toFixed(2) + ' $'
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <div style={{width: 'max-content'}}>
                                                <div style={{marginTop: -3, fontSize: 18, fontWeight: 'bold'}}>
                                                    {balance} IRR
                                                </div>
                                                <div style={{marginTop: 0, fontSize: 10}}>
                                                    Approx {' '}
                                                    {dollarPrice === 1 || euroPrice === 1
                                                        ? '-'
                                                        : (
                                                            currency === '1'
                                                                ? (balance/euroPrice).toFixed(2) + ' €'
                                                                : (balance/dollarPrice).toFixed(2) + ' $'
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginTop: -25,
                                marginBottom: 3,
                                marginRight: 3,
                            }}>
                                <button
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        width: 30,
                                        borderWidth: 0,
                                        cursor: 'pointer'
                                    }}
                                    onClick={getTransactions}
                                    title='refresh'
                                >
                                    <i className="fa fa-refresh" style={{fontSize: 14, color: '#0bdefa'}}/>
                                </button>
                            </div>

                            <div
                                className='box-shadow-in-reports'
                                style={{
                                    backgroundColor: '#f6fbfb',
                                    paddingTop: 8,
                                    paddingBottom: 20,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    fontSize: 12,
                            }}>
                                <div className='grid-2-reports'>
                                    <button style={styles.sortBtnStyle_mobile} onClick={sort}>
                                        <i className="fa fa-sort" style={{marginRight: 8}}/>
                                        Sort by Date
                                        <i className={sortIcon} style={styles.sortIconStyle_mobile}/>
                                    </button>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: 85
                                        }}>
                                            <i className="fa fa-calendar" style={{marginRight: 8}}/>
                                            <label style={styles.sortBtnStyle_mobile} >
                                                Any Date
                                                <i className='fa fa-angle-down' style={styles.sortIconStyle_mobile}/>
                                            </label>
                                        </div>
                                        <input
                                            type='date'
                                            name='search'
                                            value={search}
                                            onChange={onChange}
                                            style={{
                                                margin: 0,
                                                marginLeft: -87,
                                                width: 85,
                                                opacity: 0,
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='grid-2-reports' style={{width: '100%'}}>
                                    <div/>
                                    <div style={{
                                        textAlign: 'center',
                                        fontSize: 8,
                                        marginTop: -6,
                                        marginBottom: -8,
                                    }}>
                                        {search}
                                        {search
                                            ? (
                                                <button
                                                    onClick={clear}
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0)',
                                                        marginLeft: 5,
                                                        borderWidth: 0,
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        fontSize: 9
                                                    }}>
                                                    <i className="fa fa-times" />
                                                </button>
                                            )
                                            : null
                                        }
                                    </div>
                                </div>

                                {showResult_mobile()}
                            </div>
                        </div>
                        <BottomMenu/>
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 0,
    },
    row1Style: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#f6fbfc',
        padding: 16,
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 10,
        borderRadius: 10,
        fontSize: 15
    },
    sortBtnStyle: {
        backgroundColor: '#f6fbfc',
        padding: 0,
        borderWidth: 0,
        cursor: 'pointer',
    },
    sortIconStyle: {
        position: 'relative',
        top: 3,
        left: 5,
        color: '#69c8ce',
        fontSize: 25,
        fontWeight: 600,
    },
    row2Style: {
        backgroundColor: '#f6fbfc',
        padding: 20,
        paddingLeft:30,
        paddingRight: 30,
        paddingBottom: 15,
        borderRadius: 10,
    },
    lineStyle: {
        marginBottom: 5,
        borderStyle: 'solid',
        borderColor: '#69c8ce',
        borderWidth: 1.5,
        borderRadius: 10
    },
    contentStyle: {
        backgroundColor: 'white',
        padding: 20,
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: 12,
    },
    sortIconStyle_mobile: {
        position: 'relative',
        top: 3,
        left: 5,
        color: '#69c8ce',
        fontSize: 18,
        fontWeight: 600,
    },
    sortBtnStyle_mobile: {
        backgroundColor: '#f6fbfb',
        padding: 0,
        borderWidth: 0,
        cursor: 'pointer',
    }
};

export default Reports;
