import React, {useContext} from 'react';
import AlertContext from '../../context/alert/alertContext';

const AlertsSettings = () => {
    const alertContext = useContext(AlertContext);
    const { alert } = alertContext;

    return (
        (alert !== null)
            ? (
                <div className='all-center' style={styles.errorStyle}>
                    <label className='text-center' style={{color: alert.type}}>{alert.msg}</label>
                </div>
            )
            : null
    );
};

const styles = {
    errorStyle: {
        position: 'relative',
        alignSelf: 'center',
        margin: 'auto',
        marginTop: 10,
        marginBottom: -22,
        width: 'max-content',
        height: 'max-content',
        borderRadius: 5,
        fontSize: window.innerWidth > 767 ? 13 : 12,
    }
};

export default AlertsSettings;
