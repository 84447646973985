import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../../App.css';

const Package = ({ label, price, img, title, info, onClick, familyData }) => {
    const [cardNum, setCardNum] = useState('2');
    const [SIMCardNum, setSIMCardNum] = useState('0');

    const [off, setOff] = useState('0%');
    const [discountedPrice, setDiscountedPrice] = useState(0);

    const onChangeCard = (e) => {
        setCardNum(e.target.value)
    };

    const onChangeSIMCard = (e) => {
        setSIMCardNum(e.target.value)
    };

    const calculateCardPrice = () => {
        if (cardNum === '2') {
            setOff(`${familyData.twoCards.off}%`);
            setDiscountedPrice(Number(familyData.twoCards.final_price)*2);
            return Number(familyData.twoCards.final_price)*2;
        } else if (cardNum === '3') {
            setOff(`${familyData.threeCards.off}%`);
            setDiscountedPrice(Number(familyData.threeCards.final_price)*3);
            return Number(familyData.threeCards.final_price)*3;
        } else if (cardNum === '4') {
            setOff(`${familyData.fourCards.off}%`);
            setDiscountedPrice(Number(familyData.fourCards.final_price)*4);
            return Number(familyData.fourCards.final_price)*4;
        } else if (Number(cardNum) >= 5) {
            setOff(`${familyData.fiveAndUpCards.off}%`);
            setDiscountedPrice(Number(familyData.fiveAndUpCards.final_price)*Number(cardNum));
            return Number(familyData.fiveAndUpCards.final_price)*Number(cardNum);
        }
    };

    const calculateSIMCardPrice = () => {
        const initialPrice = Number(familyData.cardPrice)*Number(cardNum) + Number(familyData.SIMCardPrice)*Number(SIMCardNum);
        if (SIMCardNum === '0') {
            calculateCardPrice();
        } else if (SIMCardNum === '1') {
            const c = calculateCardPrice();
            setDiscountedPrice(c + Number(familyData.oneSIMCard.final_price));
            const p = c + Number(familyData.oneSIMCard.final_price);
            setOff(`${(100 - (100*p/initialPrice)).toFixed(2)}%`);
        } else if (SIMCardNum === '2') {
            const c = calculateCardPrice();
            setDiscountedPrice(c + Number(familyData.twoSIMCards.final_price)* 2);
            const p = c + Number(familyData.twoSIMCards.final_price)* 2;
            setOff(`${(100 - (100*p/initialPrice)).toFixed(2)}%`);
        } else if (Number(SIMCardNum) >= 3) {
            const c = calculateCardPrice();
            setDiscountedPrice(c + Number(familyData.threeAndUpSIMCards.final_price)*Number(SIMCardNum));
            const p = c + Number(familyData.threeAndUpSIMCards.final_price)*Number(SIMCardNum);
            setOff(`${(100 - (100*p/initialPrice)).toFixed(2)}%`);
        }
    };

    useEffect(() => {
        if (title === 'Family Package') {
            calculateSIMCardPrice()
        }
    },[familyData, cardNum, SIMCardNum]);

    return (
        title === 'IntravelCard + SimCard'
            ? (
                <div className='card shadowBox-in-converter' style={styles.cardStyle_middle}>
                    {label !== '0% OFF'
                        ? (
                            <div className='text-center' style={styles.labelStyle_middle}>
                                <label>{label}</label>
                            </div>
                        )
                        : (
                            <div className='text-center' style={styles.emptyLabelStyle}>
                                <label><br/></label>
                            </div>
                        )
                    }
                    <div className='text-center' style={styles.titleStyle_middle}>
                        <div style={{marginBottom: 5}}>{title}</div>
                        <sup style={{fontSize: 14, marginRight: 3}}>€</sup>
                        <label style={{fontSize: 20}}>{price}</label>
                    </div>
                    <img
                        style={styles.imageStyle}
                        src={img}
                        alt={title}
                    />
                    <p style={styles.infoStyle}>{info}</p>
                    <a
                        className='btn btn-link btn-block all-center'
                        style={styles.btnStyle_middle}
                        href={onClick}
                    >Order Now</a>
                </div>
            )
            : (
                title === 'Family Package'
                    ? (
                        <div className='card shadowBox-in-converter' style={styles.cardStyle}>
                            <div className='text-center' style={styles.labelStyle_fa}>
                                <label>{label}</label>
                            </div>
                            <div className='text-center' style={styles.titleStyle}>
                                <div style={{marginBottom: 5}}>{title}</div>
                                <label style={{fontSize: 20, color: '#f4ac50'}}>{price}</label>
                            </div>
                            <img
                                style={styles.imageStyle_fa}
                                src={img}
                                alt={title}
                            />
                            <p style={styles.infoStyle_fa}>
                                <div className='grid-2-in-packages'>
                                    <div>
                                        <div style={{fontSize: 13}}>IntravelCard:</div>
                                        <input
                                            className='input-in-packages'
                                            style={styles.inputStyle}
                                            type='number'
                                            name='cardNum'
                                            value={cardNum}
                                            onChange={onChangeCard}
                                            min={2}
                                        />
                                    </div>
                                    <div>
                                        <div style={{fontSize: 13}}>SIMCard:</div>
                                        <input
                                            className='input-in-packages'
                                            style={styles.inputStyle}
                                            type='number'
                                            name='SIMCardNum'
                                            value={SIMCardNum}
                                            onChange={onChangeSIMCard}
                                            min={0}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#04ac0a',
                                        width: 'max-content',
                                        margin: 'auto',
                                        marginTop: 23,
                                        marginBottom: -3,
                                        left: 55,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        color: 'white',
                                        fontSize: 10,
                                        fontWeight: 'bold'
                                    }}
                                >{off} OFF</div>
                                <div style={{textAlign: 'center'}}>
                                    <sup
                                        style={{
                                            position: 'relative',
                                            top: -7,
                                            marginRight: 3,
                                            color: '#003e57',
                                            fontSize: 16,
                                            fontWeight: 'bold'
                                        }}>€</sup>
                                    <label
                                        style={{
                                            fontSize: 23,
                                            color: '#003e57',
                                            fontWeight: 'bold'
                                        }}
                                    >{discountedPrice.toFixed(2)}</label>
                                </div>
                            </p>
                            <a
                                className='btn btn-link btn-block all-center'
                                style={styles.btnStyle}
                                href={onClick}
                            >Order Now</a>
                        </div>
                    )
                    : (
                        <div className='card shadowBox-in-converter' style={styles.cardStyle}>
                            {label !== '0% OFF'
                                ? (
                                    <div className='text-center' style={styles.labelStyle}>
                                        <label>{label}</label>
                                    </div>
                                )
                                : (
                                    <div className='text-center' style={styles.emptyLabelStyle}>
                                        <label><br/></label>
                                    </div>
                                )
                            }
                            <div className='text-center' style={styles.titleStyle}>
                                <div style={{marginBottom: 5}}>{title}</div>
                                <sup style={{fontSize: 14, marginRight: 3}}>€</sup>
                                <label style={{fontSize: 20}}>{price}</label>
                            </div>
                            <img
                                style={styles.imageStyle}
                                src={img}
                                alt={title}
                            />
                            <p style={styles.infoStyle}>{info}</p>
                            <a
                                className='btn btn-link btn-block all-center'
                                style={styles.btnStyle}
                                href={onClick}
                            >Order Now</a>
                        </div>
                    )
            )
    );
};

Package.propTypes = {
    label: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    info: PropTypes.array.isRequired,
    onClick: PropTypes.string.isRequired,
    familyData: PropTypes.object
};

const styles = {
    cardStyle: {
        backgroundColor: 'white',
        width: 240,
        height: 460,
        margin:'auto',
        padding: 0
    },
    cardStyle_middle: {
        backgroundColor: 'white',
        width: 240,
        height: 460,
        margin:'auto',
        padding: 0,
        borderStyle: 'solid',
        borderColor: '#0bdefa',
        borderWidth: 3
    },
    labelStyle: {
        position: 'relative',
        backgroundColor: 'white',
        width: '30%',
        left: '70%',
        padding: 1,
        fontSize: 14,
        fontWeight: 'bold',
        borderStyle: 'solid',
        borderColor: '#ccc',
        borderWidth: 1,
        borderTopWidth: 0,
        borderRightWidth: 0,
    },
    labelStyle_middle: {
        position: 'relative',
        backgroundColor: '#0bdefa',
        width: '40%',
        left: '60%',
        padding: 1,
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold'
    },
    labelStyle_fa: {
        position: 'relative',
        backgroundColor: '#f4ac50',
        width: '50%',
        left: '50%',
        padding: 2,
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold'
    },
    emptyLabelStyle: {
        position: 'relative',
        width: '50%',
        left: '50%',
    },
    titleStyle: {
        margin: 'auto',
        marginTop: 10,
        marginBottom: 5,
        color: 'black',
        fontSize: 17,
        fontWeight: 600,
    },
    titleStyle_middle: {
        margin: 'auto',
        marginTop: 10,
        marginBottom: 5,
        color: '#003e57',
        fontSize: 17,
        fontWeight: 600,
    },
    imageStyle: {
        display: 'flex',
        width: '75%',
        margin: 'auto',
    },
    imageStyle_fa: {
        display: 'flex',
        width: '75%',
        margin: 'auto',
        marginTop: -20,
        marginBottom: -15
    },
    infoStyle: {
        height: 120,
        margin: 'auto',
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 14,
    },
    infoStyle_fa: {
        height: 155,
        margin: 'auto',
        padding: 5,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 14,
    },
    btnStyle: {
        width: 110,
        margin: 'auto',
        padding: 3,
        color: 'orange',
        fontSize: 14,
        fontWeight: 600,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 1.5,
        borderRadius: 15,
    },
    btnStyle_middle: {
        backgroundColor: '#0bdefa',
        width: 110,
        margin: 'auto',
        padding: 3,
        color: 'white',
        fontSize: 14,
        fontWeight: 600,
        borderStyle: 'solid',
        borderColor: '#0bdefa',
        borderWidth: 1.5,
        borderRadius: 15,
    },
    inputStyle: {
        width: 80,
        height: 30,
        margin: 0,
        marginTop: 5,
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: '#ddd',
        borderRadius: 5,
        textAlign: 'center',
    }
};

export default Package;
