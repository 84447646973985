import React, {Fragment, useContext, useEffect, useState} from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import BottomMenu from "../layout/BottomMenu";
import Card from '../layout/Card';
import SpinnerModal from '../layout/SpinnerModal';
import DialogBox from '../layout/DialogBox';
import history from '../history';
import '../../Styles/NewStyles.css';
import '../../App.css';

const TopUp = (props) => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, id, username} = authContext; // +currency

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220,
        marginLeft: 220,
    });

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [balance, setBalance] = useState('');
    const [pan, setPan] = useState('');

    const [load, setLoad] = useState(false);

    const [dollarPrice, setDollarPrice] = useState(1);
    const [euroPrice, setEuroPrice] = useState(1);
    const [haveInfo, setHaveInfo] = useState(false);

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const [form , setForm] = useState({
        user_ID : id,
        firstName: '',
        lastName: '',
        email: username,
        pan: '',
        amount: null,
        currency: authContext.currency ? authContext.currency : '1',
        payType: '2',
    });

    const {amount, currency, payType} = form;

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const currRadioChangeHandler = (event) => {
        setForm( {...form,  currency: event.target.value });
    };

    const typeRadioChangeHandler = (event) => {
        setForm( {...form,  payType: event.target.value });
    };

    const divideNum = (num) => {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    const getCurrencies = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/curr/CurrenciesList/', config);
            setDollarPrice(res.data[0].dollar);
            setEuroPrice(res.data[0].euro);
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
            setDollarPrice(1);
            setEuroPrice(1);
        }
    };

    const topUp = async (e) => {
        e.preventDefault();
        setLoad(false);
        //django server
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            await axios.post('https://admin.intravelcard.com/api/req/CreateTopUp/', form, config);
            setShowDialogBox(true);
            setDialogBoxData('done');
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const showScreen0 = () => {
        return (
            <>
                <div style={{
                    marginTop: 10,
                    fontSize: width > 767 ? 16 : 14,
                    color: 'red',
                    textAlign: 'center'
                }}>
                    No card issued to you!
                </div>
                <div
                    className='grid-2-for-btns-in-refund'
                    style={{
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 0
                    }}
                >
                    <a
                        href='javascript:history.go(-1)'
                        className='btn text-center back-btn-in-refund'
                    >
                        Back
                    </a>
                </div>
            </>
        )
    };

    const showScreen1 = () => {
        return (
            <>
                <div style={{
                    marginTop: 10,
                    marginBottom: 15,
                    //textAlign: 'justify',
                    fontSize: 14,
                    color: '#626465'
                }}>
                    You can add funds to your card using cryptocurrency transfers or cash.
                </div>
                <form onSubmit={topUp} className='form-in-refund'>
                    <div style={{marginBottom: 15}}>
                        <label
                            htmlFor='amount'
                            style={styles.questionStyle}
                        >
                            How much funds do you want to add to your IntravelCard?
                        </label>
                        {width > 767
                            ? (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: 55,
                                        alignItems: 'center',
                                        color: '#626465'
                                    }}>
                                        <input
                                            className='refund'
                                            type='number'
                                            name='amount'
                                            value={amount}
                                            onChange={onChange}
                                            required
                                            placeholder='EXP: 500'
                                        />
                                        <RadioGroup
                                            name='currency'
                                            value={currency}
                                            onChange={currRadioChangeHandler}
                                            row
                                            style={{margin: 0,  marginLeft: 30}}
                                        >
                                            <FormControlLabel value='1' control={<Radio />} label='€ Euro'/>
                                            <FormControlLabel value='2' control={<Radio />} label='$ USD'/>
                                        </RadioGroup>
                                    </div>
                                    <div style={{
                                        fontSize: 11,
                                        color: 'gray',
                                        marginLeft: 8,
                                        marginTop: -8
                                    }}>
                                        Approx {' '}
                                        {dollarPrice === 1 || euroPrice === 1
                                            ? '-'
                                            : (
                                                currency === '1'
                                                    ? divideNum(amount*euroPrice) + ' IRR'
                                                    : divideNum(amount*dollarPrice) + ' IRR'
                                            )
                                        }
                                    </div>
                                </>
                            )
                            : (
                                <>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: 55,
                                        alignItems: 'center',
                                        color: '#626465',
                                    }}>
                                        <input
                                            className='refund'
                                            type='number'
                                            name='amount'
                                            value={amount}
                                            onChange={onChange}
                                            required
                                            placeholder='EXP: 500'
                                        />
                                        <div style={{
                                            fontSize: 10,
                                            color: 'gray',
                                            marginLeft: 8,
                                            marginTop: 0
                                        }}>
                                            Approx {' '}
                                            {dollarPrice === 1 || euroPrice === 1
                                                ? '-'
                                                : (
                                                    currency === '1'
                                                        ? divideNum(amount*euroPrice) + ' IRR'
                                                        : divideNum(amount*dollarPrice) + ' IRR'
                                                )
                                            }
                                        </div>
                                    </div>
                                    <RadioGroup
                                        name='currency'
                                        value={currency}
                                        onChange={currRadioChangeHandler}
                                        row
                                        style={{margin: 0, marginTop: -6, marginLeft: 7, color: '#626465'}}
                                    >
                                        <FormControlLabel value='1' control={<Radio />} label='€ Euro'/>
                                        <FormControlLabel value='2' control={<Radio />} label='$ USD'/>
                                    </RadioGroup>
                                </>
                            )
                        }
                    </div>
                    <div style={{marginTop: 15, color: '#626465'}}>
                        <label
                            htmlFor='payType'
                            style={styles.questionStyle}
                        >
                            How do you want to add funds?
                        </label>
                        {width > 767
                            ? (
                                <RadioGroup
                                    name='payType'
                                    value={payType}
                                    onChange={typeRadioChangeHandler}
                                    row
                                    style={{margin: 0, marginTop: 5, marginLeft: 7}}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label='Bitcoin'/>
                                    <FormControlLabel value='2' control={<Radio />} label='Cash (only Tehran)'  style={{marginLeft: 30}}/>
                                    <FormControlLabel value='3' control={<Radio />} label='Other' style={{marginLeft: 30}}/>
                                </RadioGroup>
                            )
                            : <RadioGroup
                                name='payType'
                                value={payType}
                                onChange={typeRadioChangeHandler}
                                style={{margin: 0, marginTop: 5, marginLeft: 7}}
                            >
                                <FormControlLabel value='1' control={<Radio />} label='Bitcoin'/>
                                <FormControlLabel value='2' control={<Radio />} label='Cash (only Tehran)'/>
                                <FormControlLabel value='3' control={<Radio />} label='Other'/>
                            </RadioGroup>
                        }
                    </div>
                    {width > 767
                        ? (
                            <div
                                className='grid-2-for-btns-in-refund'
                                style={{display: 'flex', alignItems: 'center', marginTop: 25}}
                            >
                                <a
                                    href='javascript:history.go(-1)'
                                    className='btn text-center'
                                    style={styles.cancelBtnStyle}
                                >
                                    Cancel
                                </a>
                                <input
                                    type='submit'
                                    value='Request'
                                    className='btn text-center'
                                    style={styles.requestBtnStyle}
                                />
                            </div>
                        )
                        : (
                            <div style={{marginTop: 10}}>
                                <input
                                    type='submit'
                                    value='Request'
                                    className='btn text-center'
                                    style={styles.requestBtnStyle_mobile}
                                />
                            </div>
                        )
                    }
                </form>
            </>
        )
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (props.location.state && isAuthenticated) {
            setFirstName(props.location.state.firstName);
            setLastName(props.location.state.lastName);
            setBalance(props.location.state.balance);
            setPan(props.location.state.cardNum);
            setForm({ ...form,
                pan: props.location.state.cardNum,
                firstName: props.location.state.firstName,
                lastName: props.location.state.lastName });
        } else {
            history.push('/dashboard');
        }
        if (!haveInfo && isAuthenticated) {
            getCurrencies();
            setHaveInfo(true)
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, props.location.state, haveInfo, isAuthenticated, width]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={3}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='top-up'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            <Card
                                width={width}
                                firstName={firstName}
                                lastName={lastName}
                                balance={balance}
                                cardNum={pan}
                                haveBtn={false}
                            />
                            <div style={styles.smallCadreStyle}>
                                <strong style={{fontSize: 20}}>Top Up</strong>
                                {pan === '---- ---- ---- ----'
                                    ? showScreen0()
                                    : showScreen1()
                                }
                            </div>
                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 20,
                            paddingBottom: 100,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <Card
                                width={width}
                                firstName={firstName}
                                lastName={lastName}
                                balance={balance}
                                cardNum={pan}
                                dollarPrice={dollarPrice}
                                euroPrice={euroPrice}
                                currency={currency}
                                haveBtn={false}
                                haveCard={false}
                            />
                            <div style={styles.smallCadreStyle_mobile}>
                                <strong style={{fontSize: 16}}>Top Up</strong>
                                {pan === '---- ---- ---- ----'
                                    ? showScreen0()
                                    : showScreen1()
                                }
                            </div>
                        </div>
                        <BottomMenu/>
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 0,
    },
    smallCadreStyle: {
        backgroundColor: 'white',
        padding: 20,
        paddingLeft: 35,
        paddingRight: 35,
        borderRadius: 10,
    },
    questionStyle: {
        textAlign: 'justify',
        fontSize: 14,
        color: '#626465'
    },
    inputStyle: {
        margin: 0,
        marginTop: 10,
    },
    requestBtnStyle: {
        backgroundColor: 'orange',
        color: 'white',
        fontSize: 14,
        height: 32,
        width: 100,
        margin: 0,
        marginTop: 10,
        paddingTop: 2.5,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 2,
        borderRadius: 15,
    },
    cancelBtnStyle: {
        backgroundColor: 'white',
        color: 'orange',
        fontSize: 14,
        height: 32,
        width: 100,
        margin: 0,
        marginTop: 10,
        paddingTop: 3,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 1.5,
        borderRadius: 15,
    },
    smallCadreStyle_mobile: {
        backgroundColor: 'white',
        width: '100%',
        marginTop: -220,
        padding: 15,
    },
    requestBtnStyle_mobile: {
        backgroundColor: 'orange',
        color: 'white',
        fontSize: 14,
        height: 35,
        width: '100%',
        margin: 0,
        marginTop: 10,
        paddingTop: 4,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 2,
        borderRadius: 8,
    },
};

export default TopUp;
