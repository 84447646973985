import React, {useState, useContext, useEffect} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import Alerts from './Alerts';
import '../../Styles/PopUp.css';
import '../../App.css';
import '../../Styles/FloatInputs.scss';
import backImage from '../../Img/background-login-mob.png'

const PopUpLogin = () => {
    const authContext = useContext(AuthContext);
    const alertContext = useContext(AlertContext);

    const { register, login, error, clearErrors } = authContext;
    const { setAlert } = alertContext;

    const [width, setWidth] = useState(window.innerWidth);

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const {email, password} = user;

    const [inputType, setInputType] = useState('password');
    const [showPassImg, setShowPassImg] = useState('Images/Icons/eye.png');

    const [activeTab, setActiveTab] = useState(0);

    const [os, setOs] = useState('');
    const [showIOS, setShowIOS] = useState(null);

    const showPass = (e) => {
        e.preventDefault();
        if (inputType === 'password') {
            setInputType('text');
            setShowPassImg('Images/Icons/closed eye.png');
        } else {
            setInputType('password');
            setShowPassImg('Images/Icons/eye.png');
        }
    };

    const changeTab = (e) => {
        e.preventDefault();
        if (activeTab === 0) {
            setActiveTab(1)
        } else {
            setActiveTab(0)
        }
    };

    useEffect(() => {
        if (error === 'User already exists!' || error === 'Invalid credentials!' || error === 'Profile load error!') {
            setAlert(error, 'danger');
            clearErrors();
        }
        if (width <= 767) {
            document.getElementById('email').addEventListener('focusin', function() {
                document.getElementById('logo-in-login').classList.add('focused');
            });
            document.getElementById('email').addEventListener('focusout', function() {
                document.getElementById('logo-in-login').classList.remove('focused');
            });
            document.getElementById('password').addEventListener('focusin', function() {
                document.getElementById('logo-in-login').classList.add('focused');
            });
            document.getElementById('password').addEventListener('focusout', function() {
                document.getElementById('logo-in-login').classList.remove('focused');
            });
        }

        if (navigator.userAgent.indexOf("Android") !== -1)
            setOs("Android OS");
        if (navigator.userAgent.indexOf("like Mac") !== -1) {
            setOs("IOS");
        }
        setTimeout(() => localStorage.setItem("showIOSAlert", 'true'), 5000);

        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
    }, [error, width, os]);

    const onChange = e => {
        setUser({...user, [e.target.name]: e.target.value});
    };

    const onRegSubmit = e => {
        e.preventDefault();
        register({
            username: email,
            password: password
        });
    };

    const onLoginSubmit = e => {
        e.preventDefault();
        login({
            username: email,
            password: password
        });
    };

    const close = (e) => {
        e.preventDefault();
        setShowIOS(styles.iosStyle)
    };

    return (
        <>
            <div
                className='all-center'
                id='popup-login'
                style={{
                    zIndex: 5,
                    backgroundColor: 'white'
                }}
            >
            {width > 767
                ? (
                    <div
                        id='popup_inner'
                        className='grid-2 shadowBox-in-converter'
                        style={{gridGap: 0, backgroundColor: 'white'}}
                    >
                        <div>
                            <img
                                src='/Images/login.png'
                                alt='Login'
                                style={{width: '100%'}}
                            />
                        </div>

                        <div  style={{display: 'flex', width: '100%'}}>
                            <div style={{width: '70%', margin: 'auto'}}>
                                <Tabs
                                    onSelect={(index) => setActiveTab(index)}
                                    selectedIndex={activeTab}
                                    forceRenderTabPanel={true}
                                    style={{textAlign: 'center', fontSize: 14}}
                                    className='login-tab'
                                >
                                    <TabList>
                                        <Tab id='tab1'>Sign In</Tab>
                                        <Tab id='tab2'>Sign Up</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <form onSubmit={onLoginSubmit} style={{marginTop: 50}}>
                                            <div className='form__group field' style={{marginTop: 0}}>
                                                <input
                                                    className='form__field email-login'
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    value={email}
                                                    onChange={onChange}
                                                    maxLength='150'
                                                    required
                                                    placeholder='Email'
                                                />
                                                <label
                                                    htmlFor='email'
                                                    className='form__label'
                                                >
                                                    <i className="fa fa-envelope" style={{fontSize: 12, marginRight: 5}}/>
                                                    Email
                                                </label>
                                            </div>

                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div className='form__group field'
                                                     style={{marginTop: 0, marginBottom: 0, width: '100%'}}
                                                >
                                                    <input
                                                        className='form__field pass-login'
                                                        type={inputType}
                                                        id='password'
                                                        name='password'
                                                        value={password}
                                                        onChange={onChange}
                                                        minLength='8'
                                                        required
                                                        placeholder='Password'
                                                    />
                                                    <label
                                                        htmlFor='password'
                                                        className='form__label'
                                                    >
                                                        <i className="fa fa-key" style={{fontSize: 12, marginRight: 5}}/>
                                                        Password
                                                    </label>
                                                </div>

                                                <div>
                                                    <button style={styles.eyeBtnStyle} onClick={showPass}>
                                                        <img
                                                            src={showPassImg}
                                                            alt='show'
                                                            style={{width: '65%'}}
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <a
                                                href='#'
                                                style={{
                                                    marginTop: 5,
                                                    marginLeft: -130,
                                                    color: '#4b4bf9',
                                                    fontSize: 10,
                                                }}
                                            >
                                                Forgot your password?
                                            </a>

                                            <div style={{marginTop: 30, marginBottom: -30}}>
                                                <Alerts/>
                                            </div>

                                            <input
                                                className='btn btn-block'
                                                style={styles.btnStyle}
                                                type='submit'
                                                value='Sign In'
                                            />

                                            <div style={{fontSize: 10, color: 'gray',}}>
                                                Don't have an account? {' '}
                                                <button
                                                    onClick={changeTab}
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0)',
                                                        borderWidth: 0,
                                                        color: '#4b4bf9',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    Click here
                                                </button>
                                            </div>
                                        </form>
                                    </TabPanel>
                                    <TabPanel>
                                        <form onSubmit={onRegSubmit} style={{marginTop: 50}}>
                                            <div className='form__group field' style={{marginTop: 0}}>
                                                <input
                                                    className='form__field email-login'
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    value={email}
                                                    onChange={onChange}
                                                    maxLength='150'
                                                    required
                                                    placeholder='Email'
                                                />
                                                <label
                                                    htmlFor='email'
                                                    className='form__label'
                                                >
                                                    <i className="fa fa-envelope" style={{fontSize: 12, marginRight: 5}}/>
                                                    Email
                                                </label>
                                            </div>

                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div className='form__group field'
                                                     style={{marginTop: 0, marginBottom: 0, width: '100%'}}
                                                >
                                                    <input
                                                        className='form__field pass-login'
                                                        type={inputType}
                                                        id='password'
                                                        name='password'
                                                        value={password}
                                                        onChange={onChange}
                                                        minLength='8'
                                                        required
                                                        placeholder='Password'
                                                    />
                                                    <label
                                                        htmlFor='password'
                                                        className='form__label'
                                                    >
                                                        <i className="fa fa-key" style={{fontSize: 12, marginRight: 5}}/>
                                                        Password
                                                    </label>
                                                </div>

                                                <div>
                                                    <button style={styles.eyeBtnStyle} onClick={showPass}>
                                                        <img
                                                            src={showPassImg}
                                                            alt='show'
                                                            style={{width: '65%'}}
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <div style={{marginTop: 53, marginBottom: -53}}>
                                                <Alerts/>
                                            </div>

                                            <div style={{marginTop: 57}}>
                                                <input
                                                    className='btn btn-block'
                                                    style={styles.btnStyle}
                                                    type='submit'
                                                    value='Sign Up'
                                                />
                                            </div>

                                            <div style={{fontSize: 10, color: 'gray',}}>
                                                Already have an account? {' '}
                                                <button
                                                    onClick={changeTab}
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0)',
                                                        borderWidth: 0,
                                                        color: '#4b4bf9',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    Sign In
                                                </button>
                                            </div>
                                        </form>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div id='popup_inner_mobile' style={{backgroundImage: `url(${backImage})`, padding: 45}}>
                        <div>
                            <img
                                src='/favicon.png'
                                alt='Logo'
                                style={{
                                    width: 80,
                                    marginLeft: -30,
                                    marginBottom: 20
                                }}
                                id='logo-in-login'
                            />
                            <Tabs
                                onSelect={(index) => setActiveTab(index)}
                                selectedIndex={activeTab}
                                forceRenderTabPanel={true}
                                style={{textAlign: 'center', fontSize: 14}}
                                className='login-tab-mobile'
                            >
                                <TabList>
                                    <Tab id='tab1'>Sign In</Tab>
                                    <Tab id='tab2'>Sign Up</Tab>
                                </TabList>
                                <TabPanel>
                                    <form onSubmit={onLoginSubmit} style={{marginTop: 20}}>
                                        <label style={{color: 'white'}}>
                                            COMPLETE THE FORM TO SIGN IN
                                        </label>
                                        <div className='form__group field' style={{marginTop: 30}}>
                                            <input
                                                className='form__field email-login-mobile'
                                                type='email'
                                                id='email'
                                                name='email'
                                                value={email}
                                                onChange={onChange}
                                                maxLength='150'
                                                required
                                                placeholder='Email'
                                            />
                                            <label
                                                htmlFor='email'
                                                className='form__label'
                                                style={{backgroundColor: 'rgba(0,0,0,0)'}}
                                            >
                                                <i className="fa fa-envelope" style={{fontSize: 12, marginRight: 5}}/>
                                                Email
                                            </label>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>
                                            <div className='form__group field'
                                                 style={{marginTop: 0, marginBottom: 0, width: '100%'}}
                                            >
                                                <input
                                                    className='form__field pass-login-mobile'
                                                    type={inputType}
                                                    id='password'
                                                    name='password'
                                                    value={password}
                                                    onChange={onChange}
                                                    minLength='8'
                                                    required
                                                    placeholder='Password'
                                                />
                                                <label
                                                    htmlFor='password'
                                                    className='form__label'
                                                    style={{backgroundColor: 'rgba(0,0,0,0)'}}
                                                >
                                                    <i className="fa fa-key" style={{fontSize: 12, marginRight: 5}}/>
                                                    Password
                                                </label>
                                            </div>

                                            <div>
                                                <button style={styles.eyeBtnStyle} onClick={showPass}>
                                                    <img
                                                        src={showPassImg}
                                                        alt='show'
                                                        style={{width: '65%'}}
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                        <a
                                            href='#'
                                            style={{
                                                marginTop: 5,
                                                marginLeft: '-60%',
                                                color: 'white',
                                                fontSize: 10,
                                            }}
                                        >
                                            Forgot your password?
                                        </a>

                                        <div style={{marginTop: 30, marginBottom: -30}}>
                                            <Alerts/>
                                        </div>

                                        <input
                                            className='btn btn-block'
                                            style={styles.btnStyle_mobile}
                                            type='submit'
                                            value='Sign In'
                                        />

                                        <div style={{fontSize: 10, color: 'white',}}>
                                            Don't have an account? {' '}
                                            <button
                                                onClick={changeTab}
                                                style={{
                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                    borderWidth: 0,
                                                    color: '#0bdefa',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Click here
                                            </button>
                                        </div>
                                    </form>
                                </TabPanel>
                                <TabPanel>
                                    <form onSubmit={onRegSubmit} style={{marginTop: 20}}>
                                        <label style={{color: 'white'}}>
                                            COMPLETE THE FORM TO SIGN UP
                                        </label>
                                        <div className='form__group field' style={{marginTop: 30}}>
                                            <input
                                                className='form__field email-login-mobile'
                                                type='email'
                                                id='email'
                                                name='email'
                                                value={email}
                                                onChange={onChange}
                                                maxLength='150'
                                                required
                                                placeholder='Email'
                                            />
                                            <label
                                                htmlFor='email'
                                                className='form__label'
                                                style={{backgroundColor: 'rgba(0,0,0,0)'}}
                                            >
                                                <i className="fa fa-envelope" style={{fontSize: 12, marginRight: 5}}/>
                                                Email
                                            </label>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>
                                            <div className='form__group field'
                                                 style={{marginTop: 0, marginBottom: 0, width: '100%'}}
                                            >
                                                <input
                                                    className='form__field pass-login-mobile'
                                                    type={inputType}
                                                    id='password'
                                                    name='password'
                                                    value={password}
                                                    onChange={onChange}
                                                    minLength='8'
                                                    required
                                                    placeholder='Password'
                                                />
                                                <label
                                                    htmlFor='password'
                                                    className='form__label'
                                                    style={{backgroundColor: 'rgba(0,0,0,0)'}}
                                                >
                                                    <i className="fa fa-key" style={{fontSize: 12, marginRight: 5}}/>
                                                    Password
                                                </label>
                                            </div>

                                            <div>
                                                <button style={styles.eyeBtnStyle} onClick={showPass}>
                                                    <img
                                                        src={showPassImg}
                                                        alt='show'
                                                        style={{width: '65%'}}
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                        <div style={{marginTop: 53, marginBottom: -53}}>
                                            <Alerts/>
                                        </div>

                                        <div style={{marginTop: 57}}>
                                            <input
                                                className='btn btn-block'
                                                style={styles.btnStyle_mobile}
                                                type='submit'
                                                value='Sign Up'
                                            />
                                        </div>

                                        <div style={{fontSize: 10, color: 'white',}}>
                                            Already have an account? {' '}
                                            <button
                                                onClick={changeTab}
                                                style={{
                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                    borderWidth: 0,
                                                    color: '#0bdefa',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Sign In
                                            </button>
                                        </div>
                                    </form>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                )
            }
                {os === "IOS" && !localStorage.getItem("showIOSAlert")
                    ? (
                        <div id='ios-prompt' style={showIOS}>
                            <img
                                src='/favicon.png'
                                alt='Logo'
                                style={{width: 50, margin: 10, marginBottom: 5}}
                            /><br/>
                            <b
                                style={{
                                    height: 'max-content',
                                    fontSize: 25,
                                }}
                            >Intravel Card</b>
                            <div
                                className='all-center'
                                style={{
                                    fontSize: 20,
                                    color: 'gray'
                                }}
                            >
                                Install this application on your home screen for a better experience.
                            </div>
                            <div style={{marginTop: 15, fontSize: 15}}>
                                Tap
                                <img
                                    src='/Images/Icons/AppleShare.png'
                                    style={{
                                        width: 20,
                                        margin: 0,
                                        marginLeft: 3,
                                        marginRight: 3
                                    }}
                                    alt="Add to homescreen"
                                />
                                then "Add to Home Screen"
                            </div>
                            <button
                                onClick={close}
                                id='ios-close'
                                className="btn"
                                style={{
                                    marginTop: 10,
                                    marginBottom: 0,
                                    backgroundColor: 'white',
                                    color: '#3d86ec',
                                }}
                            > Close
                            </button>
                        </div>
                    )
                    : null
                }
            </div>
        </>
    );
};

const styles = {
    btnStyle: {
        position: 'relative',
        backgroundColor: '#4b4bf9',
        color: 'white',
        marginTop: 35,
        marginBottom: 5,
        borderRadius: 20,
        fontSize: 15
    },
    btnStyle_mobile: {
        position: 'relative',
        backgroundColor: '#294956',
        color: 'white',
        marginTop: 35,
        marginBottom: 5,
        borderRadius: 20,
        fontSize: 15
    },
    eyeBtnStyle: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        width: 25,
        height: 22,
        marginTop: 5,
        marginLeft: -25,
        zIndex: 2,
        borderWidth: 0,
        color: 'gray',
        cursor: 'pointer',
    },
    iosStyle: {
        display: 'none'
    },
};

export default PopUpLogin;
