import React from 'react';
import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';

const BottomMenu = () => {
    const tabs = [
    {
        route: "/dashboard",
        icon:
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50px"
                viewBox="0 0 699.000000 560.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)"
                    fill='white'
                    stroke="none"
                    className='svg-bottomMenu'
                >
                    <path d="M2385 3799 c-605 -561 -1101 -1026 -1103 -1032 -1 -7 44 -61 101 -122 l104 -110 94 88 94 88 3 -856 2 -855 755 0 755 0 0 750 0 750 295 0 295 0 0 -750 0 -750 755 0 755 0 2 855 3 856 94 -88 94 -88 104 110 c57 61 102 115 101 122 -2 6 -487 460 -1078 1008 -591 549 -1086 1008 -1100 1021 l-26 24 -1099 -1021z m1884 -134 l710 -660 1 -847 0 -848 -445 0 -445 0 0 750 0 750 -605 0 -605 0 0 -750 0 -750 -445 0 -445 0 0 848 1 847 746 693 746 692 38 -32 c21 -18 358 -330 748 -693z"/>
                </g>
            </svg>,
        label: "Home"
    },{
        route: "/reports",
        icon:
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50px"
                viewBox="0 0 699.000000 560.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)"
                    fill='white'
                    stroke="none"
                    className='svg-bottomMenu'
                >
                    <path d="M1763 4673 c-61 -21 -128 -98 -148 -169 -13 -46 -15 -3308 -2 -3374 12 -62 77 -160 106 -160 5 0 14 -6 20 -14 7 -8 40 -19 75 -25 87 -15 2529 -15 2577 0 69 21 116 58 168 132 22 31 26 85 26 377 l0 275 -137 3 -138 3 0 -244 c0 -220 -2 -245 -18 -260 -16 -14 -130 -16 -1200 -16 -1033 1 -1184 3 -1197 16 -13 13 -15 208 -15 1578 0 992 4 1573 10 1589 l10 26 1194 0 c945 0 1197 -3 1203 -12 4 -7 10 -130 13 -273 l5 -260 125 -3 c109 -2 126 0 137 15 16 21 18 545 2 612 -9 42 -66 132 -99 158 -8 7 -36 19 -62 27 -41 14 -194 16 -1330 15 -1091 -1 -1289 -4 -1325 -16z"/>
                    <path d="M2422 3884 c-69 -48 -68 -207 0 -246 18 -10 159 -13 650 -13 617 0 627 0 654 21 50 37 67 119 37 182 -36 77 15 71 -688 72 -556 0 -633 -2 -653 -16z"/>
                    <path d="M4651 3821 c-18 -10 -61 -52 -95 -93 -33 -42 -96 -118 -138 -170 -42 -51 -112 -136 -154 -188 -68 -83 -222 -271 -415 -505 -32 -38 -139 -168 -238 -287 -99 -119 -185 -227 -191 -238 -11 -20 -22 -64 -76 -290 -14 -58 -36 -150 -49 -205 -13 -55 -31 -134 -41 -175 -9 -41 -20 -88 -25 -104 -5 -16 -9 -53 -9 -82 0 -46 4 -57 34 -88 31 -33 38 -36 92 -36 60 0 110 17 280 95 33 15 79 35 104 45 42 17 105 44 209 91 24 11 66 29 94 40 150 59 108 13 627 690 261 341 528 690 593 775 l117 154 0 59 c0 110 -58 246 -147 342 -52 56 -146 123 -208 147 -113 45 -300 57 -364 23z m252 -285 c68 -31 128 -84 157 -139 l24 -45 -27 -39 c-15 -21 -35 -49 -45 -61 l-19 -24 -62 66 c-58 60 -166 136 -194 136 -7 0 -18 4 -26 9 -12 7 -3 22 39 71 59 68 59 68 153 26z m-286 -362 c66 -28 136 -93 164 -150 l21 -44 -28 -38 c-40 -53 -395 -518 -483 -632 -40 -52 -96 -126 -124 -163 l-50 -68 -16 33 c-38 79 -181 194 -278 223 -13 3 -23 10 -23 14 0 4 26 39 58 77 31 38 75 91 97 118 22 27 63 77 90 111 164 202 407 495 433 523 35 37 47 37 139 -4z m-841 -1129 c53 -34 114 -116 114 -153 0 -13 -8 -23 -22 -27 -24 -7 -41 -14 -153 -65 -38 -18 -91 -40 -117 -51 l-47 -18 8 27 c5 15 13 50 19 77 16 73 54 228 60 243 8 21 80 4 138 -33z"/>
                    <path d="M2444 3280 c-12 -4 -31 -21 -44 -37 -19 -24 -21 -37 -18 -96 3 -57 8 -71 31 -95 l27 -27 631 0 631 0 34 39 c29 33 34 47 34 88 0 27 -7 63 -16 79 -30 60 -25 59 -686 58 -332 0 -613 -4 -624 -9z"/>
                    <path d="M2416 2619 c-32 -37 -40 -69 -32 -117 7 -48 34 -83 77 -98 53 -20 568 -19 615 1 57 23 73 51 74 122 0 56 -3 64 -31 92 l-31 31 -323 0 -323 0 -26 -31z"/>
                </g>
            </svg>,
        label: "Reports"
    },{
        route: "/packages",
        icon:
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50px"
                viewBox="0 0 699.000000 560.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)"
                    fill='white'
                    stroke="none"
                    className='svg-bottomMenu'
                >
                    <path xmlns="http://www.w3.org/2000/svg" d="M2948 4382 l-177 -197 -1 -197 0 -197 -392 -3 -393 -3 -56 -26 c-106 -50 -172 -129 -210 -250 -17 -55 -19 -96 -19 -419 0 -336 1 -362 21 -426 36 -115 103 -193 209 -243 l55 -26 573 -3 572 -3 0 201 0 200 355 0 355 0 2 -197 3 -198 570 0 570 0 67 32 c111 52 179 138 213 270 19 72 22 690 4 775 -27 124 -112 237 -217 286 l-57 27 -397 3 -398 3 0 198 0 199 -176 196 -176 196 -361 0 -362 0 -177 -198z m892 -397 l0 -195 -355 0 -355 0 0 195 0 195 355 0 355 0 0 -195z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M1880 1765 c0 -412 1 -439 20 -496 38 -113 105 -189 209 -238 l56 -26 1325 0 1325 0 55 26 c72 35 128 84 167 150 60 101 63 128 63 596 l0 423 -630 0 -630 0 0 -100 0 -100 -355 0 -355 0 0 100 0 100 -625 0 -625 0 0 -435z"/>
                </g>
            </svg>,
        label: "Services"
    },{
        route: "/converter",
        icon:
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="50px"
                viewBox="0 0 699.000000 560.000000"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,560.000000) scale(0.100000,-0.100000)"
                    fill='white'
                    stroke="none"
                    className='svg-bottomMenu'
                >
                    <path xmlns="http://www.w3.org/2000/svg" d="M3980 4694 c-249 -53 -465 -204 -597 -416 l-36 -58 -708 0 c-696 0 -709 0 -729 -20 -20 -20 -20 -33 -20 -1640 0 -1607 0 -1620 20 -1640 20 -20 33 -20 1398 -20 1323 0 1380 1 1395 18 16 17 17 106 17 1071 l0 1052 83 82 c178 174 270 373 284 613 27 460 -283 862 -740 959 -86 19 -280 18 -367 -1z m319 -125 c194 -31 393 -154 505 -313 108 -152 150 -282 150 -466 1 -173 -35 -297 -123 -432 -114 -174 -286 -295 -489 -345 -89 -21 -265 -21 -354 0 -547 133 -789 755 -477 1221 112 168 318 303 512 335 74 12 201 13 276 0z m-1012 -493 c-3 -8 -11 -33 -17 -55 l-11 -41 -532 0 c-409 0 -538 -3 -557 -13 l-25 -13 -3 -403 c-2 -365 -1 -406 14 -423 16 -17 49 -18 699 -18 l682 0 60 -48 c85 -69 227 -139 341 -169 183 -48 406 -32 581 42 33 14 63 25 66 25 3 0 5 -434 5 -965 l0 -965 -1285 0 -1285 0 0 1530 0 1530 636 0 c549 0 635 -2 631 -14z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4069 4459 c-306 -45 -555 -305 -576 -602 l-6 -78 64 3 64 3 7 63 c14 130 64 231 162 327 97 97 182 136 344 160 l42 7 0 64 c0 72 6 69 -101 53z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4100 4161 c0 -59 0 -59 -32 -66 -128 -26 -190 -175 -119 -286 40 -64 83 -82 206 -89 55 -3 106 -9 113 -13 20 -13 15 -75 -7 -87 -11 -6 -89 -10 -180 -10 l-161 0 0 -65 0 -65 90 0 90 0 0 -65 0 -65 65 0 65 0 0 64 0 63 40 7 c47 7 107 56 126 103 19 45 17 121 -4 162 -9 19 -34 48 -55 65 -37 29 -45 31 -146 36 -119 6 -141 15 -141 61 0 51 26 59 202 59 l158 0 0 65 0 65 -90 0 -90 0 0 60 0 60 -65 0 -65 0 0 -59z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4710 3748 c-1 -166 -131 -365 -290 -445 -73 -37 -166 -63 -222 -63 l-38 0 0 -65 0 -65 41 0 c70 0 181 30 269 73 110 53 237 176 292 282 45 86 78 202 78 272 l0 53 -65 0 -65 0 0 -42z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M2162 2854 c-22 -15 -22 -19 -22 -234 0 -215 0 -219 22 -234 19 -13 58 -16 223 -16 165 0 204 3 223 16 22 15 22 19 22 234 0 215 0 219 -22 234 -19 13 -58 16 -223 16 -165 0 -204 -3 -223 -16z m338 -234 l0 -120 -115 0 -115 0 0 120 0 120 115 0 115 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M2770 2850 c-19 -19 -20 -33 -20 -230 0 -273 -23 -250 247 -250 273 0 253 -20 253 248 0 186 -1 201 -21 226 l-20 26 -210 0 c-196 0 -210 -1 -229 -20z m350 -230 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M3381 2844 c-20 -25 -21 -40 -21 -226 0 -268 -20 -248 255 -248 192 0 206 1 225 20 19 19 20 33 20 230 0 197 -1 211 -20 230 -19 19 -33 20 -229 20 l-210 0 -20 -26z m349 -224 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M2180 2253 c-37 -14 -40 -32 -40 -248 0 -193 2 -213 18 -228 16 -15 47 -17 229 -17 191 0 211 2 226 18 15 16 17 47 17 228 0 166 -3 214 -14 230 -13 18 -29 19 -218 21 -112 1 -210 -1 -218 -4z m320 -243 l0 -120 -115 0 -115 0 0 120 0 120 115 0 115 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M2776 2239 l-26 -20 0 -208 c0 -171 3 -210 16 -229 15 -22 19 -22 230 -22 201 0 215 1 234 20 19 19 20 33 20 225 0 275 20 255 -248 255 -186 0 -201 -1 -226 -21z m344 -229 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M3403 2250 c-39 -16 -43 -41 -43 -240 0 -186 5 -223 34 -242 6 -4 106 -8 222 -8 192 0 212 2 227 18 15 16 17 47 17 230 l0 211 -26 20 c-25 20 -39 21 -218 20 -105 0 -201 -4 -213 -9z m327 -240 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M4015 2248 c-11 -6 -23 -16 -27 -22 -4 -6 -8 -244 -8 -528 0 -560 -2 -540 54 -554 12 -3 109 -4 214 -2 168 3 194 5 209 21 17 17 18 52 18 537 0 485 -1 520 -18 537 -16 16 -41 18 -220 21 -140 1 -208 -2 -222 -10z m335 -548 l0 -430 -120 0 -120 0 0 430 0 430 120 0 120 0 0 -430z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M2157 1622 c-15 -16 -17 -48 -17 -234 0 -188 2 -217 17 -229 12 -11 63 -14 230 -14 201 0 216 1 229 19 11 16 14 64 14 231 0 193 -2 213 -18 228 -16 15 -47 17 -229 17 -191 0 -211 -2 -226 -18z m343 -232 l0 -120 -115 0 -115 0 0 120 0 120 115 0 115 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M2766 1618 c-13 -19 -16 -58 -16 -229 l0 -208 26 -20 c25 -20 40 -21 226 -21 268 0 248 -20 248 255 0 192 -1 206 -20 225 -19 19 -33 20 -234 20 -211 0 -215 0 -230 -22z m354 -228 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"/>
                    <path xmlns="http://www.w3.org/2000/svg" d="M3394 1632 c-29 -19 -34 -56 -34 -245 0 -262 -19 -241 231 -245 106 -2 203 -1 215 2 52 13 54 21 54 250 0 194 -2 214 -18 229 -16 15 -47 17 -228 17 -115 0 -214 -4 -220 -8z m336 -242 l0 -120 -120 0 -120 0 0 120 0 120 120 0 120 0 0 -120z"/>
                </g>
            </svg>,
        label: "Converter"
    }];

    return (
        <div style={styles.menuStyle}>
            <nav role="navigation" style={{width: '100%', marginTop: 12}}>
                <Nav>
                    <div className='grid-4-dashboard all-center' style={{gridGap: 0}}>
                        {
                            tabs.map((tab, index) =>(
                                <NavItem key={`tab-${index}`} style={{width: '100%'}}>
                                    <NavLink to={tab.route} activeClassName="active">
                                        {tab.icon}
                                        <div
                                            className='all-center'
                                            style={{width: 'max-content', color: 'white', fontSize: 12}}
                                        >
                                            {tab.label}
                                        </div>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </div>
                </Nav>
            </nav>
        </div>
    )
};

const styles = {
    menuStyle: {
        position: 'fixed',
        width: '100%',
        height: 90,
        bottom: 0,
        backgroundColor: '#003e57',
        borderRadius: 20,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        zIndex: 2,
    },
    titleInCardStyle_mobile: {
        position: 'relative',
        top: -6,
        margin: 'auto',
        color: 'white',
        fontSize: 10,
    },
};

export default BottomMenu;
