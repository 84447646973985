import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import BottomMenu from "../layout/BottomMenu";
import Card from '../layout/Card';
import SpinnerModal from '../layout/SpinnerModal';
import DialogBox from '../layout/DialogBox';
import '../../Styles/NewStyles.css';
import '../../App.css';

const MyCard = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, username, currency } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        //width: width - 220,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(false);

    const [firstName, setFirstName] = useState('No card issued to you!');
    const [lastName, setLastName] = useState('');
    const [balance, setBalance] = useState(0);
    const [cardNum, setCardNum] = useState('---- ---- ---- ----');
    const [haveInfo, setHaveInfo] = useState(false);

    const [dollarPrice, setDollarPrice] = useState(1);
    const [euroPrice, setEuroPrice] = useState(1);
    const [lastUpdate, setLastUpdate] = useState('');

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const getCurrencies = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/curr/CurrenciesList/', config);
            setDollarPrice(res.data[0].dollar);
            setEuroPrice(res.data[0].euro);
            setLastUpdate(res.data[0].updateDateTime.slice(0,10));
            setShowDialogBox(false);
            //setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
            setDollarPrice(1);
            setEuroPrice(1);
        }
    };

    const getInfo = async () => {
        setLoad(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        try {
            //django server - name
            const res1 = await axios.get(`https://admin.intravelcard.com/api/req/TouristsList-Email/${username}`, config1);

            if (res1.data.length !== 0 && res1.data[res1.data.length - 1].status !== 1) {
                setFirstName(res1.data[res1.data.length - 1].firstName);
                setLastName(res1.data[res1.data.length - 1].lastName);
                const pan = res1.data[res1.data.length - 1].pan;
                setCardNum(pan.toString());

                //bank token
                const res2 = await axios.get('https://admin.intravelcard.com/getBankInfo/getToken/', config1);
                const bankToken = res2.data; //access_token

                //bank service
                //pan & balance
                const params4 = `Pan=${Number(pan)}`;
                const req4 = { bankToken: `Bearer ${bankToken}` , params: params4 };
                const res4 = await axios.post('https://admin.intravelcard.com/getBankInfo/getBalance/', req4, config1);
                setBalance(res4.data.data.balance);
            } else {
                setFirstName('No card issued to you!');
                setLastName('');
                setCardNum('---- ---- ---- ----');
                setBalance(0);
            }
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setFirstName('No card issued to you!');
            setLastName('');
            setCardNum('---- ---- ---- ----');
            setBalance(0);
            setLoad(true);
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                //width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                //width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (!haveInfo && isAuthenticated) {
            getCurrencies();
            getInfo();
            setHaveInfo(true);
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, haveInfo, isAuthenticated]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={2}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='myCard'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            <Card
                                width={width}
                                firstName={firstName}
                                lastName={lastName}
                                balance={balance}
                                cardNum={cardNum}
                            />
                            <div className='grid-2' style={{gridGap: '1.5rem'}}>
                                <div style={styles.smallCadreStyle}>
                                    <strong style={{fontSize: 20}}>Refund</strong>
                                    <div style={styles.infoStyle}>
                                        Specify the approximate or exact amount of Refund and your desired date.
                                    </div>
                                    <br/>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                        position: 'absolute',
                                        bottom: 45,
                                        right: '50%',
                                        marginRight: 40
                                    }}>
                                        <Link
                                            to={{
                                                pathname: '/refund',
                                                state: {firstName, lastName, balance, cardNum, dollarPrice, euroPrice, lastUpdate}
                                            }}
                                            className='btn'
                                            style={styles.btnStyle}
                                        >
                                            Refund
                                        </Link>
                                    </div>
                                </div>
                                <div style={styles.smallCadreStyle}>
                                    <strong style={{fontSize: 20}}>Lost or Stolen Card</strong>
                                    <div style={styles.infoStyle}>
                                        If your card is lost or stolen, fill out this from to lock your card and request a new one.
                                    </div>
                                    <br/>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                        marginRight: -5
                                    }}>
                                        <Link
                                            to={{
                                                pathname: '/lost',
                                                state: {firstName, lastName, balance, cardNum, dollarPrice, euroPrice}
                                            }}
                                            className='btn'
                                            style={styles.btnStyle}
                                        >
                                            Request
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 20,
                            paddingBottom: 110,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <Card
                                width={width}
                                firstName={firstName}
                                lastName={lastName}
                                balance={balance}
                                cardNum={cardNum}
                                dollarPrice={dollarPrice}
                                euroPrice={euroPrice}
                                currency={currency}
                            />
                            <div className='grid-2-myCard-mobile' style={{width: 300, margin:'auto'}}>
                                <Link
                                    to={{
                                        pathname: '/refund',
                                        state: {firstName, lastName, balance, cardNum, dollarPrice, euroPrice, lastUpdate}
                                    }}
                                    style={styles.smallCardStyle_mobile}>
                                    <img
                                        src='/Images/Icons/refund_mobile.png'
                                        alt='Refund'
                                        style={styles.iconStyle_mobile}
                                    />
                                    <div className='all-center' style={{color: '#f4ac50'}}>Refund</div>
                                </Link>
                                <Link
                                    to={{
                                        pathname: '/lost',
                                        state: {firstName, lastName, balance, cardNum, dollarPrice, euroPrice}
                                    }}
                                    style={styles.smallCardStyle_mobile}>
                                    <img
                                        src='/Images/Icons/lost_mobile2.png'
                                        alt='Lost'
                                        style={styles.iconStyle_mobile}
                                    />
                                    <div className='all-center' style={{color: '#f4ac50'}}>Lost</div>
                                </Link>
                            </div>
                        </div>
                        <BottomMenu />
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 0,
    },
    smallCadreStyle: {
        backgroundColor: 'white',
        width: '100%',
        padding: 20,
        paddingLeft: 35,
        paddingRight: 35,
        borderRadius: 10,
    },
    infoStyle: {
        marginTop: 10,
        fontSize: 14,
        textAlign: 'justify',
        color: '#626465',
    },
    btnStyle: {
        backgroundColor: 'white',
        color: 'orange',
        fontSize: 14,
        height: 32,
        margin: 0,
        marginTop: 10,
        padding: 20,
        paddingTop: 2.5,
        borderStyle: 'solid',
        borderColor: 'orange',
        borderWidth: 1.5,
        borderRadius: 15,
    },
    smallCardStyle_mobile: {
        display: 'grid',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#003e57',
        paddingTop: 5,
        paddingBottom: 15,
        borderRadius: 20,
    },
    iconStyle_mobile: {
        width: 100,
        margin: 'auto',
        borderRadius: 10,
    },
};

export default MyCard;
