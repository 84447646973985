import React, {Fragment, useContext, useEffect, useState} from 'react';
import axios from "axios";
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import Header from '../layout/Header';
import PopUpLogin from '../layout/PopUpLogin';
import Package from '../layout/Package';
import SpinnerModal from "../layout/SpinnerModal";
import DialogBox from "../layout/DialogBox";
import BottomMenu from "../layout/BottomMenu";
import '../../Styles/NewStyles.css';
import '../../App.css';

const Packages = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220 - 22,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(null);
    const [info, setInfo] = useState([
        {off: 0, final_price: 0},
        {off: 0, final_price: 0},
        {off: 0, final_price: 0}
    ]);
    const [familyData, setFamilyData] = useState({
        cardPrice: 0,
        SIMCardPrice: 0,
        twoCards: {off: 0, final_price: 0},
        threeCards: {off: 0, final_price: 0},
        fourCards: {off: 0, final_price: 0},
        fiveAndUpCards: {off: 0, final_price: 0},
        oneSIMCard: {off: 0, final_price: 0},
        twoSIMCards: {off: 0, final_price: 0},
        threeAndUpSIMCards: {off: 0, final_price: 0},
    });
    const [haveInfo, setHaveInfo] = useState(false);

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const pack1 = () => {
        return (
            <Package
                label={`${info[1].off}% OFF`}
                price={`${info[1].final_price}`}
                img='/Images/Packages/pack1.png'
                title='IntravelCard'
                info={[
                    <>
                        <i className="fa fa-check" style={styles.iconStyle1} />
                        Withdraw cash from ATMs
                        <br/>
                    </>,
                    <>
                        <i className="fa fa-check" style={styles.iconStyle1} />
                        Shop Online
                        <br/>
                    </>,
                    <>
                        <i className="fa fa-check" style={styles.iconStyle1} />
                        Pay at local shops
                        <br/>
                    </>,
                ]}
                onClick='/Card-Order'
                familyData={familyData}
            />
        )
    };

    const pack2 = () => {
        return (
            <Package
                label={`${info[0].off}% OFF`}
                price={`${info[0].final_price}`}
                img='/Images/Packages/pack2.png'
                title='IntravelCard + SimCard'
                info={[
                    <>
                        <i className="fa fa-check" style={styles.iconStyle2} />
                        Irancell SIM Card
                        <br/>
                    </>,
                    <>
                        <i className="fa fa-check" style={styles.iconStyle2} />
                        1.5 GB Data
                        <br/>
                    </>,
                    <>
                        <i className="fa fa-check" style={styles.iconStyle2} />
                        40000 IRR initial airtime
                        <br/>
                    </>,
                    <>
                        <i className="fa fa-check" style={styles.iconStyle2} />
                        IntravelCard
                        <br/>
                    </>,
                ]}
                onClick='/Card&SIMCard-Order'
                familyData={familyData}
            />
        )
    };

    const pack3 = () => {
        return (
            <Package
                label={`${info[2].off}% OFF`}
                price={`${info[2].final_price}`}
                img='/Images/Packages/pack3.png'
                title='SimCard'
                info={[
                    <>
                        <i className="fa fa-check" style={styles.iconStyle1} />
                        Irancell SIM Card
                        <br/>
                    </>,
                    <>
                        <i className="fa fa-check" style={styles.iconStyle1} />
                        1.5 GB Data
                        <br/>
                    </>,
                ]}
                onClick='/SIMCard-Order'
                familyData={familyData}
            />
        )
    };

    const pack4 = () => {
        return (
            <Package
                label={`Up to ${familyData.fiveAndUpCards.off}% OFF`}
                price='Flexible'
                img='/Images/Packages/pack4.png'
                title='Family Package'
                info={['-']}
                onClick='/Family-Package-Order'
                familyData={familyData}
            />
        )
    };

    const getPackInfo = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/packages/PackInfoList/', config);
            setInfo(res.data);
            setFamilyData({ ...familyData,
                cardPrice: res.data[1].final_price,
                SIMCardPrice: res.data[2].final_price,
                twoCards: res.data[3],
                threeCards: res.data[4],
                fourCards: res.data[5],
                fiveAndUpCards: res.data[6],
                oneSIMCard: res.data[7],
                twoSIMCards: res.data[8],
                threeAndUpSIMCards: res.data[9],
            });
            setLoad(true);
        } catch (e) {
            setLoad(true);
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65 - 22,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220 - 22,
                marginLeft: 220,
            }), 200);
        }
        if (!haveInfo && isAuthenticated) {
            getPackInfo();
            setHaveInfo(true)
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, haveInfo, isAuthenticated, width]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={1}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='packages'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            {width >= 1100
                                ? (
                                    <>
                                        <div className='grid-3'>
                                            {pack1()}
                                            {pack2()}
                                            {pack3()}
                                        </div>
                                        <div className='grid-3' style={{marginTop: 40}}>
                                            {pack4()}
                                        </div>
                                    </>
                                )
                                : (
                                    width >= 850
                                        ? (
                                            <>
                                                <div className='grid-2'>
                                                    {pack1()}
                                                    {pack2()}
                                                </div>
                                                <div className='grid-2' style={{marginTop: 40}}>
                                                    {pack3()}
                                                    {pack4()}
                                                </div>
                                            </>
                                        )
                                        : (
                                            <>
                                                <div>{pack1()}</div>
                                                <div style={{marginTop: 40}}>{pack2()}</div>
                                                <div style={{marginTop: 40}}>{pack3()}</div>
                                                <div style={{marginTop: 40}}>{pack4()}</div>
                                            </>
                                        )
                                )
                            }

                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            //backgroundColor: '#003e57',
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 0,
                            //paddingTop: 10,
                            paddingTop: 40,
                            paddingBottom: 100,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <div>{pack1()}</div>
                            <div style={{marginTop: 40}}>{pack2()}</div>
                            <div style={{marginTop: 40}}>{pack3()}</div>
                            <div style={{marginTop: 40}}>{pack4()}</div>
                        </div>
                        <BottomMenu/>
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        backgroundColor: 'white',
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 30,
        paddingTop: 45,
        paddingBottom: 45,
        borderRadius: 10,
        gridGap: 0,
        zIndex: 1
    },
    iconStyle1: {
        marginRight: 4,
        fontSize: 12,
        color: 'orange'
    },
    iconStyle2: {
        marginRight: 4,
        fontSize: 12,
        color: '#0bdefa'
    },
};

export default Packages;
