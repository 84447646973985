import React, {useState} from 'react';
import PropTypes from 'prop-types';
import history from '../history';
import '../../Styles/DialogBox.css';
import '../../App.css';

const DialogBox = ({ data, type }) => {
    const [showDialogBox, setShowDialogBox] = useState(true);

    const onClick = (e) => {
        e.preventDefault();
        if (data === 'done' && type === 1) { // 1 -> form
            history.push('/orders')
        } else if (data === 'done' && type === 3) { // 3 -> top up
            setShowDialogBox(false);
            history.go(-1)
        } else {
            setShowDialogBox(false);
        }
    };

    const showComponent = () => {
        if (showDialogBox) {
            return(
                <div className='popup all-center' style={{zIndex: 10}}>
                    <div className='popup_inner'
                         style={
                             window.innerWidth > 767
                                 ? {width: 280, height: 195}
                                 : {width: 250, height: 180}
                         }
                    >
                        {data === 'done'
                            ? (
                                <>
                                    <img
                                        src='/Images/Icons/check.gif'
                                        alt='check'
                                        style={{width: 50, marginTop: 22, borderRadius: '50%'}}
                                    />
                                    <div style={styles.msgStyle}>
                                        <label style={{color: 'green'}}>
                                            Your request has been successfully submitted!
                                        </label>
                                    </div>
                                    <button
                                        className='btn all-center'
                                        style={window.innerWidth > 767 ? styles.btnStyle : styles.btnStyle_mobile}
                                        onClick={onClick}
                                    > Ok </button>
                                </>
                            )
                            : (
                                <>
                                    <img
                                        src='/Images/Icons/close.gif'
                                        alt='close'
                                        style={{width: 50, marginTop: 22, borderRadius: '50%'}}
                                    />
                                    <div style={styles.msgStyle}>
                                        <label style={{color: 'red'}}>
                                            {data}
                                            <br/>
                                            <label style={{fontSize: 13}}>(Try Again)</label>
                                        </label>

                                    </div>
                                    <button
                                        className='btn all-center'
                                        style={window.innerWidth > 767 ? styles.btnStyle : styles.btnStyle_mobile}
                                        onClick={onClick}
                                    > Ok </button>
                                </>
                            )
                        }
                    </div>
                </div>
            )
        }
    };

    return (
        <>
            {showComponent()}
        </>
    );
};

DialogBox.propTypes = {
    data: PropTypes.string.isRequired,
    type : PropTypes.number
};
DialogBox.defaultProps = {
    type: 1
};

const styles = {
    msgStyle: {
        marginTop: 5,
        marginBottom: 3,
        fontSize: window.innerWidth > 767 ? 15 : 14,
    },
    btnStyle: {
        backgroundColor: '#2c3c85',
        margin: 'auto',
        padding: 0,
        borderRadius: 3,
        color: 'white',

        height: 30,
        width: 70,
        marginTop: 15,
        marginBottom: 15,
        fontSize: 15,
    },
    btnStyle_mobile: {
        backgroundColor: '#2c3c85',
        margin: 'auto',
        padding: 0,
        borderRadius: 3,
        color: 'white',

        height: 25,
        width: 60,
        marginTop: 12,
        marginBottom: 8,
        fontSize: 14,
    },
};

export default DialogBox;
