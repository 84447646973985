import React, {useContext, useEffect, useState} from 'react';
import AuthContext from '../../context/auth/authContext';
import PropTypes from 'prop-types';
import Sidebar from '../layout/Sidebar';
import MobileSidebar from "./MobileSidebar";
import '../../Styles/NewStyles.css';
import '../../App.css';

const Header = ({ defaultSelected }) => {
    const authContext = useContext(AuthContext);
    const { first_name, last_name, profile_pic } = authContext;
    const [name, setName] = useState('');
    const [margin, setMargin] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        if (width > 940) {
            setMargin('5.9%')
        } else {
            setMargin('2%')
        }
        if (first_name && last_name) {
            setName(first_name + ' ' + last_name);
        } else {
            setName('');
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[width, first_name, last_name]);

    const showProfileImage = () => {
        if (!profile_pic || profile_pic === '') {
            return <img
                src='/Images/default user.jpg'
                alt='Profile'
                className='img round-img profileImg-in-header'
            />
        } else {
            return <img
                src={`data:image/jpeg;base64,${profile_pic}`}
                alt='profile'
                className='img round-img profileImg-in-header'
            />
        }
    };

    const showName = () => {
        if (name && name !== ' ') {
            return <div style={{alignSelf: 'center', fontSize: 14, marginLeft: 10}}>{name}</div>
        } else {
            return <div style={{alignSelf: 'center', fontSize: 14, marginLeft: 10}}>welcome!</div>
        }
    };

    const showName_mobile = () => {
        if (name && name !== ' ') {
            return <div style={{alignSelf: 'center', fontSize: 16, marginRight: 5}}>{name}</div>
        } else {
            return <div style={{alignSelf: 'center', fontSize: 16, marginRight: 5}}>welcome!</div>
        }
    };

    return (
        <>
            {width > 767
                ? (
                    <div style={{position: 'fixed', zIndex: 2}}>
                        <Sidebar defaultSelected={defaultSelected} width={width} height={height}/>
                        <div style={{
                            display: 'flex',
                            backgroundColor: '#003e56',
                            //width: window.innerWidth,
                            width: width,
                            height: 39,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginLeft: margin,
                                    marginRight: margin,
                                    color: 'white',
                                }}>
                                <div className='grid-3' style={{fontSize: 14}}>
                                    <div>
                                        <i className="fa fa-phone" style={{marginRight: 10}}/>
                                        +98 21 222 70556
                                    </div>
                                    <div>
                                        <i className="fa fa-phone" style={{marginRight: 10}}/>
                                        +1 (646) 349 9167
                                    </div>
                                    <div>
                                        <i className="fa fa-envelope-o" style={{marginRight: 10}}/>
                                        info@intravelcard.com
                                    </div>
                                </div>
                                <div
                                    className='grid-5'
                                    style={{
                                        position: 'absolute',
                                        right: -5,
                                        marginRight: margin,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        gridGap: 20,
                                        fontSize: 15,
                                    }}>
                                    <div style={{fontSize: 15.5}}>Follow us:</div>
                                    <a
                                        href='https://www.instagram.com/intravelcard/'
                                        title='Instagram'
                                        target='_blank'
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src='/Images/Icons/instagram.svg'
                                            alt='Instagram'
                                            style={{display: 'flex', width: 16}}
                                        />
                                    </a>
                                    <a
                                        href='https://twitter.com/intravelcard?s=11'
                                        title='Twitter'
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        style={{color: 'white'}}
                                    >
                                        <i className="fa fa-twitter" />
                                    </a>
                                    <a
                                        href='https://www.pinterest.com/intravelcard/'
                                        title='Pinterest'
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        style={{color: 'white'}}
                                    >
                                        <i className="fa fa-pinterest" />
                                    </a>
                                    <a
                                        href='https://wa.me/00989370722346'
                                        title='Whatsapp'
                                        target='_blank'
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src='/Images/Icons/whatsapp.svg'
                                            alt='Whatsapp'
                                            style={{display: 'flex', width: 16}}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className='shadow-header'
                            style={{
                                display: 'flex',
                                backgroundColor: 'white',
                                //width: window.innerWidth,
                                width: width,
                                height: 96,
                                paddingTop: 20,
                                paddingBottom: 20,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    marginLeft: margin,
                                    marginRight: margin,
                                    color: 'white',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                }}>
                                <div className='grid-6 all-center' style={{gridGap: 0}}>
                                    <a href='https://intravelcard.com/' target='_blank' rel="noopener noreferrer">
                                        <img
                                            src='/Images/LOGO.svg'
                                            alt='Logo'
                                            style={{width: 87, marginRight: 6}}
                                        />
                                    </a>
                                    <a href='https://intravelcard.com/' target='_blank' rel="noopener noreferrer" className='top-menu-item'>
                                        Home
                                    </a>
                                    <a href='/packages' target='_blank' rel="noopener noreferrer" className='top-menu-item'>
                                        Services
                                    </a>
                                    <a href='https://intravelcard.com/gallery/' target='_blank' rel="noopener noreferrer" className='top-menu-item'>
                                        Gallery
                                    </a>
                                    <a href='https://intravelcard.com/about-us/' target='_blank' rel="noopener noreferrer" className='top-menu-item'>
                                        About Us
                                    </a>
                                    <a href='https://intravelcard.com/faq/' target='_blank' rel="noopener noreferrer" className='top-menu-item'>
                                        FAQs
                                    </a>
                                </div>
                                <div
                                    className='grid-2'
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        marginRight: margin,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        gridGap: 22,
                                        fontSize: 15,
                                    }}>
                                    <a
                                        href='/packages'
                                        className='btn text-center order-btn-in-header'
                                        style={{
                                            backgroundColor: '#f4ac50',
                                            width: 110,
                                            padding: 0,
                                            paddingTop: 9,
                                            paddingBottom: 9,
                                            borderRadius: 5,
                                            color: 'white',
                                        }}>
                                        Order Now
                                    </a>
                                    <a
                                        href='/dashboard'
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            color: 'black',
                                        }}>
                                        {showProfileImage()}
                                        {width > 1030 ? showName() : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#003e57',
                        //width: window.innerWidth,
                        width: width,
                        height: 80,
                        padding: 20,
                        color: 'white',
                    }}>
                        <MobileSidebar profile_pic={profile_pic}/>
                        <a
                            href='/dashboard'
                            style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            right: 0,
                            marginRight: 15,
                            color: 'white',
                        }}>
                            {showName_mobile()}
                            {showProfileImage()}
                        </a>
                    </div>
                )
            }
        </>
    )
};

Header.propTypes = {
    defaultSelected: PropTypes.string.isRequired,
};

export default Header;
