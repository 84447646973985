import {SET_TOGGLE} from '../types';

export default (state, action) => {
    switch (action.type) {
        case SET_TOGGLE:
            return action.payload;
        default:
            return state;
    }
};
