import React, {useReducer} from 'react';
import axios from 'axios';
import AuthContext from './authContext';
import authReducer from './authReducer';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    CHANGE_PASS,
    LOAD_PROFILE,
    LOAD_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../types';

const AuthState = props => {
    const initialState = {
        id: localStorage.getItem('id'),
        username: localStorage.getItem('username'),
        first_name: localStorage.getItem('first_name'),
        last_name: localStorage.getItem('last_name'),
        token: localStorage.getItem('token'),
        isAuthenticated: localStorage.getItem('isAuthenticated'),
        error: null,

        phone: localStorage.getItem('phone'),
        iranian_phone_number: localStorage.getItem('iranian_phone_number'),
        profile_pic: localStorage.getItem('profile_pic'),
        currency: localStorage.getItem('currency'),
    };
    const [state, dispatch] = useReducer(authReducer, initialState);

    //Register
    const register = async formData => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await axios.post('https://admin.intravelcard.com/api/auth/register/', formData, config);
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
                // {'id':#,
                // 'username':'',
                // 'first_name':'',
                // 'last_name':'',
                // 'token':''}
            });
            await loadProfile();
            //history.push('/packages');
            // {'phone':'',
            // 'iranian_phone_number':'',
            // 'profile_pic':'',
            // 'currency':#}
            //window.location.reload();
        } catch (err) {
            dispatch({
                type: REGISTER_FAIL,
                payload: 'User already exists!'
            });
        }
    };

    //Login
    const login = async formData => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            const res = await axios.post('https://admin.intravelcard.com/api/auth/login/', formData, config);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
                // {'id':#,
                // 'username':'',
                // 'first_name':'',
                // 'last_name':'',
                // 'token':''}
            });
            await loadProfile();
            //history.push('/packages');
            // {'phone':'',
            // 'iranian_phone_number':'',
            // 'profile_pic':'',
            // 'currency':#}
            //window.location.reload();
            //history.go(0);
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: 'Invalid credentials!'
            });
        }
    };

    //Load Profile
    const loadProfile = async () => {
        const username = localStorage.getItem('username');
        const config = {
            headers: {
                'Authorization' : 'token ' + localStorage.getItem('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get(`https://admin.intravelcard.com/api/user_profile/${username}`, config);
            dispatch({
                type: LOAD_PROFILE,
                payload: res.data
                // {'phone':'',
                // 'iranian_phone_number':'',
                // 'profile_pic':'',
                // 'currency':#}
            });
        } catch (err) {
            dispatch({
                type: LOAD_FAIL,
                payload: 'Profile load error!'
            });
        }
    };

    //Logout
    const logout = async () => {
        /*const token = localStorage.getItem('token');
        const config = {
            headers: {
                Authorization: `Token ${token}`
            }
        };*/
        try {
            //await axios.get('https://admin.intravelcard.com/api/auth/logout/', config);
            dispatch({ type: LOGOUT });
        } catch (err) {
            dispatch({
                type: LOGIN_FAIL,
                payload: 'Error!'
            });
        }
    };

    //Clear Errors
    const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

    //change Pass
    const changePass = async formData => {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        try {
            await axios.post('https://admin.intravelcard.com/api/auth/changePass/', formData, config);
            dispatch({
                type: CHANGE_PASS,
                payload: 'Done Successfully!'
            });
        } catch (err) {
            dispatch({
                type: CHANGE_PASS,
                payload: 'The Current Password Is Incorrect!'
            });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                id: state.id,
                username: state.username,
                first_name: state.first_name,
                last_name: state.last_name,
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                error: state.error,
                phone: state.phone,
                iranian_phone_number: state.iranian_phone_number,
                profile_pic: state.profile_pic,
                currency: state.currency,
                register,
                login,
                loadProfile,
                logout,
                changePass,
                clearErrors,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
};

export default AuthState;
