import React, {Fragment, useState, useEffect, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import SpinnerModal from '../layout/SpinnerModal';
import AlertsSettings from '../layout/AlertsSettings';
import history from '../history';
import '../../Styles/NewStyles.css';
import '../../Styles/NewStyles.css';
import '../../App.css';

const Settings = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, first_name, last_name, username, error, changePass, clearErrors } = authContext; //+ phone, currency

    const alertContext = useContext(AlertContext);
    const { setAlert } = alertContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(null);

    const [label, setLabel] = useState('');
    const [labelDone, setLabelDone] = useState('');

    //const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);

    const [rightImg, setRightImg] = useState('/Images/Settings/profile.jpg');

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    //Profile Img
    //const [profile, setProfile] = useState('');
    const [refresh, setRefresh] = useState(false);

    const [showAlert0, setShowAlert0] = useState(false);

    const showProfileImage = () => {
        if (!localStorage.getItem('profile_pic')) {
            return <img
                src='/Images/default user.jpg'
                alt='profile'
                className='img'
                style={styles.profileStyle}
            />
        } else {
            return <img
                src={`data:image/jpeg;base64,${localStorage.getItem('profile_pic')}`}
                alt='profile'
                className='img'
                style={styles.profileStyle}
            />
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadPhoto  = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);

        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const data = { profile_pic: base64.split('base64,')[1] };
            await axios.put(`https://admin.intravelcard.com/api/auth/updateProfile/${username}`, data, config);
            localStorage.setItem('profile_pic', base64.split('base64,')[1]);
            setRefresh(!refresh);
            setLoad(true);
            setShowAlert0(true);
            setAlert('Done Successfully!', 'green');
        } catch (e) {
            setLoad(true);
            setShowAlert0(true);
            setAlert('Server Error!', 'red');
        }
    };

    //personal
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [personalUpdate, setPersonalUpdate] = useState(false);
    const [personalBtnText, setPersonalBtnText] = useState('Update Information');
    const [form, setForm] = useState({
        firstName: first_name ? first_name : '',
        lastName: last_name ? last_name : '',
        email: username,
        phone: authContext.phone !== '' ? authContext.phone : '',
        currency: authContext.currency ? authContext.currency : '1',
    });

    const {firstName, lastName, email, phone, currency} = form;

    const onChange = e => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const radioChangeHandler = (event) => {
        setForm( {...form,  currency: event.target.value });
    };

    const onChangeProfile = (e) => {
        e.preventDefault();
        setShowFileUpload(!showFileUpload);
    };

    const onPersonalSubmit = async (e) => {
        e.preventDefault();
        if (!personalUpdate) {
            setPersonalUpdate(true);
            setPersonalBtnText('Save Changes');
        } else {
            setPersonalUpdate(false);
            setPersonalBtnText('Update Information');
            setLoad(false);
            setShowAlert0(false);
            //setShowAlert1(true);
            //setShowAlert2(false);
            //setShowAlert3(false);
            const config = {
                headers: {
                    'Authorization' : 'token ' + token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            };

            if (firstName && lastName && phone) {
                const data1 = {
                    first_name: firstName,
                    last_name: lastName,
                };
                const data2 = { phone: phone };
                try {
                    await axios.put(`https://admin.intravelcard.com/api/auth/updateName/${username}`, data1, config);
                    await axios.put(`https://admin.intravelcard.com/api/auth/updateProfile/${username}`, data2, config);
                    localStorage.setItem('first_name', firstName);
                    localStorage.setItem('last_name', lastName);
                    localStorage.setItem('phone', phone);
                    setLoad(true);
                    setAlert('Done Successfully!', 'green');
                    setLabelDone('Done Successfully!');
                    setTimeout(() => {
                        setLabelDone('');
                    }, 3000);
                } catch (e) {
                    setLoad(true);
                    setAlert('Server Error!', 'red');
                    setLabel('Server Error!');
                    setTimeout(() => {
                        setLabel('');
                    }, 5000);
                }
            } else {
                setLoad(true);
                setLabel('Please fill in all fields!');
                setTimeout(() => {
                    setLabel('');
                }, 5000);
                setPersonalUpdate(true);
                setPersonalBtnText('Save Changes');
            }
        }
    };

    //currency
    const onCurrencySubmit = async (e) => {
        e.preventDefault();
        setLoad(false);
        setShowAlert0(false);
        //setShowAlert1(false);
        setShowAlert2(true);
        setShowAlert3(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const data = { currency: currency };
            await axios.put(`https://admin.intravelcard.com/api/auth/updateProfile/${username}`, data, config);
            localStorage.setItem('currency', currency);
            setLoad(true);
            setAlert('Done Successfully!', 'green');
            setLabelDone('Done Successfully!');
            setTimeout(() => {
                setLabelDone('');
            }, 3000);
        } catch (e) {
            setLoad(true);
            setAlert('Server Error!', 'red');
            setLabel('Server Error!');
            setTimeout(() => {
                setLabel('');
            }, 5000);
        }
    };

    //Password
    const [pass1, setPass1] = useState(null);
    const [pass2, setPass2] = useState(null);
    const [currPass, setCurrPass] = useState(null);
    const [inputType1, setInputType1] = useState('password');
    const [inputType2, setInputType2] = useState('password');
    const [inputType3, setInputType3] = useState('password');
    const [showPassImg1, setShowPassImg1] = useState('Images/Icons/eye.png');
    const [showPassImg2, setShowPassImg2] = useState('Images/Icons/eye.png');
    const [showPassImg3, setShowPassImg3] = useState('Images/Icons/eye.png');

    const onChangePass1 = e => {
        setPass1(e.target.value);
    };

    const onChangePass2 = e => {
        setPass2(e.target.value);
    };

    const onChangePass3 = e => {
        setCurrPass(e.target.value);
    };

    const showPass1 = (e) => {
        e.preventDefault();
        if (inputType1 === 'password') {
            setInputType1('text');
            setShowPassImg1('Images/Icons/closed eye.png');
        } else {
            setInputType1('password');
            setShowPassImg1('Images/Icons/eye.png');
        }
    };

    const showPass2 = (e) => {
        e.preventDefault();
        if (inputType2 === 'password') {
            setInputType2('text');
            setShowPassImg2('Images/Icons/closed eye.png');
        } else {
            setInputType2('password');
            setShowPassImg2('Images/Icons/eye.png');
        }
    };

    const showPass3 = (e) => {
        e.preventDefault();
        if (inputType3 === 'password') {
            setInputType3('text');
            setShowPassImg3('Images/Icons/closed eye.png');
        } else {
            setInputType3('password');
            setShowPassImg3('Images/Icons/eye.png');
        }
    };

    const onChangePassSubmit = (e) => {
        setLoad(false);
        setShowAlert0(false);
        //setShowAlert1(false);
        setShowAlert2(false);
        setShowAlert3(true);
        setLabel('');
        e.preventDefault();
        if (pass1 === pass2) {
            changePass({
                username: username,
                pass: pass1,
                prevPass: currPass
            });
            setLoad(true)
        } else {
            setLoad(true);
            setLabel('Your New Password and Confirm Password do not match');
            setTimeout(() => {
                setLabel('');
            }, 5000)
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        //password
        if (error === 'The Current Password Is Incorrect!') {
            setLabel('The Current Password Is Incorrect!');
            setTimeout(() => {
                setLabel('');
            }, 5000);
            clearErrors();
        } else if (error === 'Done Successfully!') { //photo-password
            setAlert('Done Successfully!', 'green');
            clearErrors();
            history.push('/settings');
        }
        //setProfile(`data:image/jpeg;base64,${localStorage.getItem('profile_pic')}`);
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, error, refresh, isAuthenticated, width]);

    const showChangeProfile = () => {
        return (
            <div className='all-center' style={{padding: 30}}>
                {showProfileImage()}
                <button
                    className='btn'
                    style={{backgroundColor: 'white', padding: 0, fontSize: 12, color: '#626465'}}
                    onClick={onChangeProfile}
                >Change Profile Photo</button>
                {showFileUpload
                    ? (
                        <input
                            style={{marginTop: 10, marginBottom: -10, width: 160, fontSize: 12}}
                            type='file'
                            name='identificationPhoto'
                            onChange={(e) => {
                                uploadPhoto(e);
                            }}
                        />
                    )
                    : null
                }
            </div>
        )
    };

    const showPersonal = () => {
        return (
            <div className='text-left' style={{padding: 25, paddingLeft: 40, paddingRight: 40}}>
                <label style={{fontSize: 14}}>
                    <i className='fa fa-user' /> {' '}
                    Personal Settings
                </label>
                <div style={{marginTop: 10, paddingLeft: 10, fontSize: 12}}>
                    <div className='grid-2'>
                        <div style={styles.colStyle}>
                            <label htmlFor='firstName'>First name</label>
                            {personalUpdate === true
                                ? (
                                    <input
                                        className='input-in-settings'
                                        style={styles.inputStyle}
                                        type='text'
                                        name='firstName'
                                        value={firstName}
                                        onChange={onChange}
                                        maxLength='50'
                                    />
                                )
                                : <div style={styles.labelStyle}>
                                    {firstName ? firstName : '-'}
                                </div>
                            }
                        </div>
                        <div style={styles.colStyle}>
                            <label htmlFor='lastName'>Last name</label>
                            {personalUpdate === true
                                ? (
                                    <input
                                        className='input-in-settings'
                                        style={styles.inputStyle}
                                        type='text'
                                        name='lastName'
                                        value={lastName}
                                        onChange={onChange}
                                        maxLength='50'
                                    />
                                )
                                : <div style={styles.labelStyle}>
                                    {lastName ? lastName : '-'}
                                </div>
                            }
                        </div>
                    </div>
                    <div className='grid-2'>
                        <div style={styles.colStyle}>
                            <label htmlFor='email'>Email Address</label>
                            <div style={styles.labelStyle}>{email}</div>
                        </div>
                        <div style={styles.colStyle}>
                            <label htmlFor='phone'>Phone</label>
                            {personalUpdate === true
                                ? (
                                    <input
                                        className='input-in-settings'
                                        style={styles.inputStyle}
                                        type='text'
                                        name='phone'
                                        value={phone}
                                        onChange={onChange}
                                        maxLength='20'
                                    />
                                )
                                : <div style={styles.labelStyle}>
                                    {phone ? phone : '-'}
                                </div>
                            }
                        </div>
                    </div>
                    {label
                        ? (
                            <label
                                className='all-center'
                                style={{
                                    color: 'red',
                                    fontSize: 14,
                                    marginTop: 8,
                                    marginBottom: -30,
                                }}
                            >{label}</label>
                        )
                        : null
                    }
                    {labelDone
                        ? (
                            <label
                                className='all-center'
                                style={{
                                    color: 'green',
                                    fontSize: 14,
                                    marginTop: 8,
                                    marginBottom: -30,
                                }}
                            >{labelDone}</label>
                        )
                        : null
                    }
                    <div className='all-center' style={{marginTop: 50}}>
                        <button
                            className='btn all-btn-in-settings'
                            onClick={onPersonalSubmit}
                        >{personalBtnText}</button>
                    </div>
                </div>
            </div>
        )
    };

    const showCurrency = () => {
        return (
            width > 767
                ? (
                    <div className='text-left' style={{padding: 25, paddingLeft: 40, paddingRight: 40}}>
                        <label style={{fontSize: 14}}>
                            <i className='fa fa-eur' /> {' '}
                            Currency
                        </label>
                        <div style={{marginTop: 15, paddingLeft: 20, fontSize: 12}}>
                            <div>
                                Please select your default currency:
                            </div>
                            <RadioGroup
                                name='type'
                                value={currency}
                                onChange={radioChangeHandler}
                                style={{marginLeft: 30, marginTop: 10}}
                            >
                                <FormControlLabel value='1' control={<Radio />} label='Euro €' className='radio-in-curr-settings'/>
                                <FormControlLabel value='2' control={<Radio />} label='USD $' className='radio-in-curr-settings'/>
                            </RadioGroup>
                            {label
                                ? (
                                    <label
                                        className='all-center'
                                        style={{
                                            color: 'red',
                                            fontSize: 14,
                                            marginTop: 52,
                                            marginBottom: -75,
                                        }}
                                    >{label}</label>
                                )
                                : null
                            }
                            {labelDone
                                ? (
                                    <label
                                        className='all-center'
                                        style={{
                                            color: 'green',
                                            fontSize: 14,
                                            marginTop: 52,
                                            marginBottom: -75,
                                        }}
                                    >{labelDone}</label>
                                )
                                : null
                            }
                            <div className='all-center' style={{marginTop: 93}}>
                                <button
                                    className='btn all-btn-in-settings'
                                    onClick={onCurrencySubmit}
                                >Save Changes</button>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className='text-left'
                         style={{padding: 20, paddingLeft: 25, paddingRight: 25, color: 'white'}}
                    >
                        <label style={{fontSize: 14}}>
                            <i className='fa fa-eur' /> {' '}
                            Currency
                        </label>
                        <div style={{marginTop: 15, fontSize: 12}}>
                            <RadioGroup
                                name='type'
                                value={currency}
                                onChange={radioChangeHandler}
                                style={{marginLeft: 15}}
                                className='radio-in-settings'
                            >
                                <FormControlLabel value='1' control={<Radio />} label='Euro €' className='radio-in-curr-settings'/>
                                <FormControlLabel value='2' control={<Radio />} label='USD $' className='radio-in-curr-settings'/>
                            </RadioGroup>
                            {label && showAlert2 === true
                                ? (
                                    <label
                                        className='all-center'
                                        style={{
                                            color: 'red',
                                            fontSize: 12,
                                            marginTop: 0,
                                            marginBottom: -19,
                                        }}
                                    >{label}</label>
                                )
                                : null
                            }
                            {labelDone && showAlert2 === true
                                ? (
                                    <label
                                        className='all-center'
                                        style={{
                                            color: 'green',
                                            fontSize: 12,
                                            marginTop: 0,
                                            marginBottom: -19,
                                        }}
                                    >{labelDone}</label>
                                )
                                : null
                            }
                            <div className='all-center' style={{marginTop: 40}}>
                                <button
                                    className='btn all-btn-in-settings-mobile'
                                    onClick={onCurrencySubmit}
                                >Save Changes</button>
                            </div>
                        </div>
                    </div>
                )
        )
    };

    const showPassword = () => {
        return (
            width > 767
                ? (
                    <div className='text-left' style={{padding: 25, paddingLeft: 40, paddingRight: 40}}>
                        <label style={{fontSize: 14}}>
                            <i className='fa fa-key key-icon-in-settings' /> {' '}
                            Change Password
                        </label>
                        <div style={{marginTop: 20, paddingLeft: 20, fontSize: 12}}>
                            <form onSubmit={onChangePassSubmit}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <input
                                        className='passInput-in-settings'
                                        style={styles.passInputStyle}
                                        type={inputType1}
                                        name='currPass'
                                        value={currPass}
                                        onChange={onChangePass3}
                                        minLength='8'
                                        required
                                        placeholder='Current Password'
                                    />
                                    <button style={styles.eyeBtnStyle} onClick={showPass1}>
                                        <img
                                            src={showPassImg1}
                                            alt='show'
                                            style={{width: '65%'}}
                                        />
                                    </button>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <input
                                        className='passInput-in-settings'
                                        style={styles.passInputStyle}
                                        type={inputType2}
                                        name='pass1'
                                        value={pass1}
                                        onChange={onChangePass1}
                                        minLength='8'
                                        required
                                        placeholder='New Password'
                                    />
                                    <button style={styles.eyeBtnStyle} onClick={showPass2}>
                                        <img
                                            src={showPassImg2}
                                            alt='show'
                                            style={{width: '65%'}}
                                        />
                                    </button>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <input
                                        className='passInput-in-settings'
                                        style={styles.passInputStyle}
                                        type={inputType3}
                                        name='pass2'
                                        value={pass2}
                                        onChange={onChangePass2}
                                        minLength='8'
                                        required
                                        placeholder='Confirm New Password'
                                    />
                                    <button style={styles.eyeBtnStyle} onClick={showPass3}>
                                        <img
                                            src={showPassImg3}
                                            alt='show'
                                            style={{width: '65%'}}
                                        />
                                    </button>
                                </div>
                                {label
                                    ? (
                                        <label
                                            className='all-center'
                                            style={{
                                                color: 'red',
                                                fontSize: 14,
                                                marginTop: -5,
                                                marginBottom: -8,
                                            }}
                                        >{label}</label>
                                    )
                                    : null
                                }
                                {labelDone
                                    ? (
                                        <label
                                            className='all-center'
                                            style={{
                                                color: 'green',
                                                fontSize: 14,
                                                marginTop: -5,
                                                marginBottom: -8,
                                            }}
                                        >{labelDone}</label>
                                    )
                                    : null
                                }
                                <div className='all-center' style={{marginTop: 23}}>
                                    <input
                                        type='submit'
                                        value='Change Password'
                                        className='btn all-btn-in-settings'
                                        style={styles.passBtnStyle}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                )
                : (
                    <div className='text-left'
                         style={{marginTop: 10, padding: 20, paddingLeft: 25, paddingRight: 25, color: 'white'}}>
                        <label style={{fontSize: 14}}>
                            <i className='fa fa-key key-icon-in-settings' /> {' '}
                            Change Password
                        </label>
                        <div style={{marginTop: 20, fontSize: 12}}>
                            <form onSubmit={onChangePassSubmit}>
                                <div style={{display: 'flex', flexDirection: 'row', paddingLeft: 15}}>
                                    <input
                                        className='passInput-in-settings'
                                        style={styles.passInputStyle_mobile}
                                        type={inputType1}
                                        name='currPass'
                                        value={currPass}
                                        onChange={onChangePass3}
                                        minLength='8'
                                        required
                                        placeholder='Current Password'
                                    />
                                    <button style={styles.eyeBtnStyle} onClick={showPass1}>
                                        <img
                                            src={showPassImg1}
                                            alt='show'
                                            style={{width: '65%'}}
                                        />
                                    </button>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', paddingLeft: 15}}>
                                    <input
                                        className='passInput-in-settings'
                                        style={styles.passInputStyle_mobile}
                                        type={inputType2}
                                        name='pass1'
                                        value={pass1}
                                        onChange={onChangePass1}
                                        minLength='8'
                                        required
                                        placeholder='New Password'
                                    />
                                    <button style={styles.eyeBtnStyle} onClick={showPass2}>
                                        <img
                                            src={showPassImg2}
                                            alt='show'
                                            style={{width: '65%'}}
                                        />
                                    </button>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', paddingLeft: 15}}>
                                    <input
                                        className='passInput-in-settings'
                                        style={styles.passInputStyle_mobile}
                                        type={inputType3}
                                        name='pass2'
                                        value={pass2}
                                        onChange={onChangePass2}
                                        minLength='8'
                                        required
                                        placeholder='Confirm New Password'
                                    />
                                    <button style={styles.eyeBtnStyle} onClick={showPass3}>
                                        <img
                                            src={showPassImg3}
                                            alt='show'
                                            style={{width: '65%'}}
                                        />
                                    </button>
                                </div>
                                {label && showAlert3 === true
                                    ? (
                                        <label
                                            className='all-center'
                                            style={{
                                                color: 'red',
                                                fontSize: 12,
                                                marginTop: -5,
                                                marginBottom: -13,
                                            }}
                                        >{label}</label>
                                    )
                                    : null
                                }
                                {labelDone && showAlert3 === true
                                    ? (
                                        <label
                                            className='all-center'
                                            style={{
                                                color: 'green',
                                                fontSize: 12,
                                                marginTop: -5,
                                                marginBottom: -13,
                                            }}
                                        >{labelDone}</label>
                                    )
                                    : null
                                }
                                <div className='all-center' style={{marginTop: 35}}>
                                    <input
                                        type='submit'
                                        value='Change Password'
                                        className='btn all-btn-in-settings-mobile'
                                        style={styles.passBtnStyle}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                )
        )
    };

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : null
            }
            {showPopUp()}
            {width > 767
                ? (
                    <>
                        <Header defaultSelected='settings'/>
                        <div style={backStyle}>
                            <div style={styles.containerStyle}>
                                <div className='col1-in-settings'>
                                    {showAlert0 === true
                                        ? <AlertsSettings/>
                                        : null
                                    }
                                    {showChangeProfile()}
                                    <div
                                        className='all-center tab-in-settings'
                                        style={{marginTop: -15, fontSize: 14}}
                                    >
                                        <Tabs
                                            onSelect={(index) => {
                                                if (index === 0) {
                                                    setRightImg('/Images/Settings/profile.jpg');
                                                } else if (index === 1) {
                                                    setRightImg('/Images/Settings/currency.jpg');
                                                } else {
                                                    setRightImg('/Images/Settings/change pass.jpg');
                                                }
                                            }}
                                        >
                                           <TabList>
                                               <Tab id='tab1'>Personal Settings</Tab>
                                               <Tab id='tab2'>Currency</Tab>
                                               <Tab id='tab3'>Change Password</Tab>
                                           </TabList>
                                           <TabPanel>
                                               {showPersonal()}
                                           </TabPanel>
                                           <TabPanel>
                                               {showCurrency()}
                                           </TabPanel>
                                           <TabPanel>
                                               {showPassword()}
                                           </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>

                                {width > 1000
                                    ? <div style={{
                                        position: 'relative',
                                        width: '75%',
                                        display: 'flex', alignItems: 'end', justifyContent: 'center'
                                    }}>
                                        <img
                                            src={rightImg}
                                            alt='Setting'
                                            style={{width: '90%', marginBottom: 25}}
                                        />
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </>
                )
                : (
                    <>
                        <div style={{height: 30, backgroundColor: '#003e57'}} />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: '#f1f1f1',
                                padding: 10,
                                paddingLeft: 15,
                                paddingRight: 15,
                        }}>
                            <a
                                className='all-center'
                                href='javascript:history.go(-1)'
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0)',
                                    margin: 0,
                                    height: 35,
                                    width: 30,
                                    borderWidth: 0,
                                    cursor: 'pointer',
                                    zIndex: 2,
                            }}>
                                <i
                                    className="fa fa-angle-left"
                                    style={{color: '#626465', fontSize: 25, fontWeight: 'bold'}}
                                />
                            </a>
                            <div className='all-center' style={{marginLeft: -30, fontSize: 13, color: '#626465'}}>
                                Settings and Privacy
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: '#003e57',
                            //minHeight: window.innerHeight - 85,
                            minHeight: height - 85,
                            padding: 10,
                            paddingTop: 20,
                            paddingBottom: 0,
                        }}>
                            {showCurrency()}
                            {showPassword()}
                        </div>
                    </>
                )
            }
        </Fragment>
    );
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 0,
        borderRadius: 10,
    },
    profileStyle: {
        width: 60,
        height: 60,
        marginBottom: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 10,
    },
    colStyle: {
        padding: 10,
        paddingBottom: 0,
    },
    labelStyle: {
        height: 35,
        width: '80%',
        marginTop: 5,
        marginBottom: 5,
        padding: '0.4rem',
    },
    inputStyle: {
        marginTop: 5,
        marginBottom: 12,
        borderStyle: 'solid',
        borderColor: '#7f7ffb',
        borderWidth: 0,
        borderBottomWidth: 1.5,
    },
    passInputStyle: {
        marginTop: 0,
        marginBottom: 25,
        borderStyle: 'solid',
        borderColor: '#7f7ffb',
        borderWidth: 0,
        borderBottomWidth: 1.5,
    },
    passInputStyle_mobile: {
        backgroundColor: '#003e57',
        marginTop: 0,
        marginBottom: 25,
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 0,
        borderBottomWidth: 1.5,
        color: 'white',
    },
    passBtnStyle: {
        backgroundColor: 'white',
        margin: 0,
        fontSize: 14,
    },
    eyeBtnStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        width: 25,
        height: 22,
        marginTop: 5,
        marginLeft: -25,
        zIndex: 1,
        borderWidth: 0,
        color: 'gray',
        cursor: 'pointer',
    }
};

export default Settings;
