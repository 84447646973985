import React, {Fragment, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import Spinner from '../layout/Spinner';
import SpinnerModal from '../layout/SpinnerModal';
import DialogBox from '../layout/DialogBox';
import OrderDetails from '../layout/OrderDetails';
import BottomMenu from "../layout/BottomMenu";
import '../../Styles/NewStyles.css';
import '../../App.css';

const Orders = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, id } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        width: width - 220,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(null);
    const [label, setLabel] = useState("You don't have any orders!");

    const [orders, setOrders] = useState([]);
    const [ordersBySearch, setOrdersBySearch] = useState([]);
    const [haveOrders, setHaveOrders] = useState(false);

    const [reverse, setReverse] = useState(false);
    const [sortIcon, setSortIcon] = useState('fa fa-angle-down');
    const [searchDate, setSearchDate] = useState('');
    const [searchName, setSearchName] = useState('');

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const [rightDistance, setRightDistance] = useState('8%');

    const onChangeDate = (e) => {
        setSearchDate(e.target.value);
    };

    const onChangeName = (e) => {
        setSearchName(e.target.value);
    };

    const sort = (e) => {
        e.preventDefault();
        if (sortIcon === 'fa fa-angle-down') {
            setSortIcon('fa fa-angle-up')
        } else {
            setSortIcon('fa fa-angle-down')
        }
        setOrdersBySearch(orders.reverse());
        setReverse(!reverse);
    };

    const onSearchDate = () => {
        if (searchName === '') {
            setOrdersBySearch(orders.filter(o => o.createDateTime.slice(0, 10) === searchDate));
        } else {
            const data = orders.filter(o => o.createDateTime.slice(0, 10) === searchDate).filter(o =>
                o.firstName === searchName ||
                o.firstName + ' ' === searchName ||
                o.lastName === searchName ||
                o.lastName + ' ' === searchName ||
                (o.firstName + ' ' + o.lastName) === searchName ||
                (o.firstName + ' ' + o.lastName + ' ' ) === searchName
            );
            setOrdersBySearch(data.filter(o => o.createDateTime.slice(0, 10) === searchDate));
        }
    };

    const onSearchName = () => {
        if (searchDate === '') {
            setOrdersBySearch(orders.filter(o =>
                o.firstName === searchName ||
                o.firstName + ' ' === searchName ||
                o.lastName === searchName ||
                o.lastName + ' ' === searchName ||
                (o.firstName + ' ' + o.lastName) === searchName ||
                (o.firstName + ' ' + o.lastName + ' ' ) === searchName
            ))
        } else {
            const data = orders.filter(o => o.createDateTime.slice(0, 10) === searchDate);
            setOrdersBySearch(data.filter(o =>
                o.firstName === searchName ||
                o.firstName + ' ' === searchName ||
                o.lastName === searchName ||
                o.lastName + ' ' === searchName ||
                (o.firstName + ' ' + o.lastName) === searchName ||
                (o.firstName + ' ' + o.lastName + ' ' ) === searchName
            ))
        }
    };

    const clear = () => {
        setSearchDate('');
    };

    const getOrders = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get(`https://admin.intravelcard.com/api/req/TouristsList-ID/${id}`, config);
            if (res.data.length === 0) {
                setLabel("You don't have any orders!");
                setLoad(true);
            } else {
                const o = res.data.reverse();
                setOrders(o);
                setOrdersBySearch(o);
                setLoad(true);
            }
            setShowDialogBox(false);
        } catch (e) {
            setOrders([]);
            setOrdersBySearch([]);
            setLabel("You don't have any orders!");
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const showResult = () => {
        if (load === true && ordersBySearch) {
            return (
                <>
                    {ordersBySearch.length === 0
                        ? <label
                            className='all-center'
                            style={{
                                marginTop: 20,
                                color: 'red',
                                fontSize: 15
                            }}>
                            {label}
                        </label>
                        : ordersBySearch.map(order => <OrderDetails key={order.id} order={order} width1={width}/>)
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    const showResult_mobile = () => {
        if (load === true && ordersBySearch) {
            return (
                <>
                    {ordersBySearch.length === 0
                        ? <label
                            className='all-center'
                            style={{
                                backgroundColor: 'white',
                                padding: 15,
                                color: 'red',
                                fontSize: 14
                            }}>
                            {label}
                        </label>
                        : ordersBySearch.map(order => <OrderDetails key={order.id} order={order} width1={width}/>)
                    }
                </>
            )
        } else if (load === false) {
            return <Spinner/>
        } else if (load === null) {
            return null;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (!haveOrders && isAuthenticated) {
            getOrders();
            setHaveOrders(true);
        }
        if (searchDate === '' && searchName === '') {
            setOrdersBySearch(orders);
        }
        if (searchDate !== '') {
            onSearchDate();
        }
        if (searchName !== '') {
            onSearchName()
        }
        if (width >= 790 && width < 830) {
            setRightDistance ('4%')
        } else if (width < 790) {
            setRightDistance ('2%')
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, haveOrders, reverse, searchDate, searchName, isAuthenticated, width]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={2}/>
                        : null
                )
            }
            {showPopUp()}
            <Header title='Orders' defaultSelected='orders'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div style={styles.containerStyle}>
                            <div style={styles.row1Style}>
                                <div style={{display: 'flex', alignItems: 'center', marginRight: rightDistance, width: 125}}>
                                    <button style={styles.sortBtnStyle} onClick={sort}>
                                        <i className="fa fa-sort" style={{marginRight: 8}}/>
                                        Sort by Date
                                        <i className={sortIcon} style={styles.sortIconStyle}/>
                                    </button>
                                </div>
                                <div style={{marginRight: rightDistance}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: 200,
                                        }}>
                                            <i className="fa fa-calendar" style={{marginRight: 8}}/>
                                            <label style={styles.sortBtnStyle} >
                                                Any Date
                                                <i className='fa fa-angle-down' style={styles.sortIconStyle}/>
                                            </label>
                                        </div>
                                        <input
                                            type='date'
                                            name='searchDate'
                                            value={searchDate}
                                            onChange={onChangeDate}
                                            style={{
                                                margin: 0,
                                                marginLeft: -200,
                                                width: 110,
                                                opacity: 0,
                                            }}
                                        />
                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        width: 100,
                                        margin: 0,
                                        marginTop: -6,
                                        marginBottom: -10,
                                        fontSize: 8
                                    }}>
                                        {searchDate}
                                        {searchDate
                                            ? (
                                                <button
                                                    onClick={clear}
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0)',
                                                        marginLeft: 5,
                                                        borderWidth: 0,
                                                        cursor: 'pointer',
                                                        color: 'red',
                                                        fontSize: 9
                                                    }}>
                                                    <i className="fa fa-times" />
                                                </button>
                                            )
                                            : null
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                    <i className="fa fa-search" style={{marginRight: 5}}/>
                                    <input
                                        type='text'
                                        name='searchName'
                                        value={searchName}
                                        onChange={onChangeName}
                                        placeholder='Search by Name...'
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0)',
                                            width: 170,
                                            margin: 0,
                                            borderWidth: 0
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={styles.row2Style}>
                                <div className='grid-4' style={{padding: 10, paddingTop: 0, fontSize: 15}}>
                                    <label>Order</label>
                                    <label>Date</label>
                                    <label>Price</label>
                                    <label>Status</label>
                                </div>
                                <div style={styles.lineStyle}/>
                                {showResult()}
                            </div>
                        </div>
                    </div>
                )
                : (
                    <>
                        <div style={{backgroundColor: '#003e57', height: 30}}/>
                        <div style={{
                            backgroundColor: '#f1f1f1',
                            //minHeight: window.innerHeight - 80,
                            minHeight: height - 80,
                            marginTop: -30,
                            padding: 0,
                            paddingTop: 15,
                            paddingBottom: 90,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <div style={styles.row1Style_mobile}>
                                <div className='grid-2-reports' style={{width: '100%'}}>
                                    <button style={styles.sortBtnStyle_mobile} onClick={sort}>
                                        <i className="fa fa-sort" style={{marginRight: 8}}/>
                                        Sort by Date
                                        <i className={sortIcon} style={styles.sortIconStyle_mobile}/>
                                    </button>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: 85
                                        }}>
                                            <i className="fa fa-calendar" style={{marginRight: 8}}/>
                                            <label style={styles.sortBtnStyle_mobile} >
                                                Any Date
                                                <i className='fa fa-angle-down' style={styles.sortIconStyle_mobile}/>
                                            </label>
                                        </div>
                                        <input
                                            type='date'
                                            name='search'
                                            value={searchDate}
                                            onChange={onChangeDate}
                                            style={{
                                                margin: 0,
                                                marginLeft: -87,
                                                width: 85,
                                                opacity: 0,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='grid-2-reports' style={{width: '100%'}}>
                                <div/>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: 8,
                                    marginTop: -6,
                                    marginBottom: -8,
                                }}>
                                    {searchDate}
                                    {searchDate
                                        ? (
                                            <button
                                                onClick={clear}
                                                style={{
                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                    marginLeft: 5,
                                                    borderWidth: 0,
                                                    cursor: 'pointer',
                                                    color: 'red',
                                                    fontSize: 9
                                                }}>
                                                <i className="fa fa-times" />
                                            </button>
                                        )
                                        : null
                                    }
                                </div>
                            </div>

                            <div style={{marginTop: 14}}>
                                <div className='grid-5-in-orders'
                                     style={{
                                         backgroundColor: '#dcf4f8',
                                         padding: 15,
                                         textAlign: 'center',
                                         fontSize: 12
                                }}>
                                    <label>Order</label>
                                    <label>Date</label>
                                    <label>Price</label>
                                    <label>Status</label>
                                    <label>Detail</label>
                                </div>
                                {showResult_mobile()}
                            </div>
                        </div>
                        <BottomMenu/>
                    </>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 235,
        marginLeft: 220,
    },*/
    containerStyle: {
        width: '94%',
        margin: 'auto',
        marginTop: 25+135,
        marginBottom: 25,
        padding: 0,
    },
    row1Style: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#f6fbfc',
        padding: 15,
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: 10,
        borderRadius: 10,
        fontSize: 15
    },
    sortBtnStyle: {
        backgroundColor: '#f6fbfc',
        padding: 0,
        borderWidth: 0,
        cursor: 'pointer',
    },
    sortIconStyle: {
        position: 'relative',
        top: 3,
        left: 5,
        color: '#69c8ce',
        fontSize: 22,
        fontWeight: 600,
    },
    row2Style: {
        backgroundColor: '#f6fbfc',
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 10,
    },
    lineStyle: {
        borderStyle: 'solid',
        borderColor: '#69c8ce',
        borderWidth: 1.5,
        borderRadius: 10
    },
    row1Style_mobile: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'rgba(0,0,0,0)',
        padding: 0,
        fontSize: 12,
    },
    sortIconStyle_mobile: {
        position: 'relative',
        top: 3,
        left: 5,
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#69c8ce',
        fontSize: 18,
        fontWeight: 600,
    },
    sortBtnStyle_mobile: {
        backgroundColor: 'rgba(0,0,0,0)',
        padding: 0,
        borderWidth: 0,
        cursor: 'pointer',
    },
};

export default Orders;
