import React, {Fragment, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Link} from "react-router-dom";
import axios from 'axios';
import AuthContext from '../../context/auth/authContext';
import ToggleContext from '../../context/toggleOfSidebar/toggleContext';
import PopUpLogin from "../layout/PopUpLogin";
import Header from '../layout/Header';
import BottomMenu from "../layout/BottomMenu";
import DialogBox from '../layout/DialogBox';
import SpinnerModal from '../layout/SpinnerModal';
import Spinner from '../layout/Spinner';
import '../../Styles/NewStyles.css';
import '../../App.css';

const Dashboard = () => {
    const authContext = useContext(AuthContext);
    const { isAuthenticated, token, username, currency } = authContext;

    const toggleContext = useContext(ToggleContext);
    const { toggle } = toggleContext;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [backStyle, setBackStyle] = useState({
        position: 'absolute',
        //width: width - 220,
        marginLeft: 220,
    });

    const [load, setLoad] = useState(false);
    const [loadT, setLoadT] = useState(false);
    const [label, setLabel] = useState("You didn't make any transactions!");

    const [firstName, setFirstName] = useState('No card issued to you!');
    const [lastName, setLastName] = useState('');
    const [balance, setBalance] = useState(0);
    const [cardNum, setCardNum] = useState('---- ---- ---- ----');
    const [haveInfo, setHaveInfo] = useState(false);

    const [fromDate, setFromDate] = useState('');
    const [transactions, setTransactions] = useState([]);

    const [dollarPrice, setDollarPrice] = useState(1);
    const [euroPrice, setEuroPrice] = useState(1);

    const [showDialogBox, setShowDialogBox] = useState(false);
    const [dialogBoxData, setDialogBoxData] = useState('');

    const divideNum = (num) => {
        return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    };

    const getCurrencies = async () => {
        setLoad(false);
        const config = {
            headers: {
                'Authorization': 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            const res = await axios.get('https://admin.intravelcard.com/api/curr/CurrenciesList/', config);
            setDollarPrice(res.data[0].dollar);
            setEuroPrice(res.data[0].euro);
            setShowDialogBox(false);
            //setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setLoad(true);
            setDollarPrice(1);
            setEuroPrice(1);
        }
    };

    const getInfo = async () => {
        setLoad(false);
        setLoadT(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };

        try {
            //django server - name & pan
            const res1 = await axios.get(`https://admin.intravelcard.com/api/req/TouristsList-Email/${username}`, config1);
            if (res1.data.length !== 0 && res1.data[res1.data.length - 1].status !== 1) {
                setFirstName(res1.data[res1.data.length - 1].firstName);
                setLastName(res1.data[res1.data.length - 1].lastName);
                const pan = res1.data[res1.data.length - 1].pan;
                setCardNum(pan.toString());
                setFromDate(res1.data[res1.data.length - 1].createDateTime.slice(0,8) + '01' +
                    res1.data[res1.data.length - 1].createDateTime.slice(10,23) + 'Z');

                //bank token
                const res2 = await axios.get('https://admin.intravelcard.com/getBankInfo/getToken/', config1);
                const bankToken = res2.data; //access_token

                //bank service
                //balance & transactions
                const params4 = `Pan=${Number(pan)}`;
                const req4 = { bankToken: `Bearer ${bankToken}` , params: params4 };
                const res4 = await axios.post('https://admin.intravelcard.com/getBankInfo/getBalance/', req4, config1);
                setBalance(res4.data.data.balance);

                const fromdate =
                    res1.data[res1.data.length - 1].createDateTime.slice(0,8) + '01' +
                    res1.data[res1.data.length - 1].createDateTime.slice(10,23) + 'Z';
                const todate = moment()._d.toISOString();
                const Count = 10;
                const params5 = `pan=${Number(pan)}&fromdate=${fromdate}&todate=${todate}&Count=${Count}&paymentId=&TransactionType=`;
                const req5 = { bankToken: `Bearer ${bankToken}` , params: params5};
                const res5 = await axios.post('https://admin.intravelcard.com/getBankInfo/getTransactions/', req5, config1);
                if (res5.data.data === null) {
                    setTransactions([]);
                    setLabel("You didn't make any transactions!");
                } else {
                    setTransactions(res5.data.data);
                }
            } else {
                setFirstName('No card issued to you!');
                setLastName('');
                setCardNum('---- ---- ---- ----');
                setBalance(0);
                setTransactions([]);
                setLabel("You didn't make any transactions!");
            }
            setShowDialogBox(false);
            setLoad(true);
            setLoadT(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setFirstName('No card issued to you!');
            setLastName('');
            setCardNum('---- ---- ---- ----');
            setBalance(0);
            setTransactions([]);
            setLabel("You didn't make any transactions!");
            setLoad(true);
            setLoadT(true);
        }
    };

    const getTransactions = async (e) => {
        e.preventDefault();
        setLoad(false);
        setLoadT(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            //bank token
            const res2 = await axios.get('https://admin.intravelcard.com/getBankInfo/getToken/', config1);
            const bankToken = res2.data; //access_token

            const todate = moment()._d.toISOString();
            const Count = 10;
            const params5 = `pan=${Number(cardNum)}&fromdate=${fromDate}&todate=${todate}&Count=${Count}&paymentId=&TransactionType=`;
            const req5 = { bankToken: `Bearer ${bankToken}` , params: params5};
            const res5 = await axios.post('https://admin.intravelcard.com/getBankInfo/getTransactions/', req5, config1);
            if (res5.data.data === null) {
                setTransactions([]);
                setLabel("You didn't make any transactions!");
            } else {
                setTransactions(res5.data.data);
            }
            setShowDialogBox(false);
            setLoad(true);
            setLoadT(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setTransactions([]);
            setLabel("You didn't make any transactions!");
            setLoad(true);
            setLoadT(true);
        }
    };

    const getBalance = async (e) => {
        e.preventDefault();
        setLoad(false);
        //django server
        const config1 = {
            headers: {
                'Authorization' : 'token ' + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
        try {
            //bank token
            const res2 = await axios.get('https://admin.intravelcard.com/getBankInfo/getToken/', config1);
            const bankToken = res2.data; //access_token

            const params4 = `Pan=${Number(cardNum)}`;
            const req4 = { bankToken: `Bearer ${bankToken}` , params: params4 };
            const res4 = await axios.post('https://admin.intravelcard.com/getBankInfo/getBalance/', req4, config1);
            setBalance(res4.data.data.balance);
            setShowDialogBox(false);
            setLoad(true);
        } catch (e) {
            setShowDialogBox(true);
            setDialogBoxData('Server Error!');
            setBalance(0);
            setLoad(true);
        }
    };

    const showPopUp = () => {
        if (!isAuthenticated) {
            return <PopUpLogin />
        }
    };

    const showTransactions = () => {
        if (loadT === true && transactions) {
            return (
                <>
                    {transactions.length !== 0
                        ? (
                            transactions.slice(0,9).map(t => {
                                return (
                                    <div key={t.paymentID} className='grid-3' style={{marginTop: 6}}>
                                        <div style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            width: 'max-content',
                                        }}>
                                            {t.transactionType === 0
                                                ? (
                                                    <>
                                                        <i
                                                            className='fa fa-credit-card'
                                                            style={{color: 'black', fontSize: 25}}
                                                        />
                                                        <i className = 'fa fa-arrow-circle-o-down'
                                                           style={{
                                                               position: 'relative',
                                                               top: -10, left: -7,
                                                               backgroundColor: 'white',
                                                               borderRadius: '50%',
                                                               borderWidth: 0,
                                                               color: 'red',
                                                               fontSize: 14,
                                                           }}
                                                        />
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <i
                                                            className='fa fa-credit-card'
                                                            style={{color: 'black', fontSize: 25}}
                                                        />
                                                        <i className = 'fa fa-arrow-circle-o-up'
                                                           style={{
                                                               position: 'relative',
                                                               top: -10, left: -7,
                                                               backgroundColor: 'white',
                                                               borderRadius: '50%',
                                                               borderWidth: 0,
                                                               color: '#04ac0a',
                                                               fontSize: 14,
                                                           }}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div style={{marginLeft: -40}}>
                                            <div style={{fontSize: 13}}>
                                                {t.transactionType === 2
                                                    ? <label style={{color: '#04ac0a'}}>
                                                        + {divideNum(t.transAmount)} IRR
                                                    </label>
                                                    : '- ' + divideNum(t.transAmount) + ' IRR'
                                                }
                                            </div>
                                            <div style={{fontSize: 10, paddingLeft: 7, color: '#716969'}}>
                                                <label>
                                                    {t.transDate.slice(0, 4)}/
                                                    {t.transDate.slice(5, 7)}/
                                                    {t.transDate.slice(8, 10)}
                                                </label>
                                                <label style={{marginLeft: 15}}>
                                                    {t.transDate.slice(11, 16)}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <button style={{
                                                margin: 0,
                                                padding: 0,
                                                backgroundColor: 'white',
                                                border: 0,
                                                cursor: 'pointer',
                                            }} title='add note'>
                                                <img
                                                    src='/Images/Icons/notepad.png'
                                                    alt='Note'
                                                    style={{width: 16, height: 16}}
                                                />
                                            </button>
                                            <div style={{position: 'relative', top: -5, fontSize: 10, color: '#716969'}}>
                                                Approx {' '}
                                                {dollarPrice === 1 || euroPrice === 1
                                                    ? '-'
                                                    : (
                                                        currency === '1'
                                                            ? (t.transAmount/euroPrice).toFixed(2) + ' €'
                                                            : (t.transAmount/dollarPrice).toFixed(2) + ' $'
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )
                        : <label
                            className='all-center'
                            style={{
                                marginTop: 15,
                                marginBottom: 10,
                                color: 'red',
                                fontSize: 14
                            }}>
                            {label}
                        </label>
                    }
                </>
            );

        } else if (loadT === false) {
            return <Spinner/>
        } else if (loadT === null) {
            return null;
        } else {
            return null;
        }
    };

    const showTransactions_mobile = () => {
        if (loadT === true && transactions) {
            return (
                <>
                    {transactions.length !== 0
                        ? (
                            transactions.map(t => {
                                return (
                                    <div key={t.paymentID} className='trans-shadow'
                                         style={{
                                             display: 'flex',
                                             flexDirection: 'row',
                                             backgroundColor: 'white',
                                             borderRadius: 10,
                                             padding: 12,
                                             marginTop: 5
                                         }}>
                                        <div style={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            width: 'max-content',
                                        }}>
                                            {t.transactionType === 0
                                                ? (
                                                    <>
                                                        <i
                                                            className='fa fa-credit-card'
                                                            style={{color: 'black', fontSize: 25}}
                                                        />
                                                        <i className = 'fa fa-arrow-circle-o-down'
                                                           style={{
                                                               position: 'relative',
                                                               top: -10, left: -7,
                                                               backgroundColor: 'white',
                                                               borderRadius: '50%',
                                                               borderWidth: 0,
                                                               color: 'red',
                                                               fontSize: 14,
                                                           }}
                                                        />
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <i
                                                            className='fa fa-credit-card'
                                                            style={{color: 'black', fontSize: 25}}
                                                        />
                                                        <i className = 'fa fa-arrow-circle-o-up'
                                                           style={{
                                                               position: 'relative',
                                                               top: -10, left: -7,
                                                               backgroundColor: 'white',
                                                               borderRadius: '50%',
                                                               borderWidth: 0,
                                                               color: '#04ac0a',
                                                               fontSize: 14,
                                                           }}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div style={{
                                            fontSize: 13,
                                            marginLeft: 5,
                                            color: 'black',
                                        }}>
                                            <label>
                                                {t.transDate.slice(0, 4)}/
                                                {t.transDate.slice(5, 7)}/
                                                {t.transDate.slice(8, 10)}
                                            </label>
                                            <br/>
                                            <label style={{color: '#716969', fontSize: 11}}>
                                                {t.transDate.slice(11, 16)}
                                            </label>
                                        </div>
                                        <div style={{
                                            color: 'black',
                                            position: 'absolute',
                                            width: 120,
                                            right: 40
                                        }}>
                                            <div style={{fontSize: 14, fontWeight: 'bold'}}>
                                                {t.transactionType === 2
                                                    ? <label style={{color: '#04ac0a'}}>
                                                        + {divideNum(t.transAmount)} IRR
                                                    </label>
                                                    : '- ' + divideNum(t.transAmount) + ' IRR'
                                                }
                                            </div>
                                            <div style={{color: '#716969', fontSize: 11}}>
                                                Approx {' '}
                                                {dollarPrice === 1 || euroPrice === 1
                                                    ? '-'
                                                    : (
                                                        currency === '1'
                                                            ? (t.transAmount/euroPrice).toFixed(2) + ' €'
                                                            : (t.transAmount/dollarPrice).toFixed(2) + ' $'
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <button style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            right: 8,
                                            marginTop: 30,
                                            backgroundColor: 'white',
                                            border: 0,
                                            cursor: 'pointer',
                                        }} title='add note'>
                                            <img
                                                src='/Images/Icons/notepad.png'
                                                alt='Note'
                                                style={{width: 16, height: 16}}
                                            />
                                        </button>
                                    </div>
                                )
                            })
                        )
                        : <label
                            className='all-center'
                            style={{
                                marginTop: 15,
                                marginBottom: 10,
                                color: 'red',
                                fontSize: 14
                            }}>
                            {label}
                        </label>
                    }
                </>
            );

        } else if (loadT === false) {
            return <Spinner/>
        } else if (loadT === null) {
            return null;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (toggle === false) {
            setBackStyle({
                position: 'absolute',
                //width: width - 65,
                marginLeft: 65,
            });
        } else {
            setTimeout(() => setBackStyle ({
                position: 'absolute',
                //width: width - 220,
                marginLeft: 220,
            }), 200);
        }
        if (!haveInfo && isAuthenticated) {
            getCurrencies();
            getInfo();
            setHaveInfo(true);
        }
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight)
        };
        window.addEventListener("resize", handleResize);
    },[toggle, haveInfo, isAuthenticated]);

    return (
        <Fragment>
            {load === false
                ? <SpinnerModal/>
                : (
                    showDialogBox
                        ? <DialogBox data={dialogBoxData} type={2}/>
                        : null
                )
            }
            {showPopUp()}
            <Header defaultSelected='dashboard'/>
            {width > 767
                ? (
                    <div style={backStyle}>
                        <div className='grid-2-dashboard' style={styles.containerStyle}>
                            <div className='col1-in-dashboard'>
                                <div style={styles.row1Style}>
                                    <img
                                        src='/Images/Card.png'
                                        alt='Intravel Card'
                                        style={styles.cardImgStyle}
                                    />
                                    {cardNum === '---- ---- ---- ----'
                                        ? (
                                            <div style={{
                                                position: 'absolute',
                                                top: 87+135,
                                                marginLeft: 26,
                                                fontSize: 35,
                                                //fontWeight: 'bold',
                                                color: 'white'
                                            }}>
                                                {cardNum}
                                            </div>
                                        )
                                        : (
                                            <div style={{
                                                position: 'absolute',
                                                top: 101+135,
                                                marginLeft: 23,
                                                fontSize: 19,
                                                fontWeight: 'bold',
                                                color: 'white'
                                            }}>
                                                {cardNum.toString().slice(0,4)} {' '}
                                                {cardNum.toString().slice(4,8)} {' '}
                                                {cardNum.toString().slice(8,12)} {' '}
                                                {cardNum.toString().slice(12,16)} {' '}
                                            </div>
                                        )
                                    }
                                    <div style={{
                                        position: 'absolute',
                                        top: 174+135,
                                        marginLeft: 22,
                                        fontSize: 10,
                                        color: 'white'
                                    }}>
                                        {firstName} {' '} {lastName}
                                    </div>
                                    <div style={styles.cardInfoStyle}>
                                        <div style={{marginBottom: 8}}>
                                            <label style={styles.titleStyle}>Acc Holder</label>
                                            <br/>
                                            <label style={styles.infoStyle}>
                                                {firstName} {' '} {lastName}
                                            </label>
                                        </div>
                                        <div style={{marginBottom: 8}}>
                                            <label style={styles.titleStyle}>Your Balance</label>
                                            <br/>
                                            <label style={styles.infoStyle}>
                                                {divideNum(balance)} IRR
                                                <button
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0)',
                                                        width: 25,
                                                        marginLeft: 5,
                                                        borderWidth: 0,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={getBalance}
                                                    title='refresh'
                                                >
                                                    <i className="fa fa-refresh" style={{fontSize: 12, color: '#0bdefa'}}/>
                                                </button>
                                            </label>
                                        </div>
                                        <div style={{marginBottom: 8}}>
                                            <label style={styles.titleStyle}>Card Num</label>
                                            <br/>
                                            <label style={styles.infoStyle}>
                                                {cardNum === '---- ---- ---- ----'
                                                    ? cardNum
                                                    :
                                                    cardNum.toString().slice(0,4) + ' ' +
                                                    cardNum.toString().slice(4,8) + ' ' +
                                                    cardNum.toString().slice(8,12) + ' ' +
                                                    cardNum.toString().slice(12,16) + ' '
                                                }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid-2' style={{marginBottom: 20}}>
                                    <div style={styles.smallCadreStyle}>
                                        <Link
                                            to={{
                                                pathname: '/top-up',
                                                state: {firstName, lastName, balance, cardNum}
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <img
                                                src='/Images/Icons/topup.png'
                                                alt='Top Up'
                                                style={styles.iconStyle}
                                            />
                                        </Link>
                                        <div style={{marginLeft: 15, marginTop: -5}}>
                                            <a
                                                href='/top-up'
                                                style={{color: 'black'}}
                                            >
                                                <strong>Top Up</strong>
                                            </a>
                                            <div style={styles.descInCard}>
                                                Add funds to your Intravel Card
                                            </div>
                                        </div>
                                    </div>
                                    <div style={styles.smallCadreStyle}>
                                        <a
                                            href='/myCard'
                                            style={{cursor: 'pointer'}}
                                        >
                                            <img
                                                src='/Images/Icons/lost.png'
                                                alt='Lost & Refund'
                                                style={styles.iconStyle}
                                            />
                                        </a>
                                        <div style={{marginLeft: 15}}>
                                            <a
                                                href='/myCard'
                                                style={{color: 'black'}}
                                            >
                                                <strong>Lost & Refund</strong>
                                            </a>
                                            <div style={styles.descInCard}>
                                                Refund remaining balance or inform us about lost card
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid-2'>
                                    <div style={styles.smallCadreStyle}>
                                        <a
                                            href='/packages'
                                            style={{cursor: 'pointer'}}
                                        >
                                            <img
                                                src='/Images/Icons/services.png'
                                                alt='Our Services'
                                                style={styles.iconStyle}
                                            />
                                        </a>
                                        <div style={{marginLeft: 15}}>
                                            <a
                                                href='/packages'
                                                style={{color: 'black'}}
                                            >
                                                <strong>Our Services</strong>
                                            </a>
                                            <div style={styles.descInCard}>
                                                SIMCard, Hotel reservation and transfer
                                            </div>
                                        </div>
                                    </div>
                                    <div style={styles.smallCadreStyle}>
                                        <a
                                            href='/converter'
                                            style={{cursor: 'pointer'}}
                                        >
                                            <img
                                                src='/Images/Icons/converter.png'
                                                alt='Converter'
                                                style={styles.iconStyle}
                                            />
                                        </a>

                                        <div style={{marginLeft: 15}}>
                                            <a
                                                href='/converter'
                                                style={{color: 'black'}}
                                            >
                                                <strong>Converter</strong>
                                            </a>
                                            <div style={styles.descInCard}>
                                                Convert Euro/USD to IRR using our live exchange
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col2-in-dashboard' style={styles.transactionsContainerStyle}>
                                <div style={{display: 'flex', flexDirection: 'row', fontSize: 17, fontWeight: 'bold'}}>
                                    Recent Transaction
                                    <button
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0)',
                                            width: 25,
                                            marginLeft: 5,
                                            borderWidth: 0,
                                            cursor: 'pointer'
                                        }}
                                        onClick={getTransactions}
                                        title='refresh'
                                    >
                                        <i className="fa fa-refresh" style={{fontSize: 14, color: '#0bdefa'}}/>
                                    </button>
                                </div>
                                {transactions.length !== 0
                                    ? (
                                        <div className='text-right' style={{marginTop: -20, marginBottom: 10}}>
                                            <a
                                                href='/reports'
                                                style={{fontSize: 12, color: 'gray'}}
                                            >see all</a>
                                        </div>
                                    )
                                    : null
                                }
                                {showTransactions()}
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div style={{
                        backgroundColor: '#003e57',
                        //minHeight: window.innerHeight - 80
                        minHeight: height - 80
                    }}>
                        <div style={{
                            padding: 30,
                            paddingTop: 0,
                            paddingBottom: 0,
                            height: 55,
                        }}>
                            <label style={{color: 'white', fontSize: 14}}>Balance</label>
                            <br/>
                            <label style={{color: 'white', fontSize: 20, fontWeight: 'bold'}}>
                                {divideNum(balance)} IRR
                                <label style={{marginLeft: 10, fontSize: 10}}>
                                    Approx {' '}
                                    {dollarPrice === 1 || euroPrice === 1
                                        ? '-'
                                        : (
                                            currency === '1'
                                                ? (balance/euroPrice).toFixed(2) + ' €'
                                                : (balance/dollarPrice).toFixed(2) + ' $'
                                        )
                                    }
                                </label>
                                <button
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        width: 30,
                                        marginLeft: 5,
                                        borderWidth: 0,
                                        cursor: 'pointer'
                                    }}
                                    onClick={getBalance}
                                    title='refresh'
                                >
                                    <i className="fa fa-refresh" style={{fontSize: 14, color: '#0bdefa'}}/>
                                </button>
                            </label>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: 205,
                        }}>
                            <div style={{width: 260}}>
                                <img
                                    src='/Images/Card.png'
                                    alt='Intravel Card'
                                    style={styles.cardImgStyle_mobile}
                                />
                                {cardNum === '---- ---- ---- ----'
                                    ? (
                                        <div style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: 260,
                                            top: 205,
                                            //marginLeft: -30,
                                            fontSize: 35,
                                            color: 'white',
                                        }}>
                                            {cardNum}
                                        </div>
                                    )
                                    : (
                                        <div style={{
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: 260,
                                            top: 219,
                                            //marginLeft: -24,
                                            fontSize: 18.5,
                                            fontWeight: 'bold',
                                            color: 'white'
                                        }}>
                                            {cardNum.toString().slice(0,4)} {' '}
                                            {cardNum.toString().slice(4,8)} {' '}
                                            {cardNum.toString().slice(8,12)} {' '}
                                            {cardNum.toString().slice(12,16)} {' '}
                                        </div>
                                    )
                                }
                                <div style={{
                                    position: 'absolute',
                                    top: 293,
                                    marginLeft: 15,
                                    fontSize: 10,
                                    color: 'white'
                                }}>
                                    {firstName} {' '} {lastName}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: '#f6f6f6',
                            //minHeight: window.innerHeight - 340,
                            minHeight: height - 340,
                            padding: 15,
                            paddingTop: 30,
                            paddingBottom: 100,
                            borderRadius: 35,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}>
                            <div className='grid-4-dashboard'>
                                <div className='all-center' style={{width: '100%'}}>
                                    <Link
                                        to={{
                                            pathname: '/top-up',
                                            state: {firstName, lastName, balance, cardNum}
                                        }}
                                        style={styles.smallCadreStyle_mobile}
                                    >
                                        <img
                                            src='/Images/Icons/topup_mobile.png'
                                            alt='Top Up'
                                            style={styles.iconStyle_mobile}
                                        />
                                        <strong style={styles.titleInCardStyle_mobile}>Top Up</strong>
                                    </Link>
                                </div>
                                <div className='all-center' style={{width: '100%'}}>
                                    <a
                                        href='/myCard'
                                        style={styles.smallCadreStyle_mobile}
                                    >
                                        <img
                                            src='/Images/Icons/lost_mobile.png'
                                            alt='Lost & Refund'
                                            style={styles.iconStyle_mobile}
                                        />
                                        <strong style={styles.titleInCardStyle_mobile}>Lost & Refund</strong>
                                    </a>
                                </div>
                                <div className='all-center' style={{width: '100%'}}>
                                    <a
                                        href='/packages'
                                        style={styles.smallCadreStyle_mobile}
                                    >
                                        <img
                                            src='/Images/Icons/services_mobile.png'
                                            alt='Our Services'
                                            style={styles.iconStyle_mobile}
                                        />
                                        <strong style={styles.titleInCardStyle_mobile}>Our Services</strong>
                                    </a>
                                </div>
                                <div className='all-center' style={{width: '100%'}}>
                                    <a
                                        href='/converter'
                                        style={styles.smallCadreStyle_mobile}
                                    >
                                        <img
                                            src='/Images/Icons/converter_mobile.png'
                                            alt='Converter'
                                            style={styles.iconStyle_mobile}
                                        />
                                        <strong style={styles.titleInCardStyle_mobile}>Converter</strong>
                                    </a>
                                </div>
                            </div>
                            <div style={styles.transactionsContainerStyle_mobile}>
                                <div style={{display: 'flex', flexDirection: 'row', fontSize: 17, fontWeight: 'bold', color: '#003e57'}}>
                                    Recent Transaction
                                    <button
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0)',
                                            width: 30,
                                            marginLeft: 5,
                                            borderWidth: 0,
                                            cursor: 'pointer'
                                        }}
                                        onClick={getTransactions}
                                        title='refresh'
                                    >
                                        <i className="fa fa-refresh" style={{fontSize: 14, color: '#0bdefa'}}/>
                                    </button>
                                </div>
                                {transactions.length !== 0
                                    ? (
                                        <div className='text-right' style={{marginTop: -20, marginBottom: 10}}>
                                            <a
                                                href='/reports'
                                                style={{fontSize: 12, color: '#003e57'}}
                                            >see all</a>
                                        </div>
                                    )
                                    : null
                                }
                                {showTransactions_mobile()}
                            </div>
                        </div>
                        <BottomMenu />
                    </div>
                )
            }
        </Fragment>
    )
};

const styles = {
    /*backgroundStyle: {
        position: 'absolute',
        width: window.innerWidth - 65,
        marginLeft: 65,
    },
    background2Style: {
        position: 'absolute',
        width: window.innerWidth - 220,
        marginLeft: 220,
    },*/
    containerStyle: {
        width: '94%',
        margin: 'auto',
        marginTop: 15+135,
        marginBottom: 15,
        padding: 0,
    },
    row1Style: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        width: '100%',
        marginBottom: 20,
        padding: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 10,
        gridGap: 0
    },
    cardImgStyle: {
        width: 260,
        alignSelf: 'center',
        borderRadius: 10,
    },
    cardInfoStyle: {
        marginLeft: 20,
        padding: 10,
        alignSelf: 'center',
    },
    titleStyle: {
        fontSize: 12,
        color: 'gray',
    },
    infoStyle: {
        fontSize: 14
    },
    smallCadreStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        height: 120,
        padding: 20,
        borderRadius: 10,
    },
    iconStyle: {
        width: 80,
        marginTop: 7,
        borderRadius: 10,
    },
    descInCard: {
        marginTop: 5,
        fontSize: 12,
        color: '#626465',
    },
    transactionsContainerStyle: {
        backgroundColor: 'white',
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 10,
    },
    cardImgStyle_mobile: {
        position: 'absolute',
        top: 155,
        width: 260,
        borderRadius: 10,
    },
    smallCadreStyle_mobile: {
        display: 'grid',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: '#003e57',
        height: 70,
        width: 72,
        borderRadius: 15,
    },
    iconStyle_mobile: {
        width: 55,
        margin: 'auto',
        borderRadius: 10,
    },
    titleInCardStyle_mobile: {
        position: 'relative',
        top: -6,
        margin: 'auto',
        color: 'white',
        fontSize: 10,
    },
    transactionsContainerStyle_mobile: {
        position: 'relative',
        left: '-1%',
        width: '102%',
        marginTop: 30,
    }
};

export default Dashboard;
