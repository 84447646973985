import React, {useReducer, useState} from 'react';
import ToggleContext from './toggleContext';
import toggleReducer from './toggleReducer';
import {SET_TOGGLE} from '../types';

const ToggleState = props => {
    const initialState = true;

    const [state, dispatch] = useReducer(toggleReducer, initialState);
    const [t, setT] = useState(false);

    const setToggle = () => {
        setT(!t);
        dispatch({
            type: SET_TOGGLE,
            payload: t,
        })
    };

    return (
        <ToggleContext.Provider
            value={{
                toggle: state,
                setToggle
            }}
        >
            {props.children}
        </ToggleContext.Provider>
    )
};

export default ToggleState;
