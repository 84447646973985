import React, {useContext} from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../../Styles/MobileSidebar.css'
import AuthContext from "../../context/auth/authContext";
import PropTypes from 'prop-types';

const MobileSidebar = ({profile_pic}) => {
    const authContext = useContext(AuthContext);
    const { logout } = authContext;

    const showProfileImage = () => {
        if (!profile_pic || profile_pic === '') {
            return <img
                src='/Images/default user.jpg'
                alt='Profile'
                className='img round-img profileImg-in-header'
                style={styles.profileImgStyle}
            />
        } else {
            return <img
                src={`data:image/jpeg;base64,${profile_pic}`}
                alt='profile'
                className='img round-img profileImg-in-header'
                style={styles.profileImgStyle}
            />
        }
    };

    return (
        <div style={{position: 'fixed', top: 0, left: 0, zIndex: 5}}>
            <Menu>
                {showProfileImage()}
                <a className="menu-item" href="/edit-profile">
                    <i className='fa fa-user' style={styles.iconStyle} />
                    Edit profile
                </a>
                <a className="menu-item" href="/orders">
                    <i className='fa fa-list-ul' style={styles.iconStyle} />
                    Orders
                </a>
                <a className="menu-item" href="https://intravelcard.com/faq/">
                    <i className='fa fa-question-circle' style={styles.iconStyle} />
                    IntravelCard FAQs
                </a>
                <a className="menu-item" href="/settings">
                    <i className='fa fa-cog' style={styles.iconStyle} />
                    Privacy and Settings
                </a>
                <a className="menu-item" onClick={logout} style={{cursor: 'pointer'}}>
                    <i className='fa fa-sign-out' style={styles.iconStyle} />
                    Log Out
                </a>

                <div style={styles.followStyle}>
                    <a href='#' title='Instagram' className='all-center'>
                        <img
                            src='/Images/Icons/instagram.svg'
                            alt='Instagram'
                            style={{display: 'flex', width: 22}}
                        />
                    </a>
                    <a href='#' title='Twitter' className='all-center' style={{color: 'white', fontSize: 19}}>
                        <i className="fa fa-twitter" />
                    </a>
                    <a href='#' title='Pinterest' className='all-center' style={{color: 'white', fontSize: 19}}>
                        <i className="fa fa-pinterest" />
                    </a>
                    <a href='#' title='Whatsapp' className='all-center'>
                        <img
                            src='/Images/Icons/whatsapp.svg'
                            alt='Whatsapp'
                            style={{display: 'flex', width: 20}}
                        />
                    </a>
                </div>
            </Menu>
        </div>
    )
};

MobileSidebar.propTypes = {
    profile_pic: PropTypes.string.isRequired,
};

const styles = {
    iconStyle: {
        marginTop: 2.5,
        marginRight: 6,
        fontSize: 16
    },
    profileImgStyle: {
        position: 'absolute',
        top: 20,
        right: 20,
    },
    followStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: 43,
        position: 'absolute',
        bottom: 30
    }
};

export default MobileSidebar;
